import useWindowSize from '@rehooks/window-size';
import React from 'react';
import DealPublicNavDesktop from './LoginNavDesktop';


const LoginNav: React.FunctionComponent = () => {
  const windowSize = useWindowSize();

  if (windowSize.innerWidth < 768) {
    return null;
  }
  return (
    <DealPublicNavDesktop />
  );
};


export default (LoginNav);
