import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import HomeFooter from '../components/Footer/Home/HomeFooter';
import IntroCommentNoAnim from '../components/Home/Intro/Comments/IntroCommentNoAnim';
import { generateIconNew } from '../components/Home/UI/HomeIcons';
import HomeRowIcons from '../components/Home/UI/HomeRowIcons';
import HomeTitleSection from '../components/Home/UI/HomeTitleSection';
import IdeaItem from '../components/Idea/IdeaItem';
import MoreDealIdeas from '../components/MoreDealIdeas';
import HomeNav from '../components/Nav/Public/Home/HomeNav';
import firebase from '../firebase';
import {
  DASH_COLOR, GREY_DARK, Row, WHITE_DARK,
} from '../styles';
import Loading from './Loading';

export const DealIdeas = () => {
  const [limitDeal, setLimitDeal] = useState(3);
  const [ideasList, setDealIdeas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let unsubscribe;
    // eslint-disable-next-line prefer-const
    unsubscribe = firebase.firestore()
      .collection('ideas')
      .orderBy('dateCreated', 'desc')
      .where('private', '==', false)
      .where('invitedIsWitness', '==', true)
      .limit(limitDeal)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
          setIsLoading(false);
          setDealIdeas([]);
        } else {
          setIsLoading(false);
          const query = [];
          querySnapshot.forEach((doc) => {
            const comment = {
              key: doc.id,
              ideaId: doc.id,
              ...doc.data(),
            };
            query.push(comment);
          });
          //   setDealIdeas(_.reverse(query));
          setDealIdeas(query);
        }
      });
    return () => {
      unsubscribe();
    };
  }, [limitDeal]);

  const renderListItems = () => {
    if (ideasList !== undefined) {
      const list = ideasList.map((idea, index) => {
        if (index % 2) {
          return (
            <IdeaItem
              idea={idea}
              bgColor={WHITE_DARK}
              key={idea?.dateCreated}
              ideaId={idea.ideaId}
            />
          );
        }
        return (
          <IdeaItem
            idea={idea}
            key={idea?.dateCreated}
            ideaId={idea.ideaId}
          />
        );
      });
      return list;
    } return null;
  };

  if (isLoading) {
    return (
      <Loading />);
  }

  return (
    <>
      <HomeNav />
      <Row lg />

      <HomeTitleSection
        title="Challenge Contract Ideas"
        subtitle="Inspirations"
        description="Check our ideas to boost your creativity."
        id="#dealIdeasTitle"
        dark
        centered
      />
      <Row
        center
        sm
      >
        <IntroCommentNoAnim
          exampleText="Get INSPIRED and import idea to DEALEM!"
          rightAvatar
        />
      </Row>
      <Row md />
      <HomeRowIcons
        generateIconsNames={generateIconNew}
        numberOfIcons={10}
        iconColor="white"
        backgroundColor={DASH_COLOR}
        id="premium"
      />
      {renderListItems()}
      <MoreDealIdeas
        setLimitDeal={setLimitDeal}
        limitDeal={limitDeal}
      />
      <HomeFooter />
    </>
  );
};

export default DealIdeas;


export const Border = styled.span`
    border-top: 1px dashed ${GREY_DARK};
    height: 1px; 
    width: 42px;
`;
