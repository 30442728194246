import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../../../styles/Icon';
import { ORANGE, GREY_MEDIUM, LINK_NO_STYLE } from '../../../styles/index';

interface Props {
  iconType: string;
  iconTypeHover: string;
  link: string;
  text: string;
}

const NavLinkStore: React.FunctionComponent<Props> = ({
  iconType,
  iconTypeHover,
  link,
  text,
}) => {
  const [isIconHover, onIconHover] = useState(false);
  return (
    <Container
      onMouseEnter={() => onIconHover(true)}
      onMouseLeave={() => onIconHover(false)}
    >
      <StoreLink
        style={LINK_NO_STYLE}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormIconWrapper>
          <Icon
            height="20"
            width="20"
            iconType={isIconHover ? iconTypeHover : iconType}
            fill={isIconHover ? ORANGE : GREY_MEDIUM}
          />
        </FormIconWrapper>

        <FormIconLinkStyle
          isIconHover={isIconHover}
        >
          {text}
        </FormIconLinkStyle>
      </StoreLink>
    </Container>

  );
};

export default NavLinkStore;


const Container = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`;

const FormIconLinkStyle = styled.span<{isIconHover: boolean}>`
    font-size: 14px;
    color: ${(props) => (props.isIconHover ? ORANGE : GREY_MEDIUM)};
    letter-spacing: -0.5px;
    cursor: pointer;
  `;

const FormIconWrapper = styled.span`
    margin-right: 10px;
    margin-top: 2px;
  `;


const StoreLink = styled.a`
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none; 
   color: inherit;
`;
