import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import switchLanguage from '../../../../hoc/switchLanguage';
import { ButtonEmpty } from '../../../../styles/buttons';
import { Col, Row } from '../../../../styles/index';
import DealMenuBorder from '../../../UI/Cards/DealCard/DealMenuBorder';
import NavAppName from '../NavAppName';
import NavAvatar from '../NavAvatar';
import { texts } from './DealPublicNavDesktop';
import Language from '../../../../contexts/Language';


type Props = {
  userId: string;
  dealId: string;
  onSignOut: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  noLogin?: boolean;
}

const DealItemPublicNavMobile: React.FunctionComponent<Props> = ({
  userId,
  dealId,
  onSignOut,
  noLogin,
}) => {
  const locale = useContext(Language);
  const renderButton = () => {
    if (userId) {
      return (
        <ButtonEmpty
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onClick={onSignOut}
        >
          {switchLanguage(locale, texts).logout}
        </ButtonEmpty>
      );
    }
    return (
      <NavLink
        to={{
          pathname: '/logIn',
          state: { dealId },
        }}
        activeClassName="logInActive"
      >
        <ButtonEmpty>
          {switchLanguage(locale, texts).logIn}
        </ButtonEmpty>
      </NavLink>
    );
  };

  return (
    <Row
      md
      center
    >
      <Container>
        <Row>
          <Col size={1} />
          <Col
            size={3}
            ver
          >
            <NavAppName />
          </Col>
          <Col
            size={2}
            center
            ver
          >
            <DealMenuBorder />
          </Col>
          {noLogin
            ? null
            : (
              <>
                <Col
                  size={4}
                  ver
                  center
                >
                  {renderButton()}
                </Col>

                <Col size={1} />
              </>
            )}
          <Col
            size={noLogin ? 3 : 2}
            ver
            center
          >
            <NavAvatar />
          </Col>
        </Row>
      </Container>
    </Row>
  );
};


export default (DealItemPublicNavMobile);

const Container = styled.div`
  width: 768px;
`;
