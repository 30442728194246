
export const VIOLET = '#6e5bff';
export const GREEN = '#009D91';

export const ORANGE = '#FF6E00'; // logo //bb8434 cmyk
export const ORANGE_HOVER = '#ff7006';

// logo colors
export const BEIGE_LIGHT = '#FCD6C8';
export const BEIGE_MEDIUM = '#F9B9A3'; // logo top
export const BEIGE_DARK = '#F4855E';
export const ORANGE_LOGO_EXTRALIGHT = '#F37831';
export const ORANGE_LOGO_LIGHT = '#F05A24';
export const ORANGE_LOGO_MEDIUM = '#EE4823'; // logo bottom
export const ORANGE_LOGO_DARK = '#B53425';

export const ERROR_COLOR = '#ff0012';
export const ERROR_COLOR_HOVER = '#e60010';

export const GREY_WHITE = 'rgb(226, 226, 233)';
export const GREY_LIGHT = '#b8b8c7';
export const GREY = '#57575B';
export const GREY_MEDIUM = '#32323c';
export const GREY_DARK = '#17171c';
export const WHITE_DARK = '#FBFBFB';
export const DASH_COLOR = '#DEDEDE';
