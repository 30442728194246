import React from 'react';
import Img from 'react-image';
import styled from 'styled-components';
import robot from '../../../assets/robot.png';

import { ROBOT_SIZE } from '../../../styles';
import ImageCardAvatarPlaceholder from './ImageCardAvatarPlaceholder';

const ImageRobot = () => (
  <>
    <Robot
      src={robot}
      loader={
        <ImageCardAvatarPlaceholder />
      }
    />
  </>
);
export default ImageRobot;


export const Robot = styled(Img)`
   @media only screen and (max-width: 426px){
    height: 72px;
    width: 72px;
  }
    ${ROBOT_SIZE}
    object-fit: cover;
    margin-bottom:3px;
`;
