import React from 'react';
import styled from 'styled-components';
import Icon from '../../../styles/Icon';
import * as iconTypes from '../../../styles/iconTypes';
import {
  CardAvatarCircleDashStyle, CardAvatarWrapperWhiteStyle, GREY_MEDIUM, shadowNavAvatar, SHADOW_GREY_DARK,
} from '../../../styles/index';


const NavAvatarPlaceholder = () => (
  <AvatarWrapper>
    <CardAvatarCircleDashStyle>
      <Icon
        height="16"
        width="16"
        iconType={iconTypes.USER}
        fill={GREY_MEDIUM}
      />
    </CardAvatarCircleDashStyle>
  </AvatarWrapper>
);


export default NavAvatarPlaceholder;


const AvatarWrapper = styled(CardAvatarWrapperWhiteStyle)`
   ${shadowNavAvatar(SHADOW_GREY_DARK)};
`;
