import React, { useContext } from 'react';

import Language from '../../../contexts/Language';
import switchLanguage from '../../../hoc/switchLanguage';
import { GreyContainer, Row, WhiteContainer } from '../../../styles';
import Example from './Example/Example';
import IntroCommentTop from './Comments/IntroCommentTop';
import HomeTitleSection from '../UI/HomeTitleSection';
import IntroDealMobile from './Deal/IntroDealMobile';
import Features from './Features/Features';
import { texts } from './Intro';
import PhoneAnimMobile from './IntroStyle/PhoneAnimMobile';
import IntroVideoMobile from './Video/IntroVideoMobile';

const HomeIntroMobile = () => {
  const locale = useContext(Language);
  return (
    <WhiteContainer
      padTop="0px"
      padBot="0px"
    >
      <HomeTitleSection
        title={switchLanguage(locale, texts).title}
        subtitle={switchLanguage(locale, texts).subtitle}
        description={switchLanguage(locale, texts).description}
        id="intro"
        dark
      />
      <IntroCommentTop
        topMargin
        commentText="Hello HUMAN.Here's an EXAMPLE"
      />
      <Row md />
      <Example />
      <IntroVideoMobile />
      <GreyContainer
        padTop="0px"
      >
        <PhoneAnimMobile />
        <IntroDealMobile />
      </GreyContainer>
      <Features />
    </WhiteContainer>
  );
};

export default HomeIntroMobile;
