import React from 'react';
import styled from 'styled-components';


import {
  ButtonHeader,
  GREY,
  GREY_DARK,
  Row,
  WhiteContainer,
  LINK_NO_STYLE,
  FONT_FUTURA_BOLD,
} from '../../../styles';


const CheckIdeas = () => (
  <WhiteContainer
    padTop="0px"
    padBot="0px"
  >
    <BorderGrey />
    <BorderGrey />
    <BorderGrey />
    <BorderGrey />
    <Container>
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <BorderGrey />
      <Wrapper>
        We have some
        <br />
        ideas for You
      </Wrapper>
    </Container>
    <ButtonContainer>
      <Row>
        <a
          href="/ideas"
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          style={LINK_NO_STYLE}
        >
          <ButtonHeader>
            CLICK HERE
          </ButtonHeader>
        </a>
      </Row>
    </ButtonContainer>
  </WhiteContainer>
);


export default CheckIdeas;

const Container = styled.div`
    background: ${GREY_DARK};
    height: 180px;
    width: 100%;
    position: relative;
    background-size: cover;
    padding:0;
    box-shadow: 0 20px 60px 0 rgba(190,190,207,0.50);
    padding-bottom: 48px;
`;

const Wrapper = styled.h1`
  display: block;
  background-color: white;
  opacity: 1;
  /* color: #000; */
  color: #000;
  mix-blend-mode: screen;
  position: absolute;
  /* text-transform: uppercase; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size:48px;
  margin: 0;
  opacity: 1;
  width: 100%;
  height: 180px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  padding-top: 24px;
  letter-spacing: -4px;
  font-family: ${FONT_FUTURA_BOLD};
  @media only screen and (max-width: 400px){
    font-size:42px;
  }
`;

const BorderGrey = styled.div`
    border-top: 1px dashed ${GREY};
    /* border-top: 1px dashed #DEDEDE; */
    height: 1px; 
    width: 100%;
    margin-top: 3px;
`;

const ButtonContainer = styled.div`
  position: relative;
  margin-top: -32px;
  z-index:2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
