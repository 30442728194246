import styled from 'styled-components';

import {
  CARD_CIRCLE_INNER_LINE,
  CARD_CIRCLE_SIZE,
  ERROR_COLOR,
  GREY,
  ORANGE,
  SHADOW_GREY,
  shadowCardAvatarWrapper,
} from '.';
import { WHITE_DARK } from './colors';
import { DIST_LARGE } from './sizes';

// eslint-disable-next-line
export const DealCardContainerStyle = styled.div<{shadow?: boolean; drawer?: boolean}>`
  @media only screen and (max-width: 400px){
     width: 100%;
  }
  width: 360px;
  margin-bottom: ${(props) => (props.drawer ? '-2px' : '24px')};
  background: white;
  ${(props) => (props.shadow
    ? 'background: #FFFFFF;'
  + 'box-shadow: 0 10px 40px 0 rgba(190,190,207,0.50);' : null)}
  border-radius: 5px;
`;

export const CardAvatarWrapperStyle = styled.div<{error?: boolean}>`
    ${CARD_CIRCLE_SIZE};
    ${shadowCardAvatarWrapper(SHADOW_GREY)};
    background: ${(props) => (props.error ? ERROR_COLOR : ORANGE)};
    display: flex;
    justify-content: center;
    align-items:center;
`;


export const CardAvatarWrapperWhiteStyle = styled.div<{shadow?: boolean}>`
${(props) => (props.shadow && shadowCardAvatarWrapper(SHADOW_GREY))}
 background: white;
 ${CARD_CIRCLE_SIZE}
 align-items: center;
 justify-content: center;
 display: flex;
`;

export const CardAvatarCircleDashStyle = styled.div`
 ${CARD_CIRCLE_INNER_LINE}
 border-width: 1px;
 border-style: dashed; 
 border-color: ${GREY};
 align-items: center;
 justify-content: center;
 display: flex;
`;


export const TEXT_AREA_FORMAT = `
border: none;
font-size: 12px;
line-height: 18px;
color: ${ORANGE};
  :hover{
  outline-style: none !important;
  }
  :active{
      outline-style: none;
  }
  :focus{
      outline-style: none;
  }
  cursor: text;
`;


export const REPORT_MODAL_STYLE = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: '5px',
    border: 'dashed 1px #B2B2B2',
    borderColor: 'white',
    transform: 'translate(-50%, -50%)',
    zIndex: '10',
    padding: '4px',
    boxShadow: '0 10px 40px 0 rgba(190,190,207,0.50)',
  },
};

export const ThinContainer = styled.div`
  @media only screen and (max-width: 400px){
     width: 100%;
  }
  width: 360px;
`;

export const GreyContainer = styled.div<{padTop?: string; padBot?: string}>`
   width: 100%;
   background:${WHITE_DARK};
   padding:${(props) => props.padTop || DIST_LARGE} 0 ${(props) => props.padBot || DIST_LARGE} 0;
   border-top: 1px #DEDEDE dashed;
   border-bottom: 1px #DEDEDE dashed;
`;

export const WhiteContainer = styled.div<{padTop?: string; padBot?: string}>`
   width: 100%;
   background: white;
   padding:${(props) => props.padTop || DIST_LARGE} 0 ${(props) => props.padBot || DIST_LARGE} 0;
`;
