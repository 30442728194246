const switchLanguage = (language, obj) => {
  switch (language) {
    // case 'pl-PL':
    //   return obj.pl;
    default:
      return obj.en;
  }
};

export default switchLanguage;
