import React, { memo } from 'react';
import styled from 'styled-components';

import {
  CARD_CIRCLE_SIZE,
  Col,
  FONT_EXAMPLE,
  FONT_EXAMPLE_MOBILE,
  FONT_SIZE_MEDIUM,
  GREY_DARK,
  GREY_MEDIUM,
  LETTER_SPACING_NEG,
  Row,
} from '../../../../styles';
import ImageProf from '../../../UI/Image/ImageProf';


interface Props {
  commentText: string;
  topMargin?: boolean;
}

const IntroCommentBottom: React.FunctionComponent<Props> = ({
  commentText,
  topMargin = false,
}) => (
  <>
    <Row
      center
      bgr={topMargin}
    >
      <MessageGrey>
        <Row
          ver
        >
          {commentText}
        </Row>
      </MessageGrey>
    </Row>
    <Row center>
      <MessageTriangle />
    </Row>
    <Row
      center
      sm
    >
      <ImageProf />
    </Row>
  </>
);

export default memo(IntroCommentBottom);


const MessageTriangle = styled(Col)<{hidden?: boolean}>`
    width: 0;
    height: 0;
    opacity: ${(props) => (props.hidden ? 0 : 1)};
    border-style: solid;
    border-width: 5px 5px 0 5px ;
    border-color: ${GREY_DARK} transparent  transparent transparent;
`;

const MessageGrey = styled(Col)<{hidden?: boolean}>`
  font-family:${FONT_EXAMPLE};
  background: ${GREY_DARK};
  border-radius: 5px;
  padding: 16px;
  color: #FFFFFF;
  line-height: 20px;
  opacity: ${(props) => (props.hidden ? 1 : 1)};
  font-size: 14px;
  letter-spacing: -0.4px;
  font-weight: 700;
  white-space: nowrap;
  display: flexbox;
  @media only screen and (max-width: 426px){
    font-family:${FONT_EXAMPLE_MOBILE};
    font-size: 12px;
  }
`;

export const CardProfileImage = styled.img`
    ${CARD_CIRCLE_SIZE}
`;

export const FormIconLinkStyle = styled.span`
    font-size: ${FONT_SIZE_MEDIUM};
    color: ${GREY_MEDIUM};
    letter-spacing: ${LETTER_SPACING_NEG};
  `;
