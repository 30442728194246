import useWindowSize from '@rehooks/window-size';
import React, {
  FC, ReactNode,
} from 'react';
import styled from 'styled-components';

import robotLoading from '../../../../assets/robotLoading.gif';
import {
  Col,
  DealCardContainerStyle,
  GREY,
  GREY_MEDIUM,
  GreyContainer,
  Row,
  TEXT_CARD_SMALL,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';
import DealCardAttachmentExample
  from '../../../UI/Cards/DealCard/DealCardAttachmentExample';
import ExamplePhoto from '../../../UI/Cards/DealCard/ExamplePhoto';


type Props ={
  children: ReactNode;
}

const AttachmentRow: FC <Props> = ({
  children,
}) => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  return (
    <GreyContainer>
      <Row
        center
      >
        <Container>
          <Col size={1} />
          <Col
            size={5}
            center
            style={{
              marginRight: (innerWidth < 1024 && innerWidth >= 768) ? '24px' : 0,
            }}
          >
            <DealCardContainerStyle
              shadow
              drawer
            >
              <Row md />
              <DealCardAttachmentExample
                attachment={{
                  attachmentName: 'Attachment.pdf',
                }}
              />
              <ExamplePhoto
                photoUri={robotLoading}
                modalDisabled
              />
              <Row
                md
                mdB
              >
                <Col size={4} />
                <Col
                  size={4}
                  center
                  ver
                >
                  <ProofText>
                    Proof
                  </ProofText>
                </Col>
                <Col
                  size={4}
                  jEnd
                  ver
                >
                  <Icon
                    height="20"
                    width="20"
                    iconType={iconTypes.CHEVRON_DOWN}
                    fill={GREY_MEDIUM}
                  />
                </Col>
                <Col size={1} />

              </Row>
            </DealCardContainerStyle>

          </Col>
          <Col
            size={5}
            center
          >
            {children}
          </Col>
          <Col size={1} />
        </Container>
      </Row>
    </GreyContainer>
  );
};

export default AttachmentRow;


const Container = styled.div`
   width: 1444px;
   display: flex;
   @media only screen and (max-width: 1200px){
     width: 100%;
  }
`;

const ProofText = styled.span`
    text-align: center;
    ${TEXT_CARD_SMALL}
    color:${GREY};
`;
