import React from 'react';
import styled from 'styled-components';
import Img from 'react-image';
import ImageCardAvatarPlaceholder from './ImageCardAvatarPlaceholder';
import { shadowCard, CARD_CIRCLE_SIZE, SHADOW_GREY_DARK } from '../../../styles';

const ImageCardAvatar = ({
  image,
}) => (
  <>
    <CardProfileImage
      src={image}
      loader={
        <ImageCardAvatarPlaceholder />
      }
    />
  </>
);
export default ImageCardAvatar;


export const CardProfileImage = styled(Img)`
    ${CARD_CIRCLE_SIZE}
    ${shadowCard(SHADOW_GREY_DARK)}
    object-fit: cover;
`;
