import * as React from 'react';
import styled from 'styled-components';
import {
  Col, Row, shadowCard, SHADOW_GREY,
} from '../../styles';


const LogInContainer: React.FunctionComponent = ({ children }) => (
  <Row
    md
    center
  >
    <Container>
      <Row center>
        <Col
          size={1}
        />
        <PanelWrapperCenter
          size={10}
        >
          { children }
        </PanelWrapperCenter>
        <Col
          size={1}
        >
          <Separator />
        </Col>
      </Row>
    </Container>
  </Row>
);

export default LogInContainer;


const Container = styled.div`
    width: 425px;
    @media (max-width: 425px) {
    width: 100%;
  }
  `;

// const PanelWrapper = styled(Grid)`
//   margin-top: 14px;
// `;

const Separator = styled.div`
  background: #FFFFFF;
  ${shadowCard(SHADOW_GREY)};
  width: 48px;
  height: 368px;
  z-index: -2;
  margin-left: -26px;
  @media (max-width: 425px) {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 64px;
    height: 100%;
  }
`;

const PanelWrapperCenter = styled(Col)`
    display: flex;
    justify-content: center;
`;


// const LogInContainer: React.FunctionComponent = ({ children }) => (
//   <PanelWrapper>
//     <Row center="xs">
//       <Col
//         size={1}
//         md={3}
//         lg={4}
//       />
//       <PanelWrapperCenter
//         size={10}
//         md={6}
//         lg={4}
//       >
//         { children }
//       </PanelWrapperCenter>
//       <Col
//         size={1}
//         md={3}
//         lg={4}
//       >
//         <Separator />
//       </Col>
//     </Row>
//   </PanelWrapper>
// );
