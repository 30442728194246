import React, { FC } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Row, GREY_DARK } from '../../../../styles';
import { imagesLoadingOff } from '../../../../store/actions';

type Props = {
  src: string;
  imagesLoadingOffCon: typeof imagesLoadingOff;
}

const HookFrame: FC <Props> = ({
  src,
  imagesLoadingOffCon,
}) => (
  <>
    <Row
      sm
      center
    >
      <HookTop />
    </Row>
    <Row center>
      <HookCenterLeft />
      <HookCenterMiddle />
      <HookCenterRight />
    </Row>
    <Row center>
      <HookBottomSide />
      <HookBottomMiddle />
      <HookBottomSide />
    </Row>
    <Row center>
      <HookGrabSideLeft />
      <HookGrabMiddleLeft />
      <HookGrabMiddle />
      <HookGrabMiddleRight />
      <HookGrabSideRight />
    </Row>
    <PhoneRow
      center
    >
      <ImagePhone
        src={src}
        alt="alt"
        onLoad={() => imagesLoadingOffCon()}
      />
    </PhoneRow>
  </>
);


const mapStateToProps = () => ({

});

const mapDispatchToProps = {
  imagesLoadingOffCon: imagesLoadingOff,
};

export default connect(mapStateToProps, mapDispatchToProps)(HookFrame);


const PhoneRow = styled(Row)`
    margin-top: -68px;
    /* margin-right: 22px; */
`;


const HookTop = styled.div`
    width: 18px;
    height: 18px;
    border-left: 1px ${GREY_DARK} dashed;
    border-right: 1px ${GREY_DARK} dashed;
    @media only screen and (max-width: 359px){
      width: 12px;
      height: 12px;
    }
`;

const HookCenterMiddle = styled.div`
    width: 18px;
    height: 18px;
    @media only screen and (max-width: 359px){
      width: 12px;
      height: 12px;
    }
`;

const HookCenterLeft = styled.div`
    width: 158px;
    height: 18px;
    border-top: 1px ${GREY_DARK} dashed;
    border-left: 1px ${GREY_DARK} dashed;
    @media only screen and (max-width: 359px){
      width: 155px;
      height: 12px;
    }
`;

const HookCenterRight = styled.div`
    width: 158px;
    height: 18px;
    border-top: 1px ${GREY_DARK} dashed;
    border-right: 1px ${GREY_DARK} dashed;
    @media only screen and (max-width: 359px){
      width: 155px;
      height: 12px;
    }
`;

const HookBottomMiddle = styled.div`
    border-top: 1px ${GREY_DARK} dashed;
    width: 298px;
    height: 18px;
    @media only screen and (max-width: 359px){
      height: 12px;
    }
`;


const HookBottomSide = styled.div`
    width: 18px;
    border-right: 1px ${GREY_DARK} dashed;
    border-left: 1px ${GREY_DARK} dashed;
    height: 68px;
    @media only screen and (max-width: 359px){
      width: 12px;
    }
`;

const HookGrabMiddle = styled.div`
    width: 238px;
    height: 18px;
    @media only screen and (max-width: 359px){
      height: 12px;
    }
`;

const HookGrabSideLeft = styled.div`
    width: 18px;
    border-bottom: 1px ${GREY_DARK} dashed;
    border-left: 1px ${GREY_DARK} dashed;
    height: 18px;
    @media only screen and (max-width: 359px){
      height: 12px;
      width: 12px
    }
`;
const HookGrabSideRight = styled.div`
    width: 18px;
    height: 18px;
    border-bottom: 1px ${GREY_DARK} dashed;
    border-right: 1px ${GREY_DARK} dashed;
    @media only screen and (max-width: 359px){
      height: 12px;
      width: 12px
    }
`;

const HookGrabMiddleLeft = styled.div`
    width: 30px;
    border-bottom: 1px ${GREY_DARK} dashed;
    border-top: 1px ${GREY_DARK} dashed;
    height: 18px;
    @media only screen and (max-width: 359px){
      height: 12px;
    }
`;
const HookGrabMiddleRight = styled.div`
    width: 30px;
    height: 18px;
    border-bottom: 1px ${GREY_DARK} dashed;
    border-top: 1px ${GREY_DARK} dashed;
    @media only screen and (max-width: 359px){
      height: 12px;
    }
`;

const ImagePhone = styled.img`
      @media only screen and (max-width: 1024px){
        width: 320px;
        height: 637px;
      }
      width: 468px;
`;
