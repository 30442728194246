import styled from 'styled-components';
import {
  DIST_SMALL,
  DIST_MEDIUM,
  DIST_BIGGER,
  DIST_BIG,
  DIST_LARGE,
  DIST_X_X_SMALL,
} from './index';

export interface Igrid {
  center?: boolean;
  ver?: boolean;
  jEnd?: boolean;
  vEnd?: boolean;
  cursor?: boolean;
  top?: boolean;
  xsm?: boolean;
  sm?: boolean;
  md?: boolean;
  bgr?: boolean;
  bg?: boolean;
  lg?: boolean;
  xsmB?: boolean;
  smB?: boolean;
  mdB?: boolean;
  bgrB?: boolean;
  bgB?: boolean;
  lgB?: boolean;
  size?: number;
}

export const Row = styled.div<Igrid>`
     display: flex;
     ${(props) => (props.center && 'justify-content: center;')};
     ${(props) => (props.jEnd && 'justify-content: flex-end;')};
     ${(props) => (props.ver && 'align-items: center;')};
     ${(props) => (props.vEnd && 'align-items: flex-end;')};
     ${(props) => (props.cursor && 'cursor: pointer')};
     ${(props) => (props.xsm && `margin-top: ${DIST_X_X_SMALL};`)}
     ${(props) => (props.sm && `margin-top: ${DIST_SMALL};`)}
     ${(props) => (props.md && `margin-top: ${DIST_MEDIUM};`)}
     ${(props) => (props.bgr && `margin-top: ${DIST_BIGGER};`)}
     ${(props) => (props.bg && `margin-top: ${DIST_BIG};`)}
     ${(props) => (props.lg && `margin-top: ${DIST_LARGE};`)}
     ${(props) => (props.lg && `margin-bottom: ${DIST_X_X_SMALL};`)}
     ${(props) => (props.smB && `margin-bottom: ${DIST_SMALL};`)}
     ${(props) => (props.mdB && `margin-bottom: ${DIST_MEDIUM};`)}
     ${(props) => (props.bgrB && `margin-bottom: ${DIST_BIGGER};`)}
     ${(props) => (props.bgB && `margin-bottom: ${DIST_BIG};`)}
     ${(props) => (props.lgB && `margin-bottom: ${DIST_LARGE};`)}
  `;

export const Col = styled(Row)<Igrid>`
     display: flex;
     ${(props) => (props.size ? `flex:${props.size}` : '')};
  `;
