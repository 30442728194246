import React from 'react';
import styled from 'styled-components';

import {
  ORANGE,
  Col,
  GREY_DARK,
  GREY_MEDIUM,
  Row,
  SHADOW_GREY_PHONE_ICON,
  shadowNavAvatar,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';


const IdeaDealIcon = () => (
  <Row
    center
  >
    <Col size={2} />
    <Col
      size={1}
    >

      <Line
        height="30px"
      />
    </Col>
    <Col
      size={4}
      center
      vEnd
    >
      <CircleBot>
        <IconWrapper>
          <Icon
            height="18"
            width="18"
            iconType={iconTypes.HANDSHAKE}
            fill={GREY_DARK}
          />
        </IconWrapper>
      </CircleBot>
    </Col>
    <Col
      size={1}
      jEnd
    >
      <Line
        height="30px"
        colorLine
      />
    </Col>
    <Col size={2} />
  </Row>
);


export default IdeaDealIcon;


const Line = styled.div<{colorLine?: boolean; height: string }>`
  width: 1px;
  border-left: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  margin-top: 6px;
  min-height: ${(props) => props.height};
  height: auto;
`;

const CircleBot = styled.div<{grey?: boolean}>`
    margin-bottom: -81px;
    display: flex;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.grey ? GREY_MEDIUM : 'white')};
  ${((props) => (props.grey
    ? shadowNavAvatar(GREY_DARK) : shadowNavAvatar(SHADOW_GREY_PHONE_ICON)))}; 
    z-index:2;
`;

const IconWrapper = styled.div`
    width: 38px;
    height: 38px;
    border-radius: 19px;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: dashed; 
    border-color: ${GREY_DARK};
    display: flex;
`;
