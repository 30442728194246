import React, { useContext } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import Language from '../../../../contexts/Language';
import switchLanguage from '../../../../hoc/switchLanguage';
import {
  ORANGE,
  Col,
  GREY,
  GREY_DARK,
  Row,
  TEXT_CARD_SMALL,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';


const texts = {
  en: {
    prize: 'Challenge completed - consequences',
    penalty: 'Challenge failed - consequences',
    surprize: 'is surprise',
  },
};

export interface IdealCardPrizeProps {
  prize?: string;
  surprize?: boolean;
  done?: boolean;
  penalty?: boolean;
  surprizeReveal: any;
}

const DealCardPrize = ({
  prize, surprize, done, penalty, surprizeReveal,
}: any) => {
  const locale = useContext(Language);

  const renderText = () => {
    if (prize === null || undefined) {
      return (
        <Badge grayBG>
          WASN'T SET
        </Badge>
      );
    }
    if (surprize && !done && !surprizeReveal) {
      return (
        <Badge>
          SURPRIZE
        </Badge>
      );
    }
    return (<TextLight>{prize && _.isString(prize) ? prize.trim() : ''}</TextLight>);
  };

  return (
    <>
      <Row
        sm
      >
        <Col size={1} />
        <Col size={1}>
          <Wrapper>
            <CircleCol>
              <Icon
                height="14"
                width="14"
                iconType={penalty ? iconTypes.SAD : iconTypes.SMILE}
                fill={GREY_DARK}
              />
            </CircleCol>
          </Wrapper>
        </Col>
        <Col
          size={9}
        >
          <TextDark>{penalty ? switchLanguage(locale, texts).penalty : switchLanguage(locale, texts).prize}</TextDark>
        </Col>
        <Col size={1} />
      </Row>
      {surprizeReveal || (done && surprize) ? (
        <Row sm>
          <Col size={2} />
          <Col size={9}>
            <Badge grayBG>
              SURPRISE REVEALED
            </Badge>
          </Col>
          <Col size={1} />
        </Row>
      ) : null}
      <Row
        xsm
      >
        <Col size={2} />
        <Col
          size={9}
        >
          <Container>
            <Wrapper>
              {renderText()}
            </Wrapper>
          </Container>
        </Col>
        <Col size={1} />
      </Row>
    </>
  );
};

export default DealCardPrize;


export const Container = styled.div`
    display: flex;
`;

export const CircleCol = styled.div`
  border-width: 1.2px;
  border-style: dashed;
  border-color: ${GREY};
  border-radius: 10px;
  width:  20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const TextLight = styled.div`
    ${TEXT_CARD_SMALL}
    color: ${GREY};
    line-height: 20px;
    align-items: center;
`;

export const TextDark = styled(TextLight)`
  color: ${GREY_DARK};
  align-items: center;
  margin-right: 4px;

`;

export const Wrapper = styled.div`
    margin-right: 4px;
`;


const Badge = styled.div<{grayBG?: boolean}>`
   padding: 2px 8px;
   background: ${(props) => (props.grayBG ? GREY : ORANGE)};
   font-size: 9.5px;
   font-weight: 600;
   color: white;
   text-align: center;
   border-radius: 24px;
`;
