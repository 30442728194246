import React from 'react';
import styled from 'styled-components';
import {
  CardAvatarWrapperWhiteStyle, CardAvatarCircleDashStyle,
} from '../../../styles/index';

export default function ImageCardAvatarPlaceholder() {
  return (
    <Wrapper
      shadow
    >
      <CardAvatarCircleDashStyle />
    </Wrapper>
  );
}


const Wrapper = styled(CardAvatarWrapperWhiteStyle)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
