import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import useWindowSize from '@rehooks/window-size';
import { Row, Col } from '../../../../styles/grid';
import Language from '../../../../contexts/Language';
import switchLanguage from '../../../../hoc/switchLanguage';
import { GREY_DARK, GREY_MEDIUM } from '../../../../styles';

type Props= {
  description: string;
  why?: string;
}

const texts = {
  en: {
    why: 'WHY IT WORKS LIKE THAT ?',
  },
};

const Why: FC <Props> = ({
  why,
  description,
}) => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  const locale = useContext(Language);
  return (
    <>
      <Row sm>
        <SubtitleWhy>
          {why || switchLanguage(locale, texts).why}
        </SubtitleWhy>
      </Row>
      <Row sm>
        <Col size={innerWidth > 1024 ? 10 : 1}>
          <DescriptionWhy>
            {description}
          </DescriptionWhy>
        </Col>
        {innerWidth > 1024 && <Col size={2} />}
      </Row>
    </>
  );
};

export default Why;


const SubtitleWhy = styled.h3`
   font-size: 14px;
  color: ${GREY_DARK};
  letter-spacing: 2.67px;
  text-transform: uppercase;
  font-weight: bold;
`;

const DescriptionWhy = styled.p`
   font-size: 16px;
    color: ${GREY_MEDIUM};
    letter-spacing: -0.5px;
    font-style: italic;
`;
