import * as React from 'react';
import styled from 'styled-components';
import { Ideal } from '../../../../interfaces/dealTypes';
import { Col, Row } from '../../../../styles/index';
import DealCardAttachmentButtons from './DealCardAttachmentButtons';
import DealCardAttachmentFrame from './DealCardAttachmentFrame';


type Props = Pick<Ideal, 'attachment'>


const DealCardAttachment: React.FunctionComponent<Props> = ({
  attachment,
}) => (
  <>
    <Row
      md
    >
      <Col size={1} />
      <ImageContainer
        size={8}
        center
        ver
      >
        <DealCardAttachmentFrame
          filename={attachment ? attachment.attachmentName : ''}
        />
      </ImageContainer>
      <DealCardAttachmentButtons
        attachment={attachment}
      />
      <Col size={1} />
    </Row>
  </>
);
export default DealCardAttachment;

const ImageContainer = styled(Col)`
   height: 120;
`;
