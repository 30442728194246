import React from 'react';

import useWindowSize from '@rehooks/window-size';
import FeaturesDesktop from './FeaturesDesktop';
import FeaturesMobile from './FeaturesMobile';

const Features = () => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;

  if (innerWidth < 768) {
    return (
      <FeaturesMobile />
    );
  }
  return (
    <FeaturesDesktop />
  );
};
export default Features;
