import React, { FC, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import DealPublicFooter from '../components/Footer/DealPublicFooter/DealPublicFooter';
import IdeaItem from '../components/Idea/IdeaItem';
import DealPublicNav from '../components/Nav/Public/DealPublicNav/DealPublicNav';
import firebase from '../firebase';
import DealNotExists from './DealNotExists';
import Loading from './Loading';

type Props = {
  location: {
    pathname: string;
  };
}


export type Idea = {
  author: {
    bannerUri: string;
    authorName: string;
    authorUri: string;
    authorAvatar: string;
  } | null;
  dateCreated: Date;
  dateDue: Date | null;
  dealname: string;
  friendPenalty: boolean;
  friendPrize: boolean;
  ideaLink: string | null;
  ideaPhoto: string | null;
  importLink: string;
  invitedIsWitness: boolean;
  penalty: string | null;
  privateIdea: boolean;
  prize: string | null;
  surprize: boolean;
  surprizePenalty: boolean;
}


const Idea: FC <Props> = (
  { location },
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [idea, setIdea] = useState<Idea | null | undefined>(null);
  const [notExists, setNotExists] = useState<boolean>(false);
  const ideaId = location.pathname.slice(7);

  console.log(ideaId);

  useEffect(() => {
    const ref = firebase.firestore().doc(`ideas/${ideaId}`);
    const unsubscribe = ref.onSnapshot((snapshot) => {
      if (snapshot.exists) {
        setIsLoading(false);
        const a: any = { ...snapshot.data() };
        setIdea(a);
      } else {
        setIsLoading(false);
        setIdea(null);
        setNotExists(true);
      }
    }, () => {
      setIsLoading(false);
      setNotExists(true);
    });
    return () => {
      unsubscribe();
    };
  }, [ideaId]);

  if (isLoading) {
    return (
      <Loading />);
  }
  if (notExists) {
    return (
      <DealNotExists
        dealIdea
      />
    );
  }
  if (idea) {
    return (
      <Container>
        <Main>
          <DealPublicNav
            dealId={ideaId}
            noLogin
          />
          <IdeaItem
            idea={idea}
            key={ideaId}
            ideaId={ideaId}
            single
          />
        </Main>
        <DealPublicFooter
          dealIdea
        />
      </Container>
    );
  }
  return null;
};


const withDealPublic = withRouter(Idea as any);

export default withDealPublic;


const Container = styled.div`
  height:100vh;
  display: flex;
  flex-direction: column;  
`;

const Main = styled.main`
  flex: 1 0 auto; 
`;
