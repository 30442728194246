import React, { FC } from 'react';
import styled from 'styled-components';
import {
  Col, GREY_MEDIUM, Row, shadowCard, SHADOW_GREY_DARK,
} from '../../../styles';
import Icon from '../../../styles/Icon';
import { SWITCH_OFF, SWITCH_ON } from '../../../styles/iconTypes';
import { CardTitleDescriptionTextStyle, CardTitleLightTextStyle, CardTitleTextStyle } from '../../../styles/texts';


type Props = {
  title: string;
  titleLight: string;
  description: string;
  off?: boolean;
}

const PremiumCard: FC <Props> = ({
  title,
  titleLight,
  description,
  off,
}) => (
  <Container>
    <UpperRow
      ver
    >
      <Col
        size={2}
        center
      >
        <Icon
          height="32"
          width="32"
          iconType={off ? SWITCH_OFF : SWITCH_ON}
          fill="white"
        />
      </Col>
      <ContainerTitle
        size={8}
        center
      >
        <UpperRowTitle>
          <Title>
            {title}
          </Title>
          <TitleLight>
            {titleLight}
          </TitleLight>
        </UpperRowTitle>
        <Description>
          {description}
        </Description>
      </ContainerTitle>
      <Col size={1} />
    </UpperRow>
  </Container>
);

export default PremiumCard;


const Container = styled.div`
  width: 425px;
  background:${GREY_MEDIUM};
  border-radius: 5px;
  ${shadowCard(SHADOW_GREY_DARK)};
  z-index:2;
  @media only screen and (max-width: 425px){
     width: 350px;
  }
`;

const UpperRow = styled(Row)`
    padding: 8px 0;
`;

const ContainerTitle = styled(Col)`
  flex-direction: column;
  padding-left: 12px;
`;

export const UpperRowTitle = styled.h2`
   display: flex;
`;

const Title = styled(CardTitleTextStyle)`
  color: white;
`;

const TitleLight = styled(CardTitleLightTextStyle)`
  color: white;
`;

const Description = styled(CardTitleDescriptionTextStyle)`
    margin-top: 2px;
    color: white;
`;
