import React, { useContext } from 'react';
import styled from 'styled-components';

import Language from '../../contexts/Language';
import switchLanguage from '../../hoc/switchLanguage';
import {
  Col,
  DIST_SMALL,
  FONT_SIZE_SMALL,
  GREY,
  GREY_MEDIUM,
  Row,
} from '../../styles';


const texts = {
  en: {
    completed: 'COMPLETED',
    preview: 'PREVIEW',
  },
};

const DealItemDone = (props: {
  done?: boolean;
  preview?: boolean;
}) => {
  const locale = useContext(Language);
  const { done, preview } = props;
  if (done) {
    return (
      <Row
        md
        center
      >
        <Col size={1} />
        <Wrapper
          ver
          center
          size={10}
        >
          <MainText>{switchLanguage(locale, texts).completed}</MainText>
        </Wrapper>
        <Col size={1} />
      </Row>
    );
  }
  if (preview) {
    return (
      <Row
        md
        center
      >
        <Col size={1} />
        <Wrapper
          ver
          center
          size={10}
        >
          <MainText>{switchLanguage(locale, texts).preview}</MainText>
        </Wrapper>
        <Col size={1} />
      </Row>
    );
  }
  return null;
};

export default DealItemDone;

const Wrapper = styled(Col)`
    border: 1px dashed ${GREY}; 
    padding: ${DIST_SMALL} 0;
    border-radius: 5;
    /* margin-left: 14; */
`;

const MainText = styled.span`
   color: ${GREY_MEDIUM};
   letter-spacing: -0.2px;
   font-size: ${FONT_SIZE_SMALL};
   line-height: 20px;
   text-align: center;
`;
