import React, { useContext } from 'react';
import styled from 'styled-components';

import Language from '../../../../contexts/Language';
import switchLanguage from '../../../../hoc/switchLanguage';
import {
  Col,
  DIST_MEDIUM,
  DIST_SMALL,
  GREY,
  GREY_DARK,
  GREY_MEDIUM,
  Row,
  H1,
  FONT_FUTURA,
} from '../../../../styles';
import * as iconTypes from '../../../../styles/iconTypes';
import IntroCommentNoAnim from '../Comments/IntroCommentNoAnim';
import IntroCardMobile from '../IntroStyle/IntroCardMobile';
import { texts } from './IntroDealCards';


const IntroDealdMobile = () => {
  const locale = useContext(Language);
  return (
    <Row bg>
      <Col size={1} />
      <Col size={10}>
        <Container>
          <Subtile>
            {switchLanguage(locale, texts).subtitle}
          </Subtile>
          <Title>
            {switchLanguage(locale, texts).title}
          </Title>
          <IntroCardMobile
            title={switchLanguage(locale, texts).dealIdeaTitle}
            titleLight={switchLanguage(locale, texts).dealIdeaTitleLight}
            description={switchLanguage(locale, texts).dealIdeaDescription}
            iconType={iconTypes.BULB}
            titleTagComponent={<H1 />}
          />
          <IntroCardMobile
            title={switchLanguage(locale, texts).prizeTitle}
            titleLight={switchLanguage(locale, texts).prizeTitleLight}
            description={switchLanguage(locale, texts).prizeDescription}
            iconType={iconTypes.USER}
          />
          <IntroCardMobile
            title={switchLanguage(locale, texts).friendTitle}
            titleLight={switchLanguage(locale, texts).friendTitleLight}
            description={switchLanguage(locale, texts).friendDescription}
            iconType={iconTypes.SMILE}
          />
          <Row
            center
            sm
          >
            <IntroCommentNoAnim
              exampleText="Got yours challenge idea, HUMAN ?!"
              rightAvatar
            />
          </Row>
          <SubtitleWhy>
            {switchLanguage(locale, texts).why}
          </SubtitleWhy>
          <DescriptionWhy>
            {switchLanguage(locale, texts).descriptionWhy}
          </DescriptionWhy>
        </Container>
      </Col>
      <Col size={1} />
    </Row>
  );
};

export default IntroDealdMobile;


const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subtile = styled.p`
  font-size: 14px;
  color: ${GREY_MEDIUM};
  letter-spacing: 2.67px;
  text-transform: uppercase;
  font-weight: bold;
`;

const Title = styled.h2`
  font-size: 32px;
  color: ${GREY_DARK};
  letter-spacing: -2px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: ${DIST_SMALL};
  margin-top: 12px;
  font-family: ${FONT_FUTURA};
`;


const SubtitleWhy = styled(Subtile)`
   margin-top: ${DIST_MEDIUM};
`;

const DescriptionWhy = styled.h3`
   font-size: 16px;
    color: ${GREY};
    letter-spacing: -0.5px;
    font-style: italic;
    margin-top: ${DIST_MEDIUM};
`;

export const Border = styled.div`
    border-top: 1px dashed ${GREY_DARK};
    height: 1px; 
    width: 42px;
    margin-top: ${DIST_MEDIUM};
`;
