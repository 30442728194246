import React from 'react';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';
import {
  ORANGE, Col, GREY_DARK, Row,
} from '../../../../styles';

const ArrowsRow = () => (
  <Row
    center
    ver
  >
    <Col size={1} />
    <Col
      size={2}
      center
    >
      <Icon
        height="16"
        width="16"
        iconType={iconTypes.ARROW_DOWN_DECOR}
        fill={GREY_DARK}
      />
    </Col>
    <Col
      size={4}
      center
      ver
    />
    <Col
      size={2}
      center
    >
      <Icon
        height="16"
        width="16"
        iconType={iconTypes.ARROW_DOWN_DECOR}
        fill={ORANGE}
      />
    </Col>
    <Col size={1} />
  </Row>
);


export default ArrowsRow;
