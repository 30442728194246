import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styled from 'styled-components';
import Icon from '../../../styles/Icon';
import { ORANGE, GREY_MEDIUM } from '../../../styles/index';


const NavLinkLocal = ({
  iconType,
  iconTypeHover,
  link,
  text,
  offset = '0',
}) => {
  const [isIconHover, onIconHover] = useState(false);
  return (
    <Container
      onMouseEnter={() => onIconHover(true)}
      onMouseLeave={() => onIconHover(false)}
    >
      <Link
        href={link}
        offset={offset}
      >
        <FormIconWrapper>
          <Icon
            height="20"
            width="20"
            iconType={isIconHover ? iconTypeHover : iconType}
            fill={isIconHover ? ORANGE : GREY_MEDIUM}
          />
        </FormIconWrapper>
        <FormIconLinkStyle
          isIconHover={isIconHover}
        >
          {text}
        </FormIconLinkStyle>
      </Link>
    </Container>
  );
};

export default NavLinkLocal;

const Container = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`;

const FormIconLinkStyle = styled.span`
    font-size: 14px;
    color: ${props => (props.isIconHover ? ORANGE : GREY_MEDIUM)};
    letter-spacing: -0.5px;
    cursor: pointer;
  `;

const FormIconWrapper = styled.span`
    margin-right: 10px;
    margin-top: 2px;
  `;


const Link = styled(AnchorLink)`
   display: flex;
   justify-content: center;
   align-items: center;
`;
