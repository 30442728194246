import React, { FC, ReactNode } from 'react';
import Icon from '../../../styles/Icon';
import * as iconTypes from '../../../styles/iconTypes';
import { ArrowColumn, ArrowPadding } from './Arrow';


type Props = {
  fill: string; bottom?: boolean; children: ReactNode; noArrow?: boolean;
}

const ArrowBottom: FC <Props> = ({
  fill,
  bottom,
  children,
  noArrow,
}) => (
  <ArrowColumn
    bottom={bottom}
  >
    <ArrowPadding>
      {children}
    </ArrowPadding>
    {noArrow ? null
      : (
        <Icon
          height="16"
          width="16"
          iconType={iconTypes.ARROW_DOWN_DECOR}
          fill={fill}
        />
      )}
  </ArrowColumn>
);

export default ArrowBottom;
