import React, { FC } from 'react';
import styled from 'styled-components';

import { Idea } from '../../../screens/Idea';
import { Row } from '../../../styles';
import ArrowsRow from './IdeaBody/ArrowsRow';
import Author from './IdeaBody/Author/Author';
import AuthorArrows from './IdeaBody/Author/AuthorArrows';
import ChallengeCard from './IdeaBody/ChallengeCard/ChallengeCard';
import ConsequencesTitle from './IdeaBody/ConsequencesTitle';
import IdeaBottomIcons from './IdeaBody/IdeaBottomIcons';
import IdeaCurvedBottom from './IdeaBody/IdeaCurvedBottom';
import IdeaCurvedLines from './IdeaBody/IdeaCurvedLines';
import IdeaDealIcon from './IdeaBody/IdeaDealIcon';
import IdeaLine from './IdeaBody/IdeaLine';
import IdeaLinesDeal from './IdeaBody/IdeaLinesDeal';
import IdeaPersons from './IdeaBody/IdeaPersons';
import IdeaPrizePenalty from './IdeaBody/IdeaPrizePenalty';
import IdeaThinLines from './IdeaBody/IdeaThinLines';

const IdeaBody: FC <{
  idea: Idea;
}> = ({
  idea,
}) => {
  const {
    author,
    dateDue,
    dealname,
    friendPenalty,
    friendPrize,
    ideaPhoto,
    invitedIsWitness,
    penalty,
    prize,
    surprize,
    surprizePenalty,
  } = idea;
  return (
    <Row
      center
    >
      <Container>
        <Author author={author} />
        <AuthorArrows />
        <ChallengeCard
          title={invitedIsWitness ? 'Your' : "Friend's"}
          titleLight="Challenge"
          dateDue={dateDue}
          challengePhoto={ideaPhoto}
          dealname={dealname}
          titleCenter={dealname.length < 20}
        />
        <Row center>
          <ThinContainer>
            <IdeaLine
              lineHeight={30}
            />
            <IdeaPersons
              committedUsername={invitedIsWitness ? 'You' : 'Friend'}
              witnessUsername={invitedIsWitness ? 'Friend' : 'You'}
              invitedIsWitness={invitedIsWitness}
            />
            <Row center>
              <ThinContainer>
                <IdeaDealIcon />
                <IdeaLinesDeal />
                <IdeaCurvedLines />
                <IdeaThinLines />
                <IdeaCurvedBottom />
                <IdeaBottomIcons />
                <ArrowsRow />
                <ConsequencesTitle />
                <IdeaPrizePenalty
                  prize={prize}
                  penalty={penalty}
                  friendPenalty={friendPenalty}
                  friendPrize={friendPrize}
                  surprize={surprize}
                  surprizePenalty={surprizePenalty}
                />
              </ThinContainer>
            </Row>
          </ThinContainer>
        </Row>
      </Container>
    </Row>
  );
};


export default IdeaBody;


const Container = styled.div<{bgColor?: string}>`
    width: 1024px;
  `;

export const ThinContainer = styled.div`
  @media only screen and (max-width: 400px){
     width: 100%;
  }
  width: 360px;
`;
