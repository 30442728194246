import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Col, GREY, GREY_MEDIUM, Row, TEXT_CARD_SMALL,
} from '../../styles';
import Icon from '../../styles/Icon';
import * as iconTypes from '../../styles/iconTypes';
import DealItemDrawerChevron from '../UI/Cards/DealCard/DealItemDrawerChevron';


type Props = {
  isContentVisible: boolean;
  onContentShow: Function;
  done: boolean;
  attachment: any;
  proof: any;
  addMedia: boolean;
  startingPoint: any;
}


const DealItemDrawerBottomRow: React.FunctionComponent<Props> = ({
  isContentVisible,
  attachment,
  proof,
  startingPoint,
  onContentShow,
  addMedia,
}) => {
  const [isBarHover, onBarHover] = useState(false);
  return (
    <Row
      ver
      cursor
      sm={isContentVisible}
      onClick={() => onContentShow()}
      onMouseEnter={() => onBarHover(true)}
      onMouseLeave={() => onBarHover(false)}
    >
      <Col size={1} />
      <DealItemDrawerChevron
        isContentVisible={isContentVisible}
        isBarHover={isBarHover}
      />
      <Col
        size={4}
        center
        ver
      >
        {isContentVisible
        && (
          <ProofText
            isBarHover={isBarHover}
          />
        )}
      </Col>
      <Col
        size={3}
        jEnd
        ver
      >
        {attachment && (
          <Icon
            height="20"
            width="20"
            iconType={iconTypes.PAPERCLIP}
            fill={isBarHover ? GREY_MEDIUM : GREY}
          />
        )}
        {(proof || startingPoint || addMedia) && (
          <Icon
            height="20"
            width="20"
            iconType={iconTypes.PHOTO}
            fill={isBarHover ? GREY_MEDIUM : GREY}
          />
        )}
      </Col>
      <Col size={1} />
    </Row>
  );
};

export default DealItemDrawerBottomRow;


const ProofText = styled.span<{isBarHover: boolean}>`
    text-align: center;
    ${TEXT_CARD_SMALL}
    color:${(props) => (props.isBarHover ? GREY_MEDIUM : GREY)}
`;
