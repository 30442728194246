import useWindowSize from '@rehooks/window-size';
import React from 'react';

import phone from '../../../../assets/phoneDeal.png';
import { Row } from '../../../../styles';
import PhoneRowMobile from './PhoneRowMobile';


const PhoneAnimMobile = () => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;

  const renderPhoneImage = () => {
    if (innerWidth < 360) {
      return (
        <Row
          center
          bg
          style={{ marginRight: '4px', marginLeft: '4px' }}
        >
          <img
            src={phone}
            alt="alt"
          />
        </Row>
      );
    }
    return (
      <PhoneRowMobile
        positionY={2500}
      />
    );
  };

  return (
    <div>
      {renderPhoneImage()}
    </div>
  );
};

export default PhoneAnimMobile;
