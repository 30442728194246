import React from 'react';
import { HamburgerButton } from 'react-hamburger-button';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openMenu } from '../../../../store/actions/index';
import { Col, GREY_LIGHT, Row } from '../../../../styles/index';
import HomeMenuMobile from '../../../Home/Menu/HomeMenuMobile';
import NavAppName from '../NavAppName';

export const texts = {
  en: {
    as: 'App Store',
    gp: 'Google Play',
    logIn: 'Login',
    logout: 'Logout',
  },
};

type Props = {
  openMenu: Function;
  isMenuOpen: boolean;
  onSignOut: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const HomeNavMobile: React.FunctionComponent <Props> = ({
  // eslint-disable-next-line no-shadow
  openMenu,
  isMenuOpen,
  onSignOut,
}) => (
  <>
    {isMenuOpen ? (
      <HomeMenuMobile
        onSignOut={onSignOut}
      />
    ) : null}
    <Row
      md
      center
    >
      <Container>
        <Row>
          <Col size={1} />
          <Col
            size={9}
            ver
          >
            <NavAppName />
          </Col>
          <Col
            size={2}
            center
            cursor
            ver
          >
            <HamburgerButton
              open={isMenuOpen}
              width={18}
              height={16}
              strokeWidth={1}
              animationDuration={0.5}
              color={GREY_LIGHT}
              onClick={() => openMenu(true)}
            />
          </Col>
        </Row>
      </Container>
    </Row>
  </>
);


const mapStateToProps = (state: any) => ({
  openMenu: state.openMenu,
  isMenuOpen: state.user.isMenuOpen,
});

export default connect(mapStateToProps,
  {
    openMenu,
  })(HomeNavMobile);

const Container = styled.div`
    width: 768px;
    width: 1440px;
  @media only screen and (max-width: 768px){
     width: 768px;
  }
  `;
