import React, { FC } from 'react';
import styled from 'styled-components';

import {
  Col, GREY_LIGHT, Row, TEXT_CARD_SMALL,
} from '../../../styles';

const MediaDesc: FC<{
  description: string;
}> = ({ description }) => (
  <Row xsm>
    <Col
      size={1}

    />
    <Col
      center
      size={10}
      ver
    >
      <DescriptionText>{description}</DescriptionText>
    </Col>
    <Col
      size={1}

    />
  </Row>
);


export default MediaDesc;

const DescriptionText = styled.div`
    text-align: center;
    ${TEXT_CARD_SMALL}
    color:${GREY_LIGHT}
`;
