import React, { memo } from 'react';
import styled from 'styled-components';
import {
  ORANGE,
  CARD_CIRCLE_SIZE,
  Col,
  FONT_EXAMPLE,
  FONT_SIZE_MEDIUM,
  GREY_DARK,
  GREY_MEDIUM,
  LETTER_SPACING_NEG,
  Row,
  FONT_EXAMPLE_MOBILE,
} from '../../../../styles';
import ImageProf from '../../../UI/Image/ImageProf';
import ImageRobot from '../../../UI/Image/ImageRobot';

interface Props {
  rightAvatar?: any;
  exampleText: string;
  imageUrl?: string;
  imageProf?: boolean;
  noMargins?: boolean;
}

const IntroCommentNoAnim: React.FunctionComponent<Props> = ({
  rightAvatar,
  exampleText,
  imageProf,
  noMargins,
}) => {
  const renderAvatar = () => (
    <>
      {
        imageProf ? <ImageProf /> : <ImageRobot />
      }
    </>
  );

  const renderRightComment = () => (
    <>
      <MessageRow
        noMargins={noMargins}
      >
        <Col
          size={2}
          center
        >
          {renderAvatar()}
        </Col>
        <MessageTriangle size={1} />
        <MessageGrey
          size={9}
        >
          <Col
            ver
          >
            {exampleText}
          </Col>
        </MessageGrey>
      </MessageRow>
    </>
  );

  const renderrightAvatar = () => (
    <>
      <MessageRow
        rightAvatar={rightAvatar}
        noMargins={noMargins}
      >
        <Message
          size={9}
        >
          <Col
            ver
          >
            {exampleText}
          </Col>
        </Message>
        <MessageTriangleLeft size={1} />
        <Col
          size={2}
          jEnd
        >
          {renderAvatar()}
        </Col>
      </MessageRow>
    </>
  );

  if (rightAvatar) {
    return (
      <>
        {renderrightAvatar()}
      </>
    );
  }
  return (
    <>
      {renderRightComment()}
    </>
  );
};

export default memo(IntroCommentNoAnim);

const MessageRow = styled(Row)<{rightAvatar?: boolean; noMargins?: boolean}>`
    align-items: center;
    /* padding-left: 40px; */
    ${(props) => (props.noMargins ? 'margin:0px' : 'margin:32px 0')}
    justify-content: flex-end;
`;

const MessageTriangle = styled(Col)`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent ${GREY_DARK} transparent transparent;
    margin-left: 12px;
`;

const MessageTriangleLeft = styled(Col)`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent ${ORANGE};
    margin-right: 12px;
`;

const Message = styled(Col)`
  font-family:${FONT_EXAMPLE};
  background: ${ORANGE};
  border-radius: 5px;
  padding: 17px;
  color: #FFFFFF;
  font-size: 14px;
  letter-spacing: -0.4px;
  font-weight: 700;
  white-space: nowrap;
  margin-Left: 32px;
  @media only screen and (max-width: 426px){
    font-family:${FONT_EXAMPLE_MOBILE};
    font-size: 11px;
    padding: 12;
    margin-Left: 0;
  }
`;

const MessageGrey = styled(Col)`
  font-family:${FONT_EXAMPLE};
  background: ${GREY_DARK};
  border-radius: 5px;
  padding: 17px;
  color: #FFFFFF;
  line-height: 20px;
  /* font-weight: 700; */
  font-size: 14px;
  letter-spacing: -0.4px;
  font-weight: 700;
  white-space: nowrap;
  @media only screen and (max-width: 400px){
    font-family:${FONT_EXAMPLE_MOBILE};
    font-size: 12px;
  }
`;

export const CardProfileImage = styled.img`
    ${CARD_CIRCLE_SIZE}
`;

export const FormIconLinkStyle = styled.span`
    font-size: ${FONT_SIZE_MEDIUM};
    color: ${GREY_MEDIUM};
    letter-spacing: ${LETTER_SPACING_NEG};
  `;
