import React, { FC } from 'react';
import styled from 'styled-components';

import blackOrangeLogo from '../../../assets/LogoDealem.svg';
import {
  Col, FONT_BOLD, GREY, GREY_DARK, LINK_NO_STYLE, ORANGE, Row, TEXT_CARD_SMALL,
} from '../../../styles';

const ImportIdea: FC <{
  importLink: string;
}> = ({
  importLink,
}) => (
  <>
    <Row
      md
    >
      <Col
        size={5}
        ver
        jEnd
      >
        <TextDark>
          IMPORT IDEA TO DEALEM
        </TextDark>
      </Col>
      <Col
        size={2}
        ver
        center
      >
        <img
          alt=""
          src={blackOrangeLogo}
          height="32px"
          width="32px"
        />
      </Col>
      <Col
        size={6}
        ver
      >
        <a
          style={LINK_NO_STYLE}
          href={importLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TextLink>
            {importLink}
          </TextLink>
        </a>
      </Col>
    </Row>
    <Row
      center
      md
    >
      <Col size={1} />
      <Col
        size={10}
        center
      >
        <TextSmall>If you press the link on mobile it will create a challenge contract invitation in your Dealem app with the same challenge and consequences.</TextSmall>
      </Col>
      <Col size={1} />
    </Row>
  </>
);

export default ImportIdea;


export const TextLink = styled.div`
  color: ${ORANGE};
  align-items: center; 
  text-decoration: underline;
  font-weight: 700;   
  ${TEXT_CARD_SMALL}
    align-items: flex-end;
`;

export const TextDark = styled.div`
  color: ${GREY_DARK};
  align-items: center;
  font-weight: ${FONT_BOLD}; 
  @media only screen and (max-width: 425px){
    font-size: 10px
  }
  font-size: 11px;
  align-items: center;
  text-decoration: underline;
`;

const TextSmall = styled.div`
  font-size: 11px;
  color: ${GREY};
  display: flex;
  justify-content: center;
  padding: 0 4px;
  font-style: italic; 
  text-align: center;
`;
