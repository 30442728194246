import React, { FC } from 'react';
import { ClipLoader } from 'react-spinners';
import Icon from '../../../styles/Icon';
import * as iconTypes from '../../../styles/iconTypes';
import { ButtonRound } from '../../../styles/index';


type Props = {
  loading?: boolean;
  success?: boolean;
  error?: boolean|null;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}


const NextButton: FC <Props> = ({
  loading,
  onClick,
  error,
  success,
  disabled,
}) => {
  const renderIconType = () => {
    if (error) {
      return (
        <Icon
          height="14"
          width="14"
          iconType={iconTypes.CLOSE}
          fill="white"
          stroke="white"
          strokeWidth="2"
        />
      );
    }
    return (
      <Icon
        height="14"
        width="14"
        iconType={iconTypes.ARROW_RIGHT}
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
    );
  };

  return (
    <ButtonRound
      onClick={onClick}
      disabled={loading || error || success || disabled}
      type="submit"
      error={error}
    >
      {loading
        ? (
          <ClipLoader
            sizeUnit="px"
            size={16}
            color="white"
          />
        )
        : (
          renderIconType()
        )}
    </ButtonRound>
  );
};


export default NextButton;
