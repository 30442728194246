import React, { FC, useState } from 'react';
import styled from 'styled-components';
import IdeaBody from './IdeaItem/IdeaBody';
import IdeaMenuBottom from './IdeaItem/IdeaMenuBottom';
import IdeaStoreBadges from './IdeaItem/IdeaStoreBadges';
import ImportIdea from './IdeaItem/ImportIdea';
import IdeaTitle from './IdeaItem/IdeaTitle';
import IdeaSubtitle from './IdeaItem/IdeaSubtitle';
import AnimOpacity from '../../hoc/AnimOpacityDealname';
import {
  Col, GREY_DARK, Row, ThinContainer, DASH_COLOR,
} from '../../styles';
import IdeaPreview from './IdeaItem/IdeaPreview';
import Icon from '../../styles/Icon';
import * as iconTypes from '../../styles/iconTypes';
import { Idea } from '../../screens/Idea';
import { WWW } from '../../env.json';

type Props = {
  idea: Idea;
  single?: boolean;
  bgColor?: string;
  ideaId: string;
}


const IdeaItem: FC<Props> = ({
  idea,
  single,
  bgColor = 'white',
  ideaId,
}) => {
  const [previewOn, setPreviewOn] = useState(false);

  const {
    author,
    dateCreated,
    dealname,
    ideaLink,
    importLink,
    invitedIsWitness,
    penalty,
    prize,
    surprize,
    surprizePenalty,
  } = idea;

  const BottomContainer: FC = ({ children }) => (
    <Row
      center
      md
    >
      <ThinContainer>
        {children}
      </ThinContainer>
    </Row>
  );

  if (previewOn) {
    return (
      <Wrapper
        bgColor={bgColor}
      >
        <AnimOpacity
          animOn={previewOn}
        >
          <Row
            center
            bg
          >
            <PreviewContainer>
              <Row>
                <Col size={1} />
                <Col
                  size={10}
                />
                <Col
                  size={1}
                  onClick={() => setPreviewOn(false)}
                >
                  <Icon
                    height="24"
                    width="24"
                    iconType={iconTypes.CLOSE}
                    fill={GREY_DARK}
                  />
                </Col>
              </Row>
            </PreviewContainer>
          </Row>
          <IdeaPreview
            author={author}
            committedUsername={invitedIsWitness ? 'You' : 'Friend'}
            dealname={dealname}
            invitedIsWitness={invitedIsWitness}
            penalty={penalty}
            prize={prize}
            surprize={surprize}
            surprizePenalty={surprizePenalty}
            witnessUsername={invitedIsWitness ? 'Friend' : 'You'}
          />
        </AnimOpacity>
      </Wrapper>
    );
  }

  return (
    <Container
      bgColor={bgColor}
      single={single}
    >
      <IdeaTitle />
      <IdeaSubtitle
        authorName={author?.authorName ?? null}
        authorUri={author?.authorUri ?? ''}
        bannerUri={author?.bannerUri ?? ''}
        ideaLink={ideaLink}
        dateCreated={dateCreated}
      />
      <IdeaBody
        idea={idea}
      />
      <BottomContainer>
        <>
          <ImportIdea
            importLink={importLink}
          />
          <IdeaMenuBottom
            setPreviewOn={setPreviewOn}
            linkToDealIdea={`${WWW}/ideas/${ideaId}`}
            invitedIsWitness={invitedIsWitness}
          />
          <IdeaStoreBadges />
        </>
      </BottomContainer>
    </Container>
  );
};


export default IdeaItem;


const PreviewContainer = styled.div`
  @media only screen and (max-width: 400px){
    width: 320px;
  }
  width: 360px;
  padding-top: 32px;
`;

const Wrapper = styled.div<{bgColor?: string}>`
     min-height:600px;
     background: ${(props) => props.bgColor};
     width: 100%;
`;

const Container = styled.div<{bgColor?: string; single?: boolean}>`
     background: ${(props) => props.bgColor};
     width: 100%;
     padding-top: ${(props) => (props.single ? '72px' : '48px')};
     border-top: ${DASH_COLOR} ${(props) => (props.single ? '0px' : '1px')} dashed;
`;
