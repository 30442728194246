import * as iconTypes from '../../../styles/iconTypes';

const generateIconName = (nr: number) => {
  switch (nr) {
    case 1:
      return iconTypes.CALENDAR;
    case 2:
      return iconTypes.SHARE;
    case 3:
      return iconTypes.SPEECH_BUBBLE;
    case 4:
      return iconTypes.SMILE;
    case 5:
      return iconTypes.PHOTO;
    case 6:
      return iconTypes.USER;
    case 7:
      return iconTypes.PAPERCLIP;
    case 8:
      return iconTypes.HOME;
    case 9:
      return iconTypes.BULB;
    case 10:
      return iconTypes.GEAR;
    default:
      return '';
  }
};


export default generateIconName;

export const generateIconRotate = (nr: number) => {
  switch (nr) {
    case 1:
      return '45deg';
    case 2:
      return '135deg';
    case 3:
      return '225deg';
    case 4:
      return '310deg';
    default:
      return '';
  }
};

export const generateIconRotateQ = (nr: number) => {
  switch (nr) {
    case 1:
      return '90deg';
    case 2:
      return '180deg';
    case 3:
      return '275deg';
    case 4:
      return '360deg';
    default:
      return '';
  }
};


export const generateIconNew = (nr: number) => {
  switch (nr) {
    case 1:
      return iconTypes.BULB;
    case 2:
      return iconTypes.BULB;
    case 3:
      return iconTypes.BULB;
    case 4:
      return iconTypes.BULB;
    case 5:
      return iconTypes.BULB;
    case 6:
      return iconTypes.BULB;
    case 7:
      return iconTypes.BULB;
    case 8:
      return iconTypes.BULB;
    case 9:
      return iconTypes.BULB;
    case 10:
      return iconTypes.BULB;
    default:
      return '';
  }
};