import _ from 'lodash';
import React, {
  useContext, useState, FC,
} from 'react';
import styled from 'styled-components';

import Language from '../../contexts/Language';
import firebase from '../../firebase';
import switchLanguage from '../../hoc/switchLanguage';
import { DealId } from '../../interfaces/dealTypes';
import {
  ButtonEmpty,
  FONT_SIZE_MEDIUM,
  GREY,
  GREY_MEDIUM,
  LETTER_SPACING_NEG,
  Row,
  ERROR_COLOR,
  GREY_LIGHT,
  ORANGE,
} from '../../styles';
import DealCommentReportReason from './DealCommentReportReason';

export const texts = {
  en: {
    button: 'Report challenge contract',
    reason: 'reason for report',
    terms: 'validation of app terms',
    myRights: 'validation of my rights',
    otherReason: 'other reason',
    error: 'select the reason',
    success: 'report sent',
  },
};

type Props = {
  dealId: DealId;
  closeModal: () => any;
}

const DealReport: FC <Props> = ({
  dealId,
  closeModal,
}) => {
  const locale = useContext(Language);
  const [reason, setReason] = useState<Record<string, any>>({
    terms: false,
    myRights: false,
    other: false,
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChooseReason = (key: string) => {
    if (reason[key]) {
      const clearContent = _.mapValues(reason, () => false);
      return clearContent;
    }
    const clearContent = _.mapValues(reason, () => false);
    clearContent[key] = true;
    return clearContent;
  };
  const handleReasonClick = (key: string) => {
    setError(false);
    setReason(handleChooseReason(key));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleReport = (event: any): void => {
    if (!reason.terms && !reason.myRights && !reason.myRights) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    } else {
      let reasonType;
      if (reason.terms) {
        reasonType = 1;
      } else if (reason.myRights) {
        reasonType = 2;
      } else {
        reasonType = 3;
      }
      const { currentUser } = firebase.auth();
      if (currentUser) {
        const reportingId = currentUser.uid;
        const dateReport = Date.now();
        firebase.firestore().collection('reportsDeals').doc()
          .set({
            dealId,
            reportingId,
            reasonType,
            dateReport,
          })
          .then(() => {
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
              closeModal();
            }, 1500);
          })
          .catch((e) => console.log(e));
      }
    }
  };

  const buttonText = success ? switchLanguage(locale, texts).success
    : switchLanguage(locale, texts).button;

  return (
    <ModalImage>
      <Row center>
        <Button
          error={error}
          disabled={error || success}
          onClick={handleReport}
        >
          {error ? switchLanguage(locale, texts).error
            : buttonText }
        </Button>
      </Row>
      <Row
        center
        md
      >
        <Line />
      </Row>
      <DealCommentReportReason
        checked={reason.terms}
        onChange={() => handleReasonClick('terms')}
        title={switchLanguage(locale, texts).terms}
      />
      <DealCommentReportReason
        checked={reason.myRights}
        onChange={() => handleReasonClick('myRights')}
        title={switchLanguage(locale, texts).myRights}
      />
      <DealCommentReportReason
        checked={reason.other}
        onChange={() => handleReasonClick('other')}
        title={switchLanguage(locale, texts).otherReason}
      />
    </ModalImage>
  );
};

export default DealReport;

const ModalImage = styled.div`
    border-radius: 5px;
    padding: 24px;
    @media only screen and (max-width: 400px){
     width: 100%;
    }
    width: 360px;
`;

const Line = styled.div`
    border-top: 1px dashed ${GREY};
    width: 24px;
`;

export const FormIconLinkStyle = styled.span`
    font-size: ${FONT_SIZE_MEDIUM};
    color: ${GREY_MEDIUM};
    letter-spacing: ${LETTER_SPACING_NEG};
  `;


const Button = styled(ButtonEmpty)<{error: boolean}>`
    border-color:${(props) => (props.error ? ERROR_COLOR : GREY_LIGHT)}; 
    color:${(props) => (props.error ? ERROR_COLOR : GREY_MEDIUM)}; 
    :hover{
    color: ${(props) => (props.error ? ERROR_COLOR : ORANGE)};
    border-color: ${(props) => (props.error ? ERROR_COLOR : ORANGE)};
  }
`;
