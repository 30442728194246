import React, { FC } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  GREY, rotate, rotateBack, TEXT_DEALNAME,
} from '../styles';
import Icon from '../styles/Icon';
import * as iconTypes from '../styles/iconTypes';
import NavAppName from '../components/Nav/Public/NavAppName';


const LoadingHome: FC<{loading: boolean}> = ({ loading }) => {
  if (loading) {
    return (
      <LoadingContainer>
        <ContainerBorderOuter>
          <OuterBorder />
        </ContainerBorderOuter>
        <ContainerBorder>
          <InnerBorder />
        </ContainerBorder>
        <Container>
          <Circle>
            <Icon
              height="28"
              width="28"
              iconType={iconTypes.REFRESH}
              fill={GREY}
            />
          </Circle>
        </Container>
        <Deals>
          <NavAppName />
        </Deals>
      </LoadingContainer>
    );
  }
  return null;
};

const mapStateToProps = (state: any) => ({
  loading: state.user.loadingImages,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingHome);

const LoadingContainer = styled.div`
    background-size: 100%;
    position: fixed;
    z-index: 100; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: hidden;
    background-color: white; 
`;

const InnerBorder = styled.div`
  animation: ${rotate} 8s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  border: 1px dashed ${GREY};
  z-index: 10;
`;

const OuterBorder = styled.div`
  animation: ${rotateBack} 4s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border: 1px dashed ${GREY};
`;

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
`;

const ContainerBorder = styled(Container)`
 z-index: 20;
`;

const ContainerBorderOuter = styled(Container)`
 z-index: 30;
`;

const Deals = styled.div`
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translateX(-50%) translateY(-55%);
    ${TEXT_DEALNAME}
    font-size:14;
    color: ${GREY};
    @media only screen and (max-height: 612px) {
      display: none;
    }
`;

const Circle = styled.div`
    display: flex;
    width: 90px;
    height: 90px;
    border-radius: 45px;
    background: white;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 rgba(199, 199, 211, 0.30);
    animation: ${rotate} 2s linear infinite;
`;
