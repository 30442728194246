import React from 'react';
import styled from 'styled-components';
import phone from '../../../../assets/phoneDeal.png';
import { Row, GreyContainer } from '../../../../styles';
import HookFrame from '../IntroStyle/HookFrame';
import HomeCircleGear from '../../UI/HomeCircleGear';
import IntroDealCards from './IntroDealCards';

const IntroDealTablet = () => (
  <GreyContainer>
    <Row
      center
    >
      <Container>
        <Column>
          <div>
            <Row
              center
            >
              <HomeCircleGear
                marginBottom="-18px"
                isAnimOn
              />
            </Row>
            <HookFrame
              src={phone}
            />
          </div>
        </Column>
        <Column>
          <IntroDealCards />
        </Column>
      </Container>
    </Row>
  </GreyContainer>
);

export default IntroDealTablet;


const Container = styled.div`
   width: 100%;
   display: flex;
`;

const Column = styled.div`
  display: flex;
  flex: 6;
  padding: 0 8px;
  justify-content: center;
`;
