import React, { useState } from 'react';
import Icon from '../../../styles/Icon';
import { Col, GREY_MEDIUM, LINK_NO_STYLE } from '../../../styles';

interface Props {
  iconType: string;
  iconTypeHover: string;
  link: string;
}

const DealPublicFooterIcon: React.FunctionComponent<Props> = ({
  iconType,
  iconTypeHover,
  link,
}) => {
  const [isIconHover, onIconHover] = useState(false);
  return (
    <Col
      size={4}
      ver
      center
      cursor
      onMouseEnter={() => onIconHover(true)}
      onMouseLeave={() => onIconHover(false)}
    >
      <a
        style={LINK_NO_STYLE}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon
          height="32"
          width="32"
          iconType={isIconHover ? iconTypeHover : iconType}
          fill={GREY_MEDIUM}
        />
      </a>
    </Col>
  );
};

export default DealPublicFooterIcon;
