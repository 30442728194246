import useWindowScrollPosition from '@rehooks/window-scroll-position';
import useWindowSize from '@rehooks/window-size';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import phone from '../../../../assets/phoneDeal.png';
import phoneSmall from '../../../../assets/phoneDealSmall.png';
import { Col, Row, GREY_DARK } from '../../../../styles';
import Hook from './Hook';

type Props ={
  positionY: number;
}

const PhoneRowMobile: FC <Props> = ({
  positionY,
}) => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  const [isAnimOn, toggleAnim] = useState(false);
  const [isOpacityOn, turnOpacityOff] = useState(true);
  const position = useWindowScrollPosition();
  useEffect(() => {
    if (position.y > positionY) {
      toggleAnim(true);
      setTimeout(() => {
        turnOpacityOff(false);
      }, 1000);
    }
  }, [position, positionY]);
  return (
    <>
      <Row
        center
        style={{ marginBottom: '-64px', marginTop: '58px' }}
      >
        <Rail
          isOpacityOn={isOpacityOn}
        />
      </Row>
      <Row
        center
      >
        <Row
          center
          style={{ width: '768px' }}
        >
          <Col size={1} />
          <Col
            size={10}
            center
          >
            <Hook
              isAnimOn={isAnimOn}
              src={innerWidth < 420 ? phoneSmall : phone}
            />
          </Col>
          <Col size={1} />
        </Row>
      </Row>
    </>
  );
};

export default PhoneRowMobile;


const Rail = styled.div <{ isOpacityOn: boolean }>`
    border: 1px ${GREY_DARK} dashed;   
    transition: width 1.5s linear;
    height:19px;
    width: 55%;
    box-shadow: inset 0 0 4px rgba(101,101,136,0.50);
    margin-right: 45%;
    transition: opacity 1s ease-out;
    opacity: ${(props) => (props.isOpacityOn ? 1 : 0)};
    margin-left: -2px;
`;
