import React from 'react';
import styled from 'styled-components';

import { Col, GreyContainer, Row } from '../../../styles';
import SMFollow from './SMFollow';
import SMSubscribe from './SMSubscribe';

const SMDesktop = () => (
  <BackgroundContainer
    padTop="12px"
  >
    <Row
      bg
      center
    >
      <Container>
        <Col size={1} />
        <Col
          size={5}
          center
        >
          <SMFollow />
        </Col>
        <Col
          size={5}
        >
          <SMSubscribe />
        </Col>
        <Col size={1} />
      </Container>
    </Row>
  </BackgroundContainer>
);

export default SMDesktop;

const Container = styled.div`
   width: 1444px;
   display: flex;
   @media only screen and (max-width: 1200px){
     width: 100%;
  }
`;

const BackgroundContainer = styled(GreyContainer)`
   margin-top: -24px;
`;
