/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import firebase from '../../../../firebase';
import {
  CardAvatarWrapperStyle,
  Col,
  GREY,
  REPORT_MODAL_STYLE,
  Row,
  GREY_DARK,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';
import DealReport from '../../../Deal/DealReport';
import DealTitleText from '../../../Deal/DealTitleText';
import ImageCardAvatar from '../../Image/ImageCardAvatar';
import ImageCardAvatarPlaceholder from '../../Image/ImageCardAvatarPlaceholder';
import { DealId } from '../../../../interfaces/dealTypes';


type IDealCardTitleProps = {
  letter: string;
  title: string;
  titleLight: string;
  description: string;
  committedId: string;
  avatarLoading: boolean;
  onAvatarLoading: Function;
  dealId: DealId;
  committedProfilePhoto: string;
  preview?: boolean;
};

const DealCardTitle: React.FunctionComponent<IDealCardTitleProps> = ({
  letter,
  title,
  titleLight,
  description,
  committedId,
  dealId,
  committedProfilePhoto,
  preview,
}) => {
  const [isModalOpen, openModal] = useState(false);
  const { currentUser } = firebase.auth();
  const userId = currentUser ? currentUser.uid : '';

  const renderModal = () => (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => openModal(false)}
      // @ts-ignore
      style={REPORT_MODAL_STYLE}
    >
      {(userId && userId !== committedId)
        ? (
          <DealReport
            dealId={dealId}
            closeModal={() => openModal(false)}
          />
        )
        : (
          null
        )}
    </Modal>
  );


  const renderAvatar = () => {
    if (committedProfilePhoto) {
      return (
        <AvatarWrapper>
          <ImageCardAvatar
            image={committedProfilePhoto}
          />
        </AvatarWrapper>
      );
    }
    if (!committedProfilePhoto) {
      return (
        <AvatarWrapper>
          <CardAvatarWrapperStyle>
            <Letter>
              {letter}
            </Letter>
          </CardAvatarWrapperStyle>
        </AvatarWrapper>
      );
    }
    return (
      <AvatarWrapper>
        <ImageCardAvatarPlaceholder />
      </AvatarWrapper>
    );
  };


  return (
    <UpperRow>
      {renderModal()}
      <Col
        size={1}
      >
        {renderAvatar()}
      </Col>
      <DealTitleText
        title={title}
        titleLight={titleLight}
        description={description}
      />
      {(userId && userId !== committedId && !preview)
        ? (
          <Col
            center
            ver
            size={1}
            onClick={() => openModal(true)}
            cursor
          >
            <Icon
              height="20"
              width="20"
              iconType={iconTypes.MORE}
              fill={GREY}
              hoverFill={GREY_DARK}
            />
          </Col>
        ) : <Col size={1} />}
      <Col size={1} />
    </UpperRow>
  );
};

export default DealCardTitle;


const UpperRow = styled(Row)`
    padding-top: 12px;
    align-items: center;
    height: 52px;
`;

const Letter = styled.div`
    font-size: 19px;
    color: #FFFFFF;
    font-weight: 400;
`;

const AvatarWrapper = styled.div`
    margin-left: -18px;
    @media only screen and (max-width: 340px){
      margin-left: -24px;
  }
`;
