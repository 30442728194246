import React, { useContext } from 'react';
import styled from 'styled-components';

import robot from '../../../assets/robot.png';
import Language from '../../../contexts/Language';
import switchLanguage from '../../../hoc/switchLanguage';
import { ORANGE, Row, WhiteContainer } from '../../../styles';
import Example from './Example/Example';
import IntroCommentNoAnim from './Comments/IntroCommentNoAnim';
import HomeTitleSection from '../UI/HomeTitleSection';
import { texts } from './Intro';
import IntroDeal from './Deal/IntroDeal';
import Features from './Features/Features';
import Share from './Share/Share';
import IntroVideo from './Video/IntroVideo';

const HomeIntroDesktop = () => {
  const locale = useContext(Language);
  return (
    <>
      <Container>
        <WhiteContainer
          padTop="0px"
        >
          <HomeTitleSection
            title={switchLanguage(locale, texts).title}
            subtitle={switchLanguage(locale, texts).subtitle}
            description={switchLanguage(locale, texts).description}
            id="intro"
            dark
            H2on
          />
          <Row
            center
          >
            <IntroCommentNoAnim
              exampleText="Hello HUMAN, my name is WOOLY. We shall test it with my creator."
              imageUrl={robot}
              rightAvatar
            />
          </Row>
          <Example />
          <IntroVideo />
        </WhiteContainer>
        <IntroDeal />
        <Share />
        <Features />
      </Container>
    </>
  );
};

export default HomeIntroDesktop;

const Container = styled.div`
  background: white;
`;

export const Border = styled.div`
    border-top: 1px dashed ${ORANGE};
    height: 1px; 
    width: 42px;
`;
