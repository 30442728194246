import * as React from 'react';
import styled from 'styled-components';
import {
  GREY, Row, CardAvatarCircleDashStyle, CardAvatarWrapperWhiteStyle, Col,
} from '../../../../styles/index';
import * as iconTypes from '../../../../styles/iconTypes';
import Icon from '../../../../styles/Icon';

interface IDealCardAttachmentFrameProps {
  filename: string;
}

const DealCardAttachmentFrame: React.FunctionComponent<IDealCardAttachmentFrameProps> = ({ filename }) => {
  const fileNameHandle = (length: number) => {
    if (filename === undefined) {
      return '';
    }
    if (filename.length > length) {
      return (`${filename.slice(0, length)}... .pdf`);
    }
    return filename;
  };

  return (
    <RectangleDash>
      <IconRow
        center
      >
        <CardAvatarWrapperWhiteStyle
          shadow
        >
          <CardAvatarCircleDashStyle>
            <Icon
              height="20"
              width="20"
              iconType={iconTypes.PAPERCLIP}
              fill={GREY}
            />
          </CardAvatarCircleDashStyle>
        </CardAvatarWrapperWhiteStyle>
      </IconRow>
      <Row
        ver
      >
        <Col size={1} />
        <Col
          size={10}
          ver
          center
        >
          <ButtonSubText>
            {fileNameHandle(14)}
          </ButtonSubText>
        </Col>
        <Col size={1} />
      </Row>
    </RectangleDash>
  );
};

export default DealCardAttachmentFrame;


const RectangleDash = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 5px; 
    border-width: 1px;
    border-style: dashed; 
    border-color: ${GREY};
`;

const ButtonSubText = styled.span`
  color: ${GREY};
  font-size: 12px;
  padding-top: 12px;
  padding-bottom: 24px;
`;

const IconRow = styled(Row)`
   margin-top: -19px;
`;
