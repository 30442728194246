/* eslint-disable @typescript-eslint/no-non-null-assertion */
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { connect } from 'react-redux';

import DealItemPublic from '../components/Deal/DealItemPublic';
import DealPublicFooter from '../components/Footer/DealPublicFooter/DealPublicFooter';
import DealPublicNav from '../components/Nav/Public/DealPublicNav/DealPublicNav';
import firebase from '../firebase';
import DealNotExists from './DealNotExists';
import Loading from './Loading';


type Props = {
  location: {
    pathname: string;
    state: {
      commentsWillBeVisible?: string;
    };
  };
  userId: any;
}


const DealPublic: FC <Props> = (
  { location, userId },
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deal, setDeal] = useState<any>(null);
  const [comments, setComments] = useState<any>([]);
  const [notExists, setNotExists] = useState<boolean>(false);
  const [committedUsername, setCommittedUsername] = useState<any>('');
  const [committedProfilePhoto, setCommittedProfilePhoto] = useState('');
  const [witnessUsername, setWitnessUsername] = useState('');
  const [witnessProfilePhoto, setWitnessProfilePhoto] = useState('');

  const [commentLimit, setCommentLimit] = useState(5);
  const [fetchingComments, fetchComments] = useState(false);
  const [isFollower, setIsFollower] = useState(false);
  const [noFollow, setNoFollow] = useState(false);

  const getDealMembersData = async (committedId: string, witnessId: string) => {
    const publicDataC = await firebase.firestore().doc(`users/${committedId}/publicData/publicData`)
      .get();
    await setCommittedUsername(publicDataC.data()!.username);
    await setCommittedProfilePhoto(publicDataC.data()!.profilePhoto);

    const publicDataW = await firebase.firestore().doc(`users/${witnessId}/publicData/publicData`)
      .get();
    await setWitnessUsername(publicDataW.data()!.username);
    await setWitnessProfilePhoto(publicDataW.data()!.profilePhoto);
  };

  const getDealId = () => location.pathname.slice(10);

  const dealId = getDealId();


  useEffect(() => {
    const ref = firebase.firestore().collection('deals').doc(dealId);
    let commentsUnsubscribe: any;
    const unsubscribe = ref.onSnapshot((snapshot) => {
      if (snapshot.exists) {
        setNotExists(false);
        setDeal({ ...snapshot!.data() });
        const { committedId, witnessId, followers }: any = snapshot!.data();
        getDealMembersData(committedId, witnessId)
          .then(() => {
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
        if (followers && userId) {
          if (userId !== committedId && userId !== witnessId) {
            setNoFollow(false);
            setIsFollower(followers.includes(userId));
          }
        }
        if (!followers) {
          setIsFollower(false);
        }
        if (userId === committedId || userId === witnessId) {
          setNoFollow(true);
        }
        if (!userId) {
          setNoFollow(false);
          setIsFollower(false);
        }
        if (fetchingComments) {
          commentsUnsubscribe = firebase.firestore()
            .collection('deals')
            .doc(dealId)
            .collection('comments')
            .orderBy('commentDate', 'desc')
            .limit(commentLimit)
            .onSnapshot((querySnapshot) => {
              if (querySnapshot.empty) {
                setComments([]);
              } else {
                const query: any = [];
                querySnapshot.forEach((doc) => {
                  const comment = {
                    key: doc.id,
                    commentId: doc.id,
                    ...doc.data(),
                  };
                  query.push(comment);
                });
                setComments(_.reverse(query));
              }
            });
        }
      } else {
        setIsLoading(false);
        setDeal(null);
        setNotExists(true);
      }
    }, () => {
      setIsLoading(false);
      setDeal(null);
      setNotExists(true);
    });
    return () => {
      unsubscribe();
      // setCommentLimit(20);
      // fetchComments(false);
      if (commentsUnsubscribe) {
        commentsUnsubscribe();
      }
    };
  }, [commentLimit, fetchingComments, dealId, userId, location.pathname]);

  if (isLoading) {
    return (
      <Loading />);
  }
  if (notExists) {
    return (
      <DealNotExists />
    );
  }
  if (deal !== null) {
    // console.log(deal);
    const commentList = _.sortBy(comments, ['commentDate'], ['asc']);
    return (
      <Container>
        <Main>
          <DealPublicNav
            dealId={dealId}
          />
          <DealItemPublic
            {...deal}
            dealId={dealId}
            commentList={commentList}
            committedUsername={committedUsername}
            witnessUsername={witnessUsername}
            committedProfilePhoto={committedProfilePhoto}
            witnessProfilePhoto={witnessProfilePhoto}
            fetchComments={fetchComments}
            setCommentLimit={setCommentLimit}
            commentLimit={commentLimit}
            isFollower={isFollower}
            userId={userId}
            noFollow={noFollow}
          />
        </Main>
        <DealPublicFooter />
      </Container>

    );
  }
  return null;
};

const mapStateToProps = ({ user }: any) => ({
  userId: user.userId,
});


const withDealPublic = withRouter(DealPublic as any);


export default connect(mapStateToProps,
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  {})(withDealPublic);


const Container = styled.div`
  height:100vh;
  display: flex;
  flex-direction: column;  
`;


const Main = styled.main`
  flex: 1 0 auto; 
`;
