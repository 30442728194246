import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ORANGE, GREY_MEDIUM } from '../../../styles';
import Icon from '../../../styles/Icon';


const NavLinkToPublic = ({
  iconType,
  iconTypeHover,
  link,
  text,
}) => {
  const [isIconHover, onIconHover] = useState(false);
  return (
    <Container
      onMouseEnter={() => onIconHover(true)}
      onMouseLeave={() => onIconHover(false)}
    >
      <LinkStyled
        to={link}
      >
        <FormIconWrapper>
          <Icon
            height="20"
            width="20"
            iconType={isIconHover ? iconTypeHover : iconType}
            fill={isIconHover ? ORANGE : GREY_MEDIUM}
          />
        </FormIconWrapper>
        <FormIconLinkStyle
          isIconHover={isIconHover}
        >
          {text}
        </FormIconLinkStyle>
      </LinkStyled>
    </Container>
  );
};

export default NavLinkToPublic;

const Container = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`;

const FormIconLinkStyle = styled.span`
    font-size: 14px;
    color: ${(props) => (props.isIconHover ? ORANGE : GREY_MEDIUM)};
    letter-spacing: -0.5px;
    cursor: pointer;
  `;

const FormIconWrapper = styled.span`
    margin-right: 10px;
    margin-top: 2px;
  `;

const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
`;
