import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';
import { GREY, Col } from '../../../../styles/index';
import { Ideal } from '../../../../interfaces/dealTypes';


type Props = Pick<Ideal, 'attachment'>

const DealCardAttachmentButtons: FC <Props> = ({
  attachment,
}) => (
  <>
    <Col
      size={2}
      jEnd
    >
      <LeftColumn>
        <ButtonContainer
          href={attachment ? attachment.attachmentUri : ''}
        >
          <Icon
            height="20"
            width="20"
            iconType={iconTypes.DOWNLOAD}
            fill={GREY}
          />
        </ButtonContainer>
        <Border />
        <ButtonContainer />
      </LeftColumn>
    </Col>
  </>
);

export default DealCardAttachmentButtons;


const LeftColumn = styled.div`
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    display: flex;
`;

const ButtonContainer = styled.a`
    justify-content: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex:1;
    display: flex;
    cursor:pointer;
`;

const Border = styled.div`
    border-top: dashed 1px ${GREY};
    margin-top: 8px;
    margin-bottom: 8px;
    width: 20px;
`;
