import React from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';
import {
  WHITE_DARK, GREY_DARK, SHADOW_GREY, ORANGE,
} from '../../styles';

const Cookies = () => (
  <StyledCookie
    location="bottom"
    buttonText="I accept it"
    cookieName="myAwesomeCookieName2"
    style={{
      background: WHITE_DARK,
      fontSize: '12px',
      color: GREY_DARK,
      borderTop:
        `dashed 1px ${GREY_DARK}`,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      boxShadow: `0 0 40px 0 ${SHADOW_GREY}`,
      opacity: '0.9',
    }}
    buttonStyle={{
      background: ORANGE, fontSize: '13px', color: 'white', borderRadius: '4px',
    }}
    expires={150}
  >
    This website uses cookies to enhance the user experience.
  </StyledCookie>
);


export default Cookies;

const StyledCookie = styled(CookieConsent)`
  background: ${WHITE_DARK};
  font-size: 12px;
`;
