import React, { FC } from 'react';
import styled from 'styled-components';

import {
  CardTitleDescriptionTextStyle,
  CardTitleLightTextStyle,
  CardTitleTextStyle,
  Col,
  GREY,
  GREY_DARK,
  Row,
  SHADOW_GREY_PHONE_ICON,
  shadowNavAvatar,
  H2,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';


type Props ={
  title: string;
  titleLight: string;
  description: string;
  iconType: string;
  grey?: boolean;
  titleTagComponent?: any;
}

const IntroCardMobile: FC<Props> = ({
  title,
  titleLight,
  description,
  iconType,
  grey,
  titleTagComponent = <H2 />,
}) => (
  <UpperRow
    sm
    ver
  >
    <Col
      size={1}
    >
      <Circle
        grey={grey}
      >
        <IconWrapper>
          <Icon
            height="18"
            width="18"
            iconType={iconType}
            fill={GREY_DARK}
          />
        </IconWrapper>
      </Circle>
    </Col>
    <ContainerTitle
      size={11}
      center
    >
      <UpperRowTitle
        component={titleTagComponent}
      >
        <Title>
          {title}
        </Title>
        <TitleLight>
          {titleLight}
        </TitleLight>
      </UpperRowTitle>
      <Description>
        {description}
      </Description>
    </ContainerTitle>
  </UpperRow>

);


export default IntroCardMobile;


const ContainerTitle = styled(Col)`
  flex-direction: column;
  padding-left: 12px;
`;

const UpperRowTitle = styled(
  ({ component, ...props }) => React.cloneElement(component, props),
)`
display: flex;
`;

const Title = styled(CardTitleTextStyle)`
  color: ${GREY_DARK};
`;
const TitleLight = styled(CardTitleLightTextStyle)`
  color: ${GREY_DARK};
`;

const Description = styled(CardTitleDescriptionTextStyle)`
 color:${GREY};
`;

const UpperRow = styled(Row)`
    padding-top: 4px;
    /* height: 72px; */
    /* border-left: 1px dashed white; */
`;


const Circle = styled.div<{grey?: boolean}>`
    display: flex;
    width: 38px;
    height: 38px;
    border-radius: 19px;
    justify-content: center;
    align-items: center;
    background: white
    ${shadowNavAvatar(SHADOW_GREY_PHONE_ICON)}
    /* margin-left:-19px; */
    z-index:2;
`;


const IconWrapper = styled.div`
    width: 34px;
    height: 34px;
    border-radius: 17px;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: dashed; 
    border-color: ${GREY_DARK};
    display: flex;
`;
