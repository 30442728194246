import { keyframes } from 'styled-components';

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const rotateBack = keyframes`
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
`;


export const moveRight = keyframes`
  from {
    transform: translate(0px);
  }

  to {
    transform: translate(100px);
  }
`;
