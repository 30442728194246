import React, {
  FC, memo, useContext, useEffect, useState,
} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import firebase from 'firebase';

import Language from '../../contexts/Language';
import AnimOpacity from '../../hoc/AnimOpacity';
import switchLanguage from '../../hoc/switchLanguage';
import useKeyPress from '../../hooks/useKeyPress';
import { CommittedId } from '../../interfaces/dealTypes';
import {
  Col,
  DIST_MEDIUM,
  GREY_LIGHT,
  GREY_MEDIUM,
  LINK_NO_STYLE,
  Row,
  TEXT_CARD_USER_DECISION,
} from '../../styles';
import DealItemComment from './DealComment';
import DealTextArea from './DealTextArea';


const texts = {
  en: {
    message: 'Write message...',
    send: 'send',
    logIn: 'log in to comment',
    older: 'more ...',
  },
};

type IDealItemCommentListProps = {
  areCommentsVisible: boolean;
  dealId: string;
  commentList: {
    commentDate: string;
    commentText: string;
    userId: string;
    commentId: string;
  }[];
  userId: string;
  username: string;
  committedId: CommittedId;
  witnessId: string;
  witnessProfilePhoto: string;
  committedProfilePhoto: string;
  committedUsername: string;
  witnessUsername: string;
  fetchComments: any;
  setCommentLimit: any;
  commentLimit: number;
}

const DealItemCommentList: FC <IDealItemCommentListProps> = ({
  areCommentsVisible,
  dealId,
  commentList,
  userId,
  username,
  // eslint-disable-next-line no-shadow
  committedId,
  witnessId,
  witnessProfilePhoto,
  committedProfilePhoto,
  committedUsername,
  witnessUsername,
  fetchComments,
  commentLimit,
  setCommentLimit,
}) => {
  const [commentText, setCommentText] = useState('');
  const locale = useContext(Language);
  const enterPress = useKeyPress('Enter');
  useEffect(() => {
    if (enterPress && commentText !== '' && userId) {
      firebase.firestore()
        .collection('deals')
        .doc(dealId)
        .collection('comments')
        .doc()
        .set({
          commentText,
          dealId,
          userId,
          commentDate: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          setCommentText('');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [commentText, dealId, enterPress, userId, username]);

  useEffect(() => {
    fetchComments(areCommentsVisible);
    return () => {
      fetchComments(false);
    };
  }, [areCommentsVisible, fetchComments]);

  const onCommentCreate = (e: React.MouseEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (commentText !== '') {
      firebase.firestore()
        .collection('deals')
        .doc(dealId)
        .collection('comments')
        .doc()
        .set({
          commentText,
          dealId,
          userId,
          commentDate: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          setCommentText('');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const renderListItems = () => {
    if (commentList !== undefined) {
      const list = commentList.map((comment, index) => {
        if (index % 2) {
          return (
            <DealItemComment
              {...comment}
              leftComment
              key={comment.commentDate}
              dealId={dealId}
              committedId={committedId}
              witnessId={witnessId}
              committedUsername={committedUsername}
              witnessUsername={witnessUsername}
              committedProfilePhoto={committedProfilePhoto}
              witnessProfilePhoto={witnessProfilePhoto}
            />
          );
        }
        return (
          <DealItemComment
            {...comment}
            key={comment.commentDate}
            dealId={dealId}
            committedId={committedId}
            witnessId={witnessId}
            committedUsername={committedUsername}
            witnessUsername={witnessUsername}
            committedProfilePhoto={committedProfilePhoto}
            witnessProfilePhoto={witnessProfilePhoto}
          />
        );
      });
      return list;
    } return null;
  };

  const renderInput = () => {
    if (userId) {
      return (
        <>
          <form>
            <Row>
              <Col size={1} />
              <Col size={10}>
                <DealTextArea
                  setValue={(e: string) => setCommentText(e)}
                  value={commentText}
                />
              </Col>
              <Col size={1} />
            </Row>
            <Row
              center
              cursor
              onClick={onCommentCreate}
            >
              <UserDecisionText>
                {switchLanguage(locale, texts).send}
              </UserDecisionText>
            </Row>
          </form>
        </>
      );
    }
    return (
      <Row
        center
        cursor
      >
        <Link
          to={{
            pathname: '/logIn',
            state: { dealId },
          }}
          style={LINK_NO_STYLE}
        >
          <UserDecisionText
            center
          >
            {switchLanguage(locale, texts).logIn}
          </UserDecisionText>
        </Link>
      </Row>
    );
  };

  return (
    <AnimOpacity
      animOn={areCommentsVisible}
    >
      <Row md />
      {commentList.length >= commentLimit
        ? (
          <Row
            center
            cursor
            onClick={() => setCommentLimit(commentLimit + 5)}
          >
            <MoreText>
              {switchLanguage(locale, texts).older}
            </MoreText>
          </Row>
        ) : null}
      <>
        {renderListItems()}
      </>
      {renderInput()}
    </AnimOpacity>
  );
};


const mapStateToProps = ({ user }: any) => {
  const { userId, username, success } = user;
  return ({
    userId,
    username,
    success,
  });
};

export default memo(connect(mapStateToProps,
  {})(DealItemCommentList));


const UserDecisionText = styled(Col)`
    text-align: center;
    color: ${GREY_LIGHT};
    ${TEXT_CARD_USER_DECISION}
    :hover{
    color:${GREY_MEDIUM} ;
    }
    padding: ${DIST_MEDIUM} 0;
    justify-content: center;
`;

const MoreText = styled(Col)`
    text-align: center;
    color: ${GREY_LIGHT};
    ${TEXT_CARD_USER_DECISION}
    :hover{
    color:${GREY_MEDIUM} ;
    }
    padding-bottom:${DIST_MEDIUM};
    justify-content: center;
`;
