import styled from 'styled-components';
import { GREY_DARK, GREY_MEDIUM, GREY } from './index';

export const FONT_EXAMPLE = "'futura-pt',sans-serif";
export const FONT_EXAMPLE_MOBILE = "'futura-pt',sans-serif";


export const FONT_FUTURA = "'futura-pt',sans-serif";
export const FONT_FUTURA_BOLD = "'futura-pt-bold',sans-serif";

export const FONT_HEAVY = '600';
export const FONT_BOLD = '700';


export const FONT_SIZE_X_SMALL = '10px';
export const FONT_SIZE_SMALL = '12px';
export const FONT_SIZE_MEDIUM = '14px';
export const FONT_SIZE_BIGGER = '16px';
export const FONT_SIZE_BIG = '18px';
export const FONT_SIZE_LARGE = '20px';

export const LETTER_SPACING_X_NEG = '-1.5px';
export const LETTER_SPACING_NEG = '-0.5px';
export const LETTER_SPACING_X_POS = '2px';

// logo nav

export const TEXT_LOGO = `
font-size: 24px;
letter-spacing: -1.5px;
font-weight: ${FONT_BOLD};
font-family: ${FONT_FUTURA};
`;

// links in privae nav

export const TEXT_TITLE_SMALL = `
${FONT_HEAVY};
font-size: ${FONT_SIZE_MEDIUM};
letter-spacing: ${LETTER_SPACING_NEG};
line-height: 18px;
`;

// ???? OUT ????

export const TEXT_TITLE_LIGHT = `
font-size: ${FONT_SIZE_MEDIUM};
letter-spacing: ${LETTER_SPACING_NEG};
line-height: 18px;
`;

export const TEXT_DEALNAME = `
font-size: ${FONT_SIZE_BIGGER};
letter-spacing: ${LETTER_SPACING_NEG};
line-height: 20px;
`;

export const TEXT_CARD_USER_DECISION = `
  font-size: ${FONT_SIZE_SMALL};
  letter-spacing: ${LETTER_SPACING_X_POS};
  text-transform: uppercase;
`;

export const TEXT_TITLE_INFOCARD = `
font-size: ${FONT_SIZE_MEDIUM};
letter-spacing: ${LETTER_SPACING_NEG};
line-height: 18px;
`;

export const TEXT_CARD_SMALL = `
font-size: ${FONT_SIZE_SMALL};
letter-spacing: ${LETTER_SPACING_NEG};
`;


export const TEXT_CARD_TITLE = `
font-size: ${FONT_SIZE_BIGGER};
letter-spacing: ${LETTER_SPACING_NEG};
font-weight: ${FONT_HEAVY}
line-height: 20px;
`;

export const TEXT_CARD_TITLE_LIGHT = `
font-size: ${FONT_SIZE_BIGGER};
letter-spacing: ${LETTER_SPACING_NEG};
line-height: 20px;
`;


// eg GO BACK

export const ScreenLinkTextStyle = styled.div`
    color: ${GREY_MEDIUM};
    letter-spacing: ${LETTER_SPACING_X_POS};
    text-align: center;
    text-transform: uppercase;
    font-size: ${FONT_SIZE_SMALL};
    :hover {
    color: ${GREY_DARK};
    }
`;

// ? margin

export const CardTitleTextStyle = styled.span`
    color: ${GREY_DARK};
    margin-right: 4px;
    ${TEXT_CARD_TITLE};
`;

export const CardTitleLightTextStyle = styled.span`
    color: ${GREY_DARK};
    ${TEXT_CARD_TITLE_LIGHT};
    font-weight: 400;
`;

export const CardTitleDescriptionTextStyle = styled.p`
    margin-top: 2px;
    color: ${GREY};
    ${TEXT_CARD_SMALL}
`;


export const H1 = styled.h1`
`;
export const H2 = styled.h2`
`;
export const H3 = styled.h3`
`;
export const H4 = styled.h4`
`;
