import React from 'react';
import Img from 'react-image';
import styled from 'styled-components';
import { CARD_CIRCLE_SIZE, SHADOW_GREY_DARK } from '../../../styles';
import NavAvatarPlaceholder from '../../Nav/Public/NavAvatarPlaceholder';

const ImageNavAvatar = ({
  image,
}) => (
  <>
    <Image
      src={image}
      loader={
        <NavAvatarPlaceholder />
      }
    />
  </>
);
export default ImageNavAvatar;


export const Image = styled(Img)`
    ${CARD_CIRCLE_SIZE}
    box-shadow: 0 0px 20px 0 ${SHADOW_GREY_DARK};
    object-fit: cover;
`;
