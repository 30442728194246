import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Icon from '../../../styles/Icon';
import * as iconTypes from '../../../styles/iconTypes';


type Props = {
  fill: string; bottom?: boolean; children: ReactNode;
}

const Arrow: FC <Props> = ({
  fill,
  bottom,
  children,
}) => (
  <ArrowColumn
    bottom={bottom}
  >
    <Icon
      height="16"
      width="16"
      iconType={iconTypes.ARROW_DOWN_DECOR}
      fill={fill}
    />
    <ArrowPadding>
      {children}
    </ArrowPadding>
  </ArrowColumn>
);

export default Arrow;


export const ArrowColumn = styled.div<{bottom?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.bottom ? 'flex-start' : 'flex-end')};
  height: 90px;
  position: relative;
  overflow: hidden;
`;

export const ArrowPadding = styled.div`
  width: 84px;
  display: flex;
  justify-content: center;
`;
