import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import firebase from '../../../firebase';
import {
  CardAvatarWrapperStyle, CARD_CIRCLE_SIZE, shadowNavAvatar, SHADOW_GREY_DARK,
} from '../../../styles/index';
import ImageNavAvatar from '../../UI/Image/ImageNavAvatar';
import NavAvatarPlaceholder from './NavAvatarPlaceholder';

type Props = {
  userId: string;
}

const NavAvatar: React.FunctionComponent<Props> = ({
  userId,
}) => {
  const [letter, setLetter] = useState<string>('');
  const [usernameC, setUsername] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string|null>(null);
  const [isImageUrlFetched, fetchingImage] = useState(false);


  useEffect(() => {
    const unsubscribe = firebase.firestore().doc(`users/${userId}/publicData/publicData`).onSnapshot(
      (doc) => {
        if (doc.exists) {
          const { profilePhoto, username }: any = doc.data();
          setUsername(username);
          if (profilePhoto) {
            setImageUrl(profilePhoto);
            fetchingImage(true);
          } else {
            fetchingImage(true);
          }
        } else {
          setUsername('?');
          fetchingImage(true);
        }
      },
    );
    return () => {
      unsubscribe();
    };
  }, [userId]);

  useEffect(() => {
    if (usernameC) {
      setLetter(usernameC.charAt(0));
    }
  }, [usernameC]);

  if (isImageUrlFetched && imageUrl && userId) {
    return (
      <ImageNavAvatar
        image={imageUrl}
      />
    );
  }
  if (isImageUrlFetched && imageUrl === null && userId) {
    return (
      <AvatarWrapperBlue>
        <Letter>
          {letter}
        </Letter>
      </AvatarWrapperBlue>
    );
  }
  return (
    <NavAvatarPlaceholder />
  );
};


const mapStateToProps = ({ user }: any) => {
  const { userId, username } = user;
  return ({
    userId,
    username,
  });
};

export default connect(mapStateToProps,
  {})(NavAvatar);


const Letter = styled.div`
    font-size: 19px;
    color: #FFFFFF;
    font-weight: 400;
`;

const AvatarWrapperBlue = styled(CardAvatarWrapperStyle)`
    ${shadowNavAvatar(SHADOW_GREY_DARK)}
`;

export const CardProfileImage = styled.img`
    ${CARD_CIRCLE_SIZE}
`;
