import React, { FC } from 'react';
import styled from 'styled-components';

import {
  Col, GREY, Row, TEXT_CARD_SMALL,
} from '../../../styles';

const MediaTitle: FC<{
  title: string;
}> = ({ title }) => {
  if (title) {
    return (
      <Row sm>
        <Col
          size={1}
          center
        />
        <Col
          center
          ver
          size={10}
        >
          <PictureText>{title}</PictureText>
        </Col>
        <Col size={1} />
      </Row>
    );
  }
  return <Row sm />;
};

export default MediaTitle;

const PictureText = styled.div`
    text-align: center;
    ${TEXT_CARD_SMALL}
    color:${GREY}
`;
