import React, { FC } from 'react';

import { Row, ThinContainer } from '../../../../../styles';

const AuthorContainer: FC = ({
  children,
}) => (
  <Row center>
    <ThinContainer>
      <Row center>
        <ThinContainer>
          {children}
        </ThinContainer>
      </Row>
    </ThinContainer>
  </Row>
);


export default AuthorContainer;
