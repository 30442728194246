/* eslint-disable @typescript-eslint/no-unused-vars */
import firebase from 'firebase';
import React, { memo } from 'react';
import { Row } from 'react-flexbox-grid';
import styled from 'styled-components';

import { DealId } from '../../interfaces/dealTypes';
import { Col, DIST_MEDIUM } from '../../styles';
import * as iconTypes from '../../styles/iconTypes';
import DealMenuBorder from '../UI/Cards/DealCard/DealMenuBorder';
import DealMenuButton from '../UI/Cards/DealCard/DealMenuButton';
import DealMenuButtonColor from '../UI/Cards/DealCard/DealMenuButtonColor';
import DealItemMenuBottomComments
  from '../UI/Cards/DealCard/DealMenuButtonComments';


interface IdealItemMenuBottom {
  onBottomMenuClick: Function;
  areCommentsVisible: boolean;
  isShareVisible: boolean;
  numberOfComments: number;
  areDatesVisible: boolean;
  isMinimizeOn: boolean;
  onMinimizeTextOn: (event: React.MouseEvent<HTMLInputElement>) => void;
  isFollower: boolean;
  userId: string;
  noFollow: boolean;
  dealId: DealId;
  followers: any;
}

const DealItemMenuBottom = (props: IdealItemMenuBottom) => {
  const {
    onBottomMenuClick,
    areCommentsVisible,
    numberOfComments,
    isShareVisible,
    areDatesVisible,
    isMinimizeOn,
    onMinimizeTextOn,
    isFollower,
    noFollow,
    dealId,
    userId,
    followers,
  } = props;
  const onFollowPress = () => {
    if (isFollower) {
      firebase.firestore()
        .collection('deals')
        .doc(dealId).update({
          followers: firebase.firestore.FieldValue.arrayRemove(userId),
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      firebase.firestore()
        .collection('deals')
        .doc(dealId).update({
          followers: firebase.firestore.FieldValue.arrayUnion(userId),
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <MenuRow>
      <Col size={1} />
      <DealItemMenuBottomComments
        onClick={(event: React.MouseEvent<HTMLInputElement>) => onBottomMenuClick('areCommentsVisible')}
        isVisible={areCommentsVisible}
        numberOfComments={numberOfComments}
      />
      <DealMenuBorder />
      <DealMenuButton
        onClick={(event: React.MouseEvent<HTMLInputElement>) => onBottomMenuClick('isShareVisible')}
        isVisible={isShareVisible}
        iconType={iconTypes.SHARE}
      />
      <DealMenuBorder />
      <DealMenuButton
        onClick={(event: React.MouseEvent<HTMLInputElement>) => onBottomMenuClick('areDatesVisible')}
        isVisible={areDatesVisible}
        iconType={iconTypes.CALENDAR}
      />
      <DealMenuBorder />
      {noFollow
        ? (
          <DealMenuButton
            onClick={onMinimizeTextOn}
            isVisible={isMinimizeOn}
            iconType={iconTypes.MAXIMIZE}
          />
        ) : (
          <DealMenuButtonColor
            onClick={onFollowPress}
            isVisible={isFollower}
            iconType={isFollower ? iconTypes.STAR : iconTypes.STAR}
            dealId={dealId}
            userId={userId}
          />
        )}
      <Col size={1} />
    </MenuRow>
  );
};

export default memo(DealItemMenuBottom);

const MenuRow = styled(Row)`
  margin-top: ${DIST_MEDIUM};
  padding-bottom: ${DIST_MEDIUM};
  /* padding-right: 18px;  ???*/
`;
