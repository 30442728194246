import React from 'react';
import styled from 'styled-components';
import Img from 'react-image';
import Icon from '../../../styles/Icon';
import * as iconTypes from '../../../styles/iconTypes';
import { GREY, GREY_LIGHT } from '../../../styles/index';

const ImageCard = ({
  image,
  modalDisabled,
}) => (
  <>
    <Photo
      src={image}
      modalDisabled={modalDisabled}
      loader={(
        <ImagePlaceholder>
          <IconWrapper>
            <Icon
              height="20"
              width="20"
              iconType={iconTypes.PHOTO}
              fill={GREY}
            />
          </IconWrapper>
        </ImagePlaceholder>
      )}
      unloader={(
        <ImagePlaceholder>
          <IconWrapper>
            <Icon
              height="20"
              width="20"
              iconType={iconTypes.PHOTO}
              fill={GREY}
            />
          </IconWrapper>
        </ImagePlaceholder>
      )}
    />
  </>
);
export default ImageCard;


const Photo = styled(Img)`
    border-radius: 2px;
    width: 100%;
    height: 100%;
    border-radius: 5; 
    cursor: ${(props) => (props.modalDisabled ? null : 'pointer')};
    object-fit: cover;
`;


const ImagePlaceholder = styled.div`
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: dashed; 
    border-color: ${GREY_LIGHT};
    border-radius: 5; 
    height: 100%;
    width: 100%;
    display: flex;
`;

const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: dashed; 
    border-color: ${GREY_LIGHT};
    display: flex;
`;
