import useWindowSize from '@rehooks/window-size';
import _ from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Assign } from 'utility-types';

import switchLanguage from '../../../../hoc/switchLanguage';
import { Ideal } from '../../../../interfaces/dealTypes';
import { Col, GREY_DARK, Row } from '../../../../styles';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';
import {
  CircleCol, Container, TextDark, TextLight, Wrapper,
} from './DealCardPrize';

const texts = {
  en: {
    witnessWill: 'will be a supervisor',
    witness: 'is a supervisor',
    are: 'are a supervisor',
  },
};


type IdealCardWitnessProps = Assign<{
  witnessProfilePhoto: string;
  are?: boolean;
  preview?: boolean;
}, Pick<Ideal, 'witnessUsername'>>

export type AssignTypes
<PropsTypes extends Record<string, any>,
  ImportedTypes,
  ImportedTypesKeys extends keyof ImportedTypes> =
  Assign<PropsTypes, Pick<ImportedTypes, ImportedTypesKeys>>

const DealCardWitness = ({
  witnessUsername,
  witnessProfilePhoto,
  are,
  preview,
}: IdealCardWitnessProps) => {
  const [isWitnessUsernameMaximized, maximizeWitnessUsername] = useState<boolean>(false);

  const windowSize = useWindowSize();

  const { innerWidth } = windowSize;

  const handleWitnessUsernameLength = () => {
    if (witnessUsername === undefined) {
      return '';
    }
    if (innerWidth < 400 && witnessUsername.length > 21) {
      if (!isWitnessUsernameMaximized) {
        return (`${witnessUsername.slice(0, 21)}...`);
      }
    }
    return witnessUsername;
  };


  const renderAvatar = () => {
    if (preview) {
      return (
        <CircleCol>
          <Icon
            height="14"
            width="14"
            iconType={iconTypes.USER}
            fill={GREY_DARK}
          />
        </CircleCol>
      );
    }
    if (!_.isNull(witnessProfilePhoto)) {
      return (
        <WitnessAvatarWrapper>
          <WitnessAvatar
            src={witnessProfilePhoto}
            alt=""
          />
        </WitnessAvatarWrapper>
      );
    }
    return (
      <CircleCol>
        <Icon
          height="14"
          width="14"
          iconType={iconTypes.USER}
          fill={GREY_DARK}
        />
      </CircleCol>
    );
  };

  return (
    <Row
      md
    >
      <Col size={1} />
      <Col size={1}>
        <Wrapper>
          {renderAvatar()}
        </Wrapper>
      </Col>
      <Col
        size={9}
        onClick={() => maximizeWitnessUsername(!isWitnessUsernameMaximized)}
      >
        <Container>
          <TextDark>{handleWitnessUsernameLength()}</TextDark>
          <TextLight>
            {are
              ? switchLanguage(null, texts).are
              : switchLanguage(null, texts).witness}
          </TextLight>
        </Container>
      </Col>
      <Col size={1} />
    </Row>
  );
};

export default DealCardWitness;


const WitnessAvatar = styled.img`
    border-radius: 10px;
    width:  20px;
    height: 20px;
    object-fit: cover;
`;

const WitnessAvatarWrapper = styled.div`
  border-radius: 10px;
  width:  20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
