import React, { memo } from 'react';
import styled from 'styled-components';

import {
  CARD_CIRCLE_SIZE,
  Col,
  FONT_EXAMPLE,
  FONT_EXAMPLE_MOBILE,
  FONT_SIZE_MEDIUM,
  GREY_MEDIUM,
  LETTER_SPACING_NEG,
  ORANGE,
  Row,
} from '../../../../styles';
import ImageRobot from '../../../UI/Image/ImageRobot';


interface Props {
  commentText: string;
  topMargin?: boolean;
}

const IntroCommentTop: React.FunctionComponent<Props> = ({
  commentText,
  topMargin = false,
}) => (
  <>
    <Row
      center
      md={topMargin}
    >
      <ImageRobot />
    </Row>
    <Row center>
      <MessageTriangleLeft />
    </Row>
    <Row center>
      <Message>
        <Col
          ver
        >
          {commentText}
        </Col>
      </Message>
    </Row>
  </>
);

export default memo(IntroCommentTop);


const MessageTriangleLeft = styled.div`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent ${ORANGE} transparent;
`;

const Message = styled(Col)<{leftComment?: boolean; animTwo?: boolean}>`
  font-family: ${FONT_EXAMPLE};
  background: ${ORANGE};
  border-radius: 5px;
  padding: 16px;
  color: #FFFFFF;
  line-height: 20px;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: -0.4px;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  @media only screen and (max-width: 426px){
    font-family:${FONT_EXAMPLE_MOBILE};
    font-size: 12px;
  }
`;

export const CardProfileImage = styled.img`
    ${CARD_CIRCLE_SIZE}
`;

export const FormIconLinkStyle = styled.span`
    font-size: ${FONT_SIZE_MEDIUM};
    color: ${GREY_MEDIUM};
    letter-spacing: ${LETTER_SPACING_NEG};
  `;
