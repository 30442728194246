export const DIST_X_X_X_SMALL = '4px';
export const DIST_X_X_SMALL = '6px';
export const DIST_X_SMALL = '8px';
export const DIST_SMALL = '12px';
export const DIST_MEDIUM = '24px';
export const DIST_BIGGER = '32px';
export const DIST_BIG = '48px';
export const DIST_LARGE = '58px';

export const CARD_CIRCLE_MARGIN_NEGATIVE = 'margin-left: -18px';
export const CARD_CIRCLE_SIZE = 'border-radius: 19px; width: 38px; height: 38px;';
export const CARD_CIRCLE_INNER_LINE = 'border-radius: 17px; width: 34px; height: 34px;';
export const CARD_DESKTOP_WIDTH = 'width: 360px';
export const ROBOT_SIZE = 'width: 92px; height: 92px;';
