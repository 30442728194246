/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import { connect } from 'react-redux';
import Language from '../../../../contexts/Language';
import switchLanguage from '../../../../hoc/switchLanguage';
import { Col, Row, ButtonEmpty } from '../../../../styles';
import * as iconTypes from '../../../../styles/iconTypes';
import DealMenuBorder from '../../../UI/Cards/DealCard/DealMenuBorder';
import NavAppName from '../NavAppName';
import NavAvatar from '../NavAvatar';
import NavLinkLocal from '../NavLinkLocal';
import NavLinkToPublic from '../NavLinkToPublic';
import firebase from '../../../../firebase';

export const texts = {
  en: {
    logIn: 'Login',
    logout: 'Logout',
    premium: 'Premium',
    privacy: 'Privacy',
    features: 'Features',
  },
};

type Props = {
  userId: string;
  onSignOut: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const HomeNavDesktop: React.FunctionComponent<Props> = ({ onSignOut, userId }) => {
  const locale = useContext(Language);

  // TODO
  const [loading, setloading] = useState(true);
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('deals')
      .where('witnessId', '==', userId)
      .where('invPending', '==', false)
      .where('active', '==', true)
      .orderBy('dateCreated', 'desc')
      .limit(5)
      .onSnapshot(async (querySnapshot) => {
        if (querySnapshot.empty) {
          setloading(false);
          setDeals([]);
        } else {
          // @ts-ignore
          const query = [];
          querySnapshot.forEach((doc) => {
            const deal = {
              key: doc.id,
              dealId: doc.id,
              ...doc.data(),
            };
            query.push(deal);
          });
          setloading(false);
          // @ts-ignore
          setDeals(query);
        }
      }, (err) => {
        setloading(false);
        console.log(err);
      });

    return () => {
      // setloading(true);
      // setDeals([]);
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userId]);

  return (
    <Row
      md
      center
    >
      <Container>
        <Row>
          <Col size={1} />
          <Col
            size={2}
            ver
          >
            <NavAppName />
          </Col>
          <Col
            size={1}
            ver
          >
            <NavLinkLocal
              iconType={iconTypes.GEAR}
              iconTypeHover={iconTypes.GEAR_SOLID}
              link="#intro"
              text={switchLanguage(locale, texts).features}
              offset="144"
            />
          </Col>
          <Col
            size={1}
            ver
          >
            <NavLinkLocal
              iconType={iconTypes.DIAMOND}
              iconTypeHover={iconTypes.DIAMOND_SOLID}
              link="#premium"
              text={switchLanguage(locale, texts).premium}
            />
          </Col>
          <Col
            size={1}
            ver
          >
            <NavLinkToPublic
              iconType={iconTypes.LOCK}
              iconTypeHover={iconTypes.LOCK_SOLID}
              link="/privacy"
              text={switchLanguage(locale, texts).privacy}
            />
          </Col>
          <Col
            size={2}
            ver
          >
            <NavLinkToPublic
              iconType={iconTypes.BULB}
              iconTypeHover={iconTypes.BULB}
              link="/ideas"
              text="Challenge Contract Ideas"
            />
          </Col>
          <Col
            size={1}
            center
            ver
          >
            <DealMenuBorder />
          </Col>
          <Col
            size={1}
            ver
            center
          >
            {userId
              ? (
                <NavAvatar />
              ) : null}
          </Col>
          <Col
            size={!userId ? 1 : 3}
            jEnd={!userId}
          >
            {userId
              ? (
                <ButtonEmpty
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  onClick={onSignOut}
                >
                  {switchLanguage(locale, texts).logout}
                </ButtonEmpty>
              ) : <NavAvatar />}
          </Col>
        </Row>
      </Container>
    </Row>
  );
};
const mapStateToProps = (state: any) => ({
  userId: state.user.userId,
});


export default connect(mapStateToProps,
  {
  })(HomeNavDesktop);


const Container = styled.div`
    width: 1224px;
  `;
