import React, { FC } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import DealPublic from '../screens/DealPublic';
import Home from '../screens/Home';
import LogIn from '../screens/LogIn';
import Privacy from '../screens/Privacy';
import Terms from '../screens/Terms';
import Ideas from '../screens/Ideas';
import Idea from '../screens/Idea';

type Props ={
  userAuth?: boolean;
}

export const AppRouter: FC<Props> = ({ userAuth }) => {
  const renderRoot = () => (
    <Route
      path="/"
      exact
      render={() => (
        <Home />
      )}
    />
  );

  const renderLogIn = () => (
    <Route
      path="/logIn"
      exact
      render={
        (props) => {
          if (userAuth) {
            return (
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            );
          }
          return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
            <LogIn
              {...props}
            />
          );
        }
      }
    />
  );

  const renderPrivacy = () => (
    <Route
      path="/privacy"
      exact
      render={
        () => (
          <Privacy />
        )
      }
    />
  );

  const renderTerms = () => (
    <Route
      path="/terms"
      exact
      render={
        () => (
          <Terms />
        )
      }
    />
  );

  const renderDealIdeas = () => (
    <Route
      path="/ideas"
      exact
      render={
        () => (
          <Ideas />
        )
      }
    />
  );


  return (
    <Router>
      <>
        {renderRoot()}
        {renderLogIn()}
        {renderPrivacy()}
        {renderTerms()}
        {renderDealIdeas()}
        <Route
          path="/deal/:id"
          component={DealPublic}
        />
        <Route
          path="/contracts/:id"
          component={DealPublic}
        />
        <Route
          path="/ideas/:id"
          component={Idea}
        />
      </>
    </Router>
  );
};

const mapStateToProps = (state: any) => ({
  userAuth: state.user.userAuth,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
