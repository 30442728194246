import useWindowSize from '@rehooks/window-size';
import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import NavAppName from '../components/Nav/Public/NavAppName';
import InfoCardDashed from '../components/UI/Cards/InfoCardDashed';
import Language from '../contexts/Language';
import switchLanguage from '../hoc/switchLanguage';
import {
  Col,
  GREY,
  GREY_DARK,
  LINK_NO_STYLE,
  Row,
  SHADOW_GREY,
  TEXT_DEALNAME,
} from '../styles';
import Icon from '../styles/Icon';
import * as iconTypes from '../styles/iconTypes';

export const texts = {
  en: {
    title: "Challenge wasn't found",
    titleIdea: "Challenge Contract wasn't found",
    description: "Challenge doesn't exist or isn't public anymore. Check your link.",
    descriptionIdea: "Challenge Idea doesn't exist. Check your link.",
    homePage: 'DEALEM - home page',
  },
};

const DealNotExists: FC<{
  dealIdea?: boolean;
}> = ({
  dealIdea,
}) => {
  const locale = useContext(Language);
  const windowSize = useWindowSize();

  const { innerWidth } = windowSize;

  const smallScreen = innerWidth < 360;
  return (
    <>
      <ContainerBorderOuter>
        <OuterBorder />
      </ContainerBorderOuter>
      <ContainerBorder>
        <InnerBorder />
      </ContainerBorder>
      <Container>
        <Circle>
          <Icon
            height="28"
            width="28"
            iconType={iconTypes.QUESTION_MARK}
            fill={GREY_DARK}
          />
        </Circle>
      </Container>
      <Row
        sm={smallScreen}
        lg={!smallScreen}
        center
      >
        <Col size={1} />
        <Col
          size={10}
          center
        >
          <InfoCardDashed
            title={dealIdea ? switchLanguage(locale, texts).titleIdea : switchLanguage(locale, texts).title}
            description={dealIdea ? switchLanguage(locale, texts).descriptionIdea : switchLanguage(locale, texts).description}
          />
        </Col>
        <Col size={1} />
      </Row>
      <LinkContainer>
        <Link
          to="/"
          style={LINK_NO_STYLE}
        >
          <NavAppName />
        </Link>
      </LinkContainer>
    </>
  );
};

export default DealNotExists;


const InnerBorder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  border: 1px dashed ${GREY_DARK};
  z-index: 10;
`;

const OuterBorder = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border: 1px dashed ${GREY_DARK};
`;

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
    @media only screen and (max-height: 630px) {
      display: none;
    }
`;

const ContainerBorder = styled(Container)`
 z-index: 20;
`;

const ContainerBorderOuter = styled(Container)`
 z-index: 30;
`;

const LinkContainer = styled.div`
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translateX(-50%) translateY(-55%);
    ${TEXT_DEALNAME}
    font-size:14;
    color: ${GREY};
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-height: 370px) {
      display: none;
    }
`;

const Circle = styled.div`
    display: flex;
    width: 90px;
    height: 90px;
    border-radius: 45px;
    background: white;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 ${SHADOW_GREY};
`;
