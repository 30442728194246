import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { FONT_SIZE_SMALL, GREY, LETTER_SPACING_X_POS } from '../../../styles';

const MenuItemLinkToPublic = ({
  link,
  title,
}) => (
  <LinkStyled
    to={link}
  >
    <Title>
      {_.toUpper(title)}
    </Title>
  </LinkStyled>
);

export default MenuItemLinkToPublic;


const LinkStyled = styled(Link)`
   display: flex;
   justify-content: center;
   align-items: center;
`;

const Title = styled.div`
    color: ${GREY};
    letter-spacing: ${LETTER_SPACING_X_POS};
    text-align: center;
    text-transform: uppercase;
    font-size: ${FONT_SIZE_SMALL};
    :hover{
      color: white;
    }
`;
