import useWindowSize from '@rehooks/window-size';
import React, { memo } from 'react';
import SMDesktop from './SMDesktop';
import SMMobile from './SMMobile';


const SM = () => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;

  if (innerWidth < 1024) {
    return (
      <>
        <SMMobile />
      </>
    );
  }
  return (
    <SMDesktop />
  );
};

export default memo(SM);
