import React from 'react';
import styled from 'styled-components';

import { Row } from '../../../../../styles';
import ArrowsRow from '../ArrowsRow';
import IdeaLine from '../IdeaLine';

const AuthorArrows = () => (
  <Row
    center
    style={{
      marginTop: 6,
    }}
  >
    <ThinContainer>
      <ArrowsRow />
      <IdeaLine />
    </ThinContainer>
  </Row>
);


export default AuthorArrows;


const ThinContainer = styled.div` 
  width: 360px;
`;
