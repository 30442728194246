import React from 'react';
import styled from 'styled-components';

import {
  ORANGE, Col, GREY_DARK, Row,
} from '../../../../styles';

const IdeaCurvedLines = () => (
  <Row
    center
    xsm
  >
    <Col size={2} />
    <Col
      size={3}
    >
      <LineCurved
        height="30px"
      />
    </Col>
    <Col
      size={3}
      jEnd
    >
      <LineCurvedRight
        height="30px"
        colorLine
      />
    </Col>
    <Col size={2} />
  </Row>
);


export default IdeaCurvedLines;


const LineCurved = styled.div<{colorLine?: boolean; height: string}>`
  width: 1px;
  border-left: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  border-bottom: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  min-height: ${(props) => props.height};
  height: auto;
  width: 100%;
  border-bottom-left-radius: 10px;
`;
const LineCurvedRight = styled.div<{colorLine?: boolean; height: string}>`
  width: 1px;
  border-right: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  border-bottom: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  min-height: ${(props) => props.height};
  height: auto;
  width: 100%;
  border-bottom-right-radius: 10px;
`;
