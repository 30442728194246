import useWindowSize from '@rehooks/window-size';
import React, { useContext } from 'react';
import styled from 'styled-components';

import apBadge from '../../assets/apBadge.svg';
import gpBadge from '../../assets/gpBadge.svg';
import {
  Col, DIST_MEDIUM, DIST_SMALL, Row, TEXT_CARD_SMALL, GREY_LIGHT,
} from '../../styles';
import Language from '../../contexts/Language';
import switchLanguage from '../../hoc/switchLanguage';

export const texts = {
  en: {
    gp: 'Google Play and the Google Play logo are trademarks of Google LLC.',
    as: 'Apple, the Apple logo, iPhone are trademarks of Apple Inc., registered in the U.S. and other countries and regions. App Store is a service mark of Apple Inc',
  },
};

const LoginDownloadIcons: React.FunctionComponent = () => {
  const windowSize = useWindowSize();
  const locale = useContext(Language);
  if (windowSize.innerWidth <= 425) {
    return (
      <>
        <Row
          bg
          center
        >
          <img
            src={apBadge}
            height="48px"
            alt="app store"
          />
        </Row>
        <Row
          md
          center
        >
          <img
            src={gpBadge}
            height="48px"
            alt="google store"
          />
        </Row>
        <Row
          center
          bgr
        >
          <Col size={3} />
          <Col
            size={6}
            center
          >
            <TextLaw>
              {switchLanguage(locale, texts).as}
            </TextLaw>
          </Col>
          <Col size={3} />
        </Row>
        <Row
          center
          sm
        >
          <Col size={3} />
          <Col
            size={6}
            center
          >
            <TextLaw>
              {switchLanguage(locale, texts).gp}
            </TextLaw>
          </Col>
          <Col size={3} />
        </Row>
      </>
    );
  }
  return (
    <Row
      md
      center
    >
      <Container>
        <Row
          bgr
          center
        >
          <Col size={1} />
          <BadgesColumn size={10}>
            <a
              href="https://apps.apple.com/app/id1483466835"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              <img
                src={apBadge}
                height="48px"
                alt="app store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.eodcode.dealem.bodhidharma"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              <GpBadge
                src={gpBadge}
                height="48px"
                alt="google play"
              />
            </a>
          </BadgesColumn>
          <Col size={1} />
        </Row>
        <Row
          center
          bgr
        >
          <Col size={3} />
          <Col
            size={6}
            center
          >
            <TextLaw>
              {switchLanguage(locale, texts).as}
            </TextLaw>
          </Col>
          <Col size={3} />
        </Row>
        <Row
          center
          sm
        >
          <Col size={3} />
          <Col
            size={6}
            center
          >
            <TextLaw>
              {switchLanguage(locale, texts).gp}
            </TextLaw>
          </Col>
          <Col size={3} />
        </Row>
      </Container>
    </Row>
  );
};


export default (LoginDownloadIcons);

const Container = styled.div`
  width: 768px;
`;


const BadgesColumn = styled(Col)`
  justify-content: center;
  @media only screen and (max-width: 425px){
    flex-direction: column;
  } 
`;

const GpBadge = styled.img`
  margin-left: ${DIST_MEDIUM};
  @media only screen and (max-width: 425px){
    margin-left: ${DIST_SMALL};
    align-items: center;
  }
`;


export const TextLaw = styled.span`
  ${TEXT_CARD_SMALL}
  color: ${GREY_LIGHT};
  align-items: center;
`;
