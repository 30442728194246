import useWindowSize from '@rehooks/window-size';
import React, { memo } from 'react';
import HomeFooterDesktop from './HomeFooterDesktop';
import HomeFooterMobile from './HomeFooterMobile';


const HomeFooter: React.FunctionComponent = () => {
  const windowSize = useWindowSize();

  if (windowSize.innerWidth < 768) {
    return (
      <HomeFooterMobile />
    );
  }
  return (
    <HomeFooterDesktop />
  );
};

export default memo(HomeFooter);
