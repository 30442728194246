import React, { FC } from 'react';
import styled from 'styled-components';

import {
  Col, FONT_BOLD, FONT_SIZE_X_SMALL, GREY, GREY_DARK, LETTER_SPACING_NEG, ORANGE, Row, TEXT_CARD_SMALL,
} from '../../../../styles';

const IdeaPersons: FC<{
  committedUsername: string;
  witnessUsername: string;
  invitedIsWitness?: boolean;
}> = ({
  committedUsername,
  witnessUsername,
  invitedIsWitness,
}) => (
  <Row
    center
  >
    <Col size={1} />
    <Col
      size={4}
      center
    >
      <ContainerStyle>
        <Row
          sm
          center
        >
          <TextDark>
            {committedUsername}
          </TextDark>
        </Row>
        <Row center>
          <TextLight>
            challenged
          </TextLight>
        </Row>
        {invitedIsWitness
          ? (
            <Row
              xsm
              center
            >
              <Badge>
                INITIATOR
              </Badge>
            </Row>
          ) : (
            <Row
              xsmB
            />
          )}
      </ContainerStyle>
    </Col>
    <Col size={4} />
    <Col
      size={4}
      center
    >
      <ContainerStyle>
        <Row
          sm
          center
        >
          <TextDark>
            {witnessUsername}
          </TextDark>
        </Row>
        <Row center>
          <TextLight>
            supervisor
          </TextLight>
        </Row>
        {invitedIsWitness === false
          ? (
            <Row
              xsm
              center
            >
              <Badge>
                INITIATOR
              </Badge>
            </Row>
          ) : (
            <Row
              xsmB
            />
          )}
      </ContainerStyle>
    </Col>
    <Col size={1} />
  </Row>

);

export default IdeaPersons;


export const ContainerStyle = styled.div<{shadow?: boolean; drawer?: boolean}>`
  width: 100%;
  background: white;
  box-shadow: 0 10px 40px 0 rgba(190,190,207,0.50);
  border-radius: 5px;
`;


const Badge = styled.div`
   padding: 2px 8px;
   background: ${ORANGE};
   font-size: ${FONT_SIZE_X_SMALL};
   font-weight: 700;
   color: white;
   text-align: center;
   margin-bottom: -7px;
   border-radius: 8px;
   z-index: 2;
   box-shadow: 0 10px 40px 0 rgba(190,190,207,0.50);
`;

export const TextDark = styled.div`
  color: ${GREY_DARK};
  align-items: center;
  font-weight: ${FONT_BOLD};    
  ${TEXT_CARD_SMALL}
    align-items: center;
`;

export const TextLight = styled.div`
   font-size: ${FONT_SIZE_X_SMALL};
   letter-spacing: ${LETTER_SPACING_NEG};
   color: ${GREY};
   align-items: center;
   justify-content: center;
   margin-top: 2px;
`;
