import React from 'react';
import styled from 'styled-components';

import {
  Col,
  GREY_DARK,
  GREY_MEDIUM,
  Row,
  SHADOW_GREY_PHONE_ICON,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';


const IdeaBottomIcons = () => (
  <Row
    style={{ marginTop: '6px' }}
    center
  >
    <Col size={1} />
    <Col
      size={2}
      center
    >
      <CircleTop>
        <IconWrapperSmall>
          <Icon
            height="14"
            width="14"

            iconType={iconTypes.SMILE}
            fill={GREY_DARK}
          />
        </IconWrapperSmall>
      </CircleTop>
    </Col>
    <Col
      size={4}
      center
    />
    <Col
      size={2}
      center
    >
      <CircleTop>
        <IconWrapperSmall>
          <Icon
            height="14"
            width="14"
            iconType={iconTypes.SAD}
            fill={GREY_DARK}
          />
        </IconWrapperSmall>
      </CircleTop>
    </Col>
    <Col size={1} />
  </Row>
);


export default IdeaBottomIcons;


const CircleTop = styled.div<{grey?: boolean}>`
    margin-top: -62px;
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.grey ? GREY_MEDIUM : 'white')};
    box-shadow: 0 0px 6px 0 ${SHADOW_GREY_PHONE_ICON};
    z-index:1;
`;

const IconWrapperSmall = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: dashed; 
    border-color: ${GREY_DARK};
    display: flex;
`;
