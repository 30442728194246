import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Language from '../../../../contexts/Language';
import switchLanguage from '../../../../hoc/switchLanguage';
import { Col, GET_DEALEM_LINK, Row } from '../../../../styles';
import { ButtonEmpty } from '../../../../styles/buttons';
import * as iconTypes from '../../../../styles/iconTypes';
import NavAppName from '../NavAppName';
import NavAvatar from '../NavAvatar';
import NavLinkStore from '../NavLinkStore';

export const texts = {
  en: {
    features: 'Get Dealem',
    premium: 'Premium',
    ideas: 'Challenge Contract Ideas',
    logIn: 'Login',
    logout: 'Logout',
  },
};

type Props = {
  userId: string;
  dealId: string;
  onSignOut: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  noLogin?: boolean;
}

const DealItemPublicNavDesktop: React.FunctionComponent<Props> = ({
  userId,
  dealId,
  onSignOut,
  noLogin,
}) => {
  const locale = useContext(Language);
  const renderButton = () => {
    if (userId) {
      return (
        <ButtonEmpty
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onClick={onSignOut}
        >
          {switchLanguage(locale, texts).logout}
        </ButtonEmpty>
      );
    }
    return (
      <NavLink
        to={{
          pathname: '/logIn',
          state: { dealId },
        }}
        activeClassName="logInActive"
      >
        <ButtonEmpty>
          {switchLanguage(locale, texts).logIn}
        </ButtonEmpty>
      </NavLink>
    );
  };

  return (
    <Row
      md
      center
    >
      <Container>
        <Row>
          <Col size={1} />
          <Col
            size={2}
            ver
            center
          >
            <NavAppName />
          </Col>
          <Col
            size={2}
            jEnd
          >
            <NavLinkStore
              iconType={iconTypes.DOWNLOAD}
              iconTypeHover={iconTypes.DOWNLOAD}
              link={GET_DEALEM_LINK}
              text={switchLanguage(locale, texts).features}
            />
          </Col>
          <Col
            size={noLogin ? 3 : 2}
            center
          >
            <NavLinkStore
              iconType={iconTypes.BULB}
              iconTypeHover={iconTypes.BULB_SOLID}
              link="/ideas"
              text={switchLanguage(locale, texts).ideas}
            />
          </Col>
          {noLogin
            ? null
            : (
              <>
                <Col
                  size={2}
                  ver
                  center
                >
                  {renderButton()}
                </Col>

              </>
            )}
          <Col
            size={noLogin ? 2 : 1}
            ver
            center
          >
            <NavAvatar />
          </Col>
          <Col size={1} />
        </Row>
      </Container>
    </Row>
  );
};


export default (DealItemPublicNavDesktop);

const Container = styled.div`
    width: 768px;
  `;
