import React, { useContext, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Language from '../contexts/Language';
import switchLanguage from '../hoc/switchLanguage';
import {
  ORANGE,
  Col,
  GREY,
  LINK_NO_STYLE,
  Row,
  ScreenLinkTextStyle,
} from '../styles';
import Icon from '../styles/Icon';
import * as iconTypes from '../styles/iconTypes';


export const goBackText = {
  en: {
    goBack: 'go back',
  },
};

type Props = {
  location: {
    pathname: string;
    state: {
      dealId?: string;
    };
  };
}

const PublicGoBackBar: React.FunctionComponent<Props> = ({
  location,
}) => {
  const [isIconPulsing, setIconPulsing] = useState(false);

  const locale = useContext(Language);
  return (
    <Row
      lg
      center
    >
      <Container>
        <Row
          ver
          center
        >
          <Col
            size={3}
            jEnd
          >
            <Link to={
              location.state ? `/deal/${location.state.dealId}` : '/'}

            >
              <BackIcon
                className={isIconPulsing ? 'animated slideOutLeft infinite' : ''}
                onMouseEnter={() => setIconPulsing(true)}
                onMouseLeave={() => setIconPulsing(false)}
              >
                <Icon
                  height="18"
                  width="18"
                  iconType={iconTypes.ARROW_LEFT}
                  fill={isIconPulsing ? ORANGE : GREY}
                />
              </BackIcon>
            </Link>
          </Col>
          <Col
            size={6}
            center
          >
            <Link
              to={location.state ? `/deal/${location.state.dealId}` : '/'}
              style={LINK_NO_STYLE}
              onMouseEnter={() => setIconPulsing(true)}
              onMouseLeave={() => setIconPulsing(false)}
            >
              <ScreenLinkTextStyle>
                {switchLanguage(locale, goBackText).goBack}
              </ScreenLinkTextStyle>
            </Link>
          </Col>
          <Col
            size={3}
          />
        </Row>
      </Container>
    </Row>
  );
};

export default withRouter(PublicGoBackBar as any);


const Container = styled.div`
    width: 768px;
  `;

const BackIcon = styled.div`
    float: right;
`;
