import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import apBadge from '../../../assets/apBadge.svg';
import gpBadge from '../../../assets/gpBadge.svg';
import Language from '../../../contexts/Language';
import switchLanguage from '../../../hoc/switchLanguage';
import {
  ButtonHeader,
  Col,
  DIST_BIGGER,
  DIST_MEDIUM,
  DIST_SMALL,
  FONT_FUTURA,
  GREY_DARK,
  GREY_LIGHT,
  Row,
} from '../../../styles';


export const texts = {
  en: {
    subtitle: 'Hype your actions up with Dealem app!',
    title: 'Try unique interaction <br />with surprises.',
    createDeal: 'Contract your challenge.',
    button: 'Try dealem!',
    download: 'Download app',
  },
};

const HomeHeader = () => {
  const locale = useContext(Language);
  return (
    <Row
      center
      md
    >
      <Container>
        <Row>
          <Col size={1} />
          <Col size={9}>
            <HeaderWrapper>
              <HeaderSubtile>
                {switchLanguage(locale, texts).subtitle}
              </HeaderSubtile>
              <HeaderTitleH1>
                {switchLanguage(locale, texts).createDeal}
              </HeaderTitleH1>
              <HeaderTitleH2>
                {ReactHtmlParser(switchLanguage(locale, texts).title)}
              </HeaderTitleH2>
              <HeaderButtonWrapper>
                <AnchorLink
                  href="#intro"
                  offset="144"
                >
                  <ButtonHeader>
                    {switchLanguage(locale, texts).button}
                  </ButtonHeader>
                </AnchorLink>
              </HeaderButtonWrapper>
            </HeaderWrapper>
          </Col>
          <Col size={2} />
        </Row>
        <Row bgr>
          <Col size={1} />
          <BadgesColumn size={9}>
            <a
              href="https://apps.apple.com/app/id1483466835"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              <img
                src={apBadge}
                height="48px"
                alt="app store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.eodcode.dealem.bodhidharma"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              <GpBadge
                src={gpBadge}
                height="48px"
                alt="google play"
              />
            </a>
          </BadgesColumn>
          <Col size={1} />
        </Row>
      </Container>
    </Row>
  );
};

export default HomeHeader;


const Container = styled.div`
  width: 1224px;
  @media only screen and (max-width: 768px){
     width: 768px;
  }
`;

const HeaderWrapper = styled.header`
  /* margin: 80px 0; */
  margin-bottom: ${DIST_BIGGER};
  margin-top: 24px;
  @media only screen and (max-width: 768px){
     margin-top: 0;
  }
`;

const HeaderSubtile = styled.h2`
  /* font-family:${FONT_FUTURA}; */
  font-size: 16px;
  color: ${GREY_LIGHT};
  letter-spacing: 2.67px;
  margin-bottom: 42px;
  text-transform: uppercase;
`;

const HeaderTitleH1 = styled.h1`
  font-family:${FONT_FUTURA};
  font-size: 40px;
  color: ${GREY_DARK};
  letter-spacing: -2px;
  line-height: 48px;
  font-weight: 400;
  margin: 0;
`;

const HeaderTitleH2 = styled.h2`
  font-family:${FONT_FUTURA};
  font-size: 40px;
  color: ${GREY_DARK};
  letter-spacing: -2px;
  line-height: 48px;
  font-weight: 400;
  margin: 0;
`;

const HeaderButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
`;

const BadgesColumn = styled(Col)`
  margin-bottom: ${DIST_MEDIUM};
  @media only screen and (max-width: 425px){
     justify-content: center;
  }
`;

const GpBadge = styled.img`
  margin-left: ${DIST_MEDIUM};
  @media only screen and (max-width: 375px){
    margin-left: ${DIST_SMALL};
  }
`;
