import React, { useContext } from 'react';
import styled from 'styled-components';

import Language from '../../../../contexts/Language';
import switchLanguage from '../../../../hoc/switchLanguage';
import {
  FONT_FUTURA, GREY_DARK, GREY_MEDIUM, H3,
} from '../../../../styles';
import * as iconTypes from '../../../../styles/iconTypes';
import IntroCard from '../IntroStyle/IntroCard';
import IntroComment from '../Comments/IntroComment';

const texts = {
  en: {
    subtitle: 'More features',
    title: 'Attachments',
    why: 'WHY IT WORKS LIKE THAT ?',
    descriptionWhy: 'You want to show your progress through photos ? Show were you started and proof that you finished. It feels good !',
    photoTitle: 'Proof',
    photoTitleLight: ' and starting point photos or videos',
    photoDescription: 'You can attach a photo/video of the starting point. Likewise, you can add a photo/video proving your achievement.',
    photoExample: 'I did it, have a look!',
    pdfTitle: 'Attach',
    pdfTitleLight: ' more text',
    pdfDescription: "Sometimes the challenge description can be long. No problem! You can attach a pdf file to deal's invitation.",
    pdfExample: 'Woohoo, good job!',
  },
};


const FeaturesCards = () => {
  const locale = useContext(Language);
  return (
    <Container>
      <Subtile>
        {switchLanguage(locale, texts).subtitle}
      </Subtile>
      <Title>
        {switchLanguage(locale, texts).title}
      </Title>
      <IntroCard
        title={switchLanguage(locale, texts).photoTitle}
        titleLight={switchLanguage(locale, texts).photoTitleLight}
        description={switchLanguage(locale, texts).photoDescription}
        iconType={iconTypes.PHOTO}
        titleTagComponent={<H3 />}
      />
      <IntroCard
        title={switchLanguage(locale, texts).pdfTitle}
        titleLight={switchLanguage(locale, texts).pdfTitleLight}
        description={switchLanguage(locale, texts).pdfDescription}
        iconType={iconTypes.PAPERCLIP}
        titleTagComponent={<H3 />}
      />
      <IntroComment
        rightAvatar
        text={switchLanguage(locale, texts).pdfExample}
      />
    </Container>
  );
};

export default FeaturesCards;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  @media only screen and (max-width: 1200px){
    padding-top: 66px;
  }
`;

const Subtile = styled.p`
  font-size: 14px;
  color: ${GREY_MEDIUM};
  letter-spacing: 2.67px;
  text-transform: uppercase;
  font-weight: bold;
`;

const Title = styled.h1`
  font-size: 32px;
  color: ${GREY_DARK};
  letter-spacing: -2px;
  line-height: 48px;
  font-weight: 400;
  margin-bottom: 32px;
  font-family:${FONT_FUTURA};
`;

export const Border = styled.div`
    border-top: 1px dashed ${GREY_DARK};
    height: 1px; 
    width: 42px;
`;
