
// log in

export const LOG_IN_EMAIL_CHANGED = 'LOG_IN_EMAIL_CHANGED';
export const LOG_IN_PASSWORD_CHANGED = 'LOG_IN_PASSWORD_CHANGED';
export const LOG_IN_LOADING = 'LOG_IN_LOADING';
export const LOG_IN_RESET = 'LOG_IN_RESET';
export const LOG_IN_USER_SUCCESS = 'LOG_IN_USER_SUCCESS';
export const LOG_IN_FAILED = 'LOG_IN_FAILED';
export const LOG_IN_FAILED_OFF = 'LOG_IN_FAILED_OFF';

// Logged status

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_NOT_LOGGED_IN = 'USER_NOT_LOGGED_IN';

export const HIDE_PUBLIC_FOOTER = 'HIDE_PUBLIC_FOOTER';

export const OPEN_MENU = 'OPEN_MENU';

export const IMAGES_LOADING_OFF = 'IMAGES_LOADING_OFF';
