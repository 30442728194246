import React from 'react';
import PhoneRow from '../IntroStyle/PhoneRow';
import IntroDealCards from './IntroDealCards';

const IntroDealDesktop = () => (
  <PhoneRow
    positionY={2800}
  >
    <IntroDealCards />
  </PhoneRow>
);

export default IntroDealDesktop;
