import useWindowSize from '@rehooks/window-size';
import React, { useContext } from 'react';
import styled from 'styled-components';

import Language from '../../../contexts/Language';
import switchLanguage from '../../../hoc/switchLanguage';
import {
  ORANGE,
  GREY_DARK,
  GREY_LIGHT,
  GREY_MEDIUM,
  Row,
  SHADOW_GREY_DARK,
  shadowNavAvatar,
} from '../../../styles';
import Icon from '../../../styles/Icon';
import { DIAMOND, MEGAPHONE, PHOTO } from '../../../styles/iconTypes';
import generateIconName from '../UI/HomeIcons';
import HomeRowIcons from '../UI/HomeRowIcons';
import HomeTitleSection from '../UI/HomeTitleSection';
import PremiumCard from './PremiumCard';
import PremiumCardBackground from './PremiumCardBackground';

const texts = {
  en: {
    subtitle: 'extra features',
    title: 'Dealem Premium',
    description: "Dealem is free. To unlock additional features, subscribe to Dealem. <br/>It's only 1.49 $ monthly of great support for Dealem.",
    moreDeals: {
      title: 'Number',
      titleLight: ' of challenges: up to 20',
      description: '3 challenges available for non-premium account',
    },
    noAdds: {
      title: 'No',
      titleLight: 'ads',
      description: 'Enjoy fully ad-free experience',
    },
    prizePic: {
      title: 'Attach',
      titleLight: 'extra pictures and videos',
      description: 'Attach pictures and videos showing your journey step by step',
    },
  },
};


const Premium = () => {
  const locale = useContext(Language);
  const windowSize = useWindowSize();

  const { innerWidth } = windowSize;

  return (
    <>
      <HomeRowIcons
        generateIconsNames={generateIconName}
        numberOfIcons={10}
        iconColor="white"
        backgroundColor={GREY_LIGHT}
        id="premium"
      />
      <Contanier>
        <Circle>
          <IconWrapperGear>
            <Icon
              height="24"
              width="24"

              iconType={DIAMOND}
              fill="white"
            />
          </IconWrapperGear>
        </Circle>
        <HomeTitleSection
          title={switchLanguage(locale, texts).title}
          subtitle={switchLanguage(locale, texts).subtitle}
          description={switchLanguage(locale, texts).description}
          dark
          id=""
        />
      </Contanier>
      <Row
        center
        bgr
      >
        <Border />
      </Row>
      <Row
        center
        bgr
      >
        <PremiumCardBackground
          iconColor="white"
          backgroundColor={ORANGE}
          width={innerWidth > 426 ? 360 : 300}
          iconType={DIAMOND}
          bordersRadius
        />
      </Row>
      <CardWrapper
        center
      >
        <PremiumCard
          title={switchLanguage(locale, texts).moreDeals.title}
          titleLight={switchLanguage(locale, texts).moreDeals.titleLight}
          description={switchLanguage(locale, texts).moreDeals.description}
        />
      </CardWrapper>
      <Row
        center
        lg
      >
        <PremiumCardBackground
          iconColor="white"
          backgroundColor={ORANGE}
          width={innerWidth > 426 ? 360 : 300}
          iconType={MEGAPHONE}
          bordersRadius
          rotate90
        />
      </Row>
      <CardWrapper
        center
      >
        <PremiumCard
          title={switchLanguage(locale, texts).noAdds.title}
          titleLight={switchLanguage(locale, texts).noAdds.titleLight}
          description={switchLanguage(locale, texts).noAdds.description}
          off
        />
      </CardWrapper>
      <Row
        center
        lg
      >
        <PremiumCardBackground
          iconColor="white"
          backgroundColor={ORANGE}
          width={innerWidth > 426 ? 360 : 300}
          iconType={PHOTO}
          bordersRadius
        />
      </Row>
      <CardWrapper
        center
        lgB
      >
        <PremiumCard
          title={switchLanguage(locale, texts).prizePic.title}
          titleLight={switchLanguage(locale, texts).prizePic.titleLight}
          description={switchLanguage(locale, texts).prizePic.description}
        />
      </CardWrapper>
    </>
  );
};

export default Premium;


const Contanier = styled.div`
   width: 1440;
   display: flex;
   flex-direction: column;
   align-items:center;
   @media only screen and (max-width: 1439px){
     width: 100%;
  }
`;

const CardWrapper = styled(Row)`
    margin-top: -76px;
`;

export const Border = styled.div`
    border-top: 1px dashed ${GREY_DARK};
    height: 1px; 
    width: 42px;
`;

const Circle = styled.div`
    display: flex;
    width: 58px;
    height: 58px;
    border-radius: 29px;
    justify-content: center;
    align-items: center;
    background: ${GREY_MEDIUM};
  ${shadowNavAvatar(SHADOW_GREY_DARK)};
    z-index:2;
    margin-top: -29px;
`;


const IconWrapperGear = styled.div`
    width: 52px;
    height: 52px;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: dashed; 
    border-color: white;
    display: flex;
`;
