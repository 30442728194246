import React from 'react';
import styled from 'styled-components';

import PrivacyNav from '../components/Nav/Public/Privacy/PrivacyNav';
import PublicGoBackBar from '../components/PublicGoBackBar';
import terms from '../law/terms';
import { Row } from '../styles';
import { Link } from './Privacy';
import {
  APPLE_TERMS, GOOGLE_TERMS, FACEBOOK_TERMS,
} from '../styles/links';

const Terms = () => (
  <div>
    <PrivacyNav />
    <Row
      center
      bg
    >
      <Container>
        <Title>{terms.title}</Title>
        <p>
          {terms.desc1}
        </p>
        <Warning>{terms.subtitle1}</Warning>
        <Subtitle>§1</Subtitle>
        <p>
          {terms.desc2}
        </p>
        <Subtitle>§2</Subtitle>
        <p>
          {terms.desc3}
        </p>
        <Subtitle>§3</Subtitle>
        <p>
          {terms.desc4}
        </p>
        <Subtitle>§4</Subtitle>
        <p>
          {terms.desc5}
        </p>
        <Subtitle>§5</Subtitle>
        <p>
          {terms.desc6}
        </p>
        <Subtitle>§6</Subtitle>
        <p>
          {terms.desc7}
        </p>
        <Subtitle>§7</Subtitle>
        <p>
          {terms.desc8}
        </p>
        <Subtitle>§8</Subtitle>
        <p>
          {terms.desc9}
        </p>
        <Subtitle>§9</Subtitle>
        <p>
          {terms.desc10}
        </p>
        <Subtitle>§10</Subtitle>
        <p>
          {terms.desc11}
        </p>
        <Subtitle>§11</Subtitle>
        <p>
          {terms.desc12}
        </p>
        <Subtitle>§12</Subtitle>
        <p>
          {terms.desc13}
        </p>
        <Subtitle>§14</Subtitle>
        <p>
          {terms.desc14}
        </p>
        <Subtitle>§14</Subtitle>
        <p>
          {terms.desc15}
        </p>
        <Subtitle>§15</Subtitle>
        <p>
          {terms.desc16}
        </p>
        <Subtitle>§16</Subtitle>
        <p>
          {terms.desc17}
        </p>
        <Subtitle>§17</Subtitle>
        <p>
          {terms.desc18}
        </p>
        <Subtitle>§18</Subtitle>
        <p>
          {terms.desc19}
        </p>
        <Subtitle>§19</Subtitle>
        <p>
          {terms.desc20}
        </p>
        <Subtitle>§20</Subtitle>
        <p>
          {terms.desc21}
        </p>
        <ul>
          <li>
            <Link
              href={APPLE_TERMS}
              target="_blank"
              rel="noopener noreferrer"
            >
              Apple
            </Link>
          </li>
          <li>
            <Link
              href={GOOGLE_TERMS}
              target="_blank"
              rel="noopener noreferrer"
            >
              Google
            </Link>
          </li>
          <li>
            <Link
              href={FACEBOOK_TERMS}
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </Link>
          </li>
        </ul>
      </Container>
    </Row>
    <PublicGoBackBar />
    <Row lg />
  </div>
);

export default Terms;

const Container = styled.div`
max-width: 768px;
margin:0 24px;
`;
const Title = styled.h2`
justify-content: center;
margin-bottom: 48px;
`;

const Subtitle = styled.h4`
font-weight: 700;
margin: 24px 0;
justify-content: center;
`;
const Warning = styled.h4`
font-weight: 700;
margin: 24px 0;
`;
