import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import firebase from '../../firebase';
import AnimSlideDown from '../../hoc/AnimSlideDown';
import convertToDate from '../../hoc/convertToDate';
import { hidePublicFooter } from '../../store/actions';
import {
  Col, DIST_MEDIUM, GREY_LIGHT, Row, SHADOW_GREY_DRAWER, shadowDrawer,
} from '../../styles';
import DealCardAttachment from '../UI/Cards/DealCard/DealCardAttachment';
import MediaDrawer from '../UI/Media/MediaDrawer';
import DealItemDrawerBottomRow from './DealItemDrawerBottomRow';


type IDealItemDrawerProps = {
  isMinimizeOn?: boolean;
  hidePublicFooter: Function;
  proof: any;
  done: boolean;
  attachment: any;
  dealId: string;
  startingPoint: any;
};



export const texts = {
  en: {
    startingPoint: 'starting point',
    proof: 'proof',
    attachment: 'attachment',
  },
};


const DealItemDrawer: React.FunctionComponent<IDealItemDrawerProps> = ({
  done,
  proof,
  attachment,
  startingPoint,
  isMinimizeOn,
  // eslint-disable-next-line no-shadow
  hidePublicFooter,
  dealId,
}) => {
  const [isContentVisible, setContentVisible] = useState(false);
  const [isHeightOn, startAinmHeight] = useState(false);
  const [isOpacityOn, startAimOpacity] = useState(false);
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [mediaLimit, setMediaLimit] = useState(2);

  useEffect(() => {
    let unsubscribe: any;
    if (isContentVisible) {
      unsubscribe = firebase.firestore()
        .collection('deals')
        .doc(dealId)
        .collection('addMedia')
        .doc('addMedia')
        .collection('addMediaDocs')
        .orderBy('dateCreated', 'desc')
        .limit(mediaLimit)
        .onSnapshot(
          (querySnapshot) => {
            if (querySnapshot.empty) {
              setMediaList([]);
              if (proof) {
                const proofMedia = {
                  addMedia: false,
                  dateCreated: proof.dateCreated,
                  dealId,
                  key: proof.dateCreated,
                  mediaDescription: 'proof',
                  photoUri: proof.photoUri,
                  videoUri: proof.videoUri,
                };
                setMediaList([proofMedia]);
              }
            } else {
              const query: any = [];
              querySnapshot.forEach((doc) => {
                const photoData = {
                  ...doc.data(),
                  addMedia: true,
                  photoId: doc.id,
                  dealId,
                  key: doc.id,
                };
                query.push(photoData);
              });
              if (proof) {
                const proofMedia = {
                  addMedia: false,
                  dateCreated: proof.dateCreated,
                  dealId,
                  key: proof.dateCreated,
                  mediaDescription: 'proof',
                  photoUri: proof.photoUri,
                  videoUri: proof.videoUri,
                };
                query.push(proofMedia);
              }
              const sorted = _.orderBy(query, ['dateCreated'], ['desc']);
              setMediaList(_.reverse(sorted));
            }
          },
          (error) => {
            console.log(error);
          },
        );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dealId, isContentVisible, mediaLimit, proof]);

  const List = () => {
    const mList = mediaList.map((props: any) => (
      <MediaDrawer
        {...props}
        key={props.key}
        title={props.mediaDescription}
        photoUri={props.photoUri}
        videoUri={props.videoUri}
        description={convertToDate(props.dateCreated)}
      />
    ));
    return (
      <>
        {mediaList.length >= 2 ? (
          <Row
            sm
            center
            onClick={() => setMediaLimit(mediaLimit + 5)}
            cursor
          >
            <MoreText>
              more pictures/videos...
            </MoreText>
          </Row>
        ) : null}
        {mList}
      </>
    );
  };


  const handleContentAnim = (p: boolean) => {
    hidePublicFooter();
    if (p) {
      hidePublicFooter(true);
      setContentVisible(true);
      startAinmHeight(true);
      setTimeout(() => {
        startAimOpacity(true);
      }, 500);
    }
    if (!p) {
      startAinmHeight(false);
      startAimOpacity(false);
      setTimeout(() => {
        setContentVisible(false);
        hidePublicFooter(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (isMinimizeOn) {
      handleContentAnim(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMinimizeOn]);


  const renderStartingPointImage = () => {
    if (startingPoint) {
      return (
        <MediaDrawer
          title="starting point"
          photoUri={startingPoint.photoUri}
          videoUri={startingPoint.videoUri}
          description={convertToDate(startingPoint.dateCreated)}
        />
      );
    } return (
      null
    );
  };


  const renderAttachmentFile = () => {
    if (attachment) {
      return (
        <DealCardAttachment
          attachment={attachment}
        />
      );
    } return (
      null
    );
  };

  const renderContent = () => (
    <>
      {renderAttachmentFile()}
      {renderStartingPointImage()}
      <List />
    </>
  );
  if (attachment
    || proof
    || startingPoint) {
    return (
      <Container>
        <Col size={1} />
        <Col
          size={10}
          center
        >
          <Cont>
            <AnimSlideDown
              isContentVisible={isContentVisible}
              isHeightOn={isHeightOn}
              isOpacityOn={isOpacityOn}
              mediaListLength={mediaList.length}
            >
              { renderContent()}
            </AnimSlideDown>
            <DealItemDrawerBottomRow
              isContentVisible={isContentVisible}
              done={done}
              attachment={attachment}
              proof={proof}
              startingPoint={startingPoint}
              addMedia={mediaList.length > 0}
              onContentShow={() => handleContentAnim(!isContentVisible)}
            />
          </Cont>
        </Col>
        <Col size={1} />
      </Container>
    );
  } return null;
};


const mapStateToProps = (state: any) => ({
  hidePublicFooter: state.hidePublicFooter,
});

export default connect(mapStateToProps,
  {
    hidePublicFooter,
  })(DealItemDrawer);

const Container = styled(Row)`
  margin-bottom: ${DIST_MEDIUM};
  width:100%;
`;

const Cont = styled.div<{isContentVisible?: boolean}>`
    ${shadowDrawer(SHADOW_GREY_DRAWER)};
    flex: 1;
    padding: 18px 0;
    border-radius: 0 0 5px 5px;
    height:auto;
    @media only screen and (max-width: 400px){
     width: 100%;
  }
  width: 330px;
    display: flex;
    flex-direction: column;
`;

const MoreText = styled.span`
   font-size: 9px;
   color: ${GREY_LIGHT}
`;
