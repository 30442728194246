import React, { useContext } from 'react';
import switchLanguage from '../../hoc/switchLanguage';
import ScreenTitle from '../UI/ScreenTitle';
import Language from '../../contexts/Language';


export const logInTitle = {
  en: {
    firstPart: 'Log',
    secondPart: 'In',
  },
};


const LogInTitle = () => {
  const locale = useContext(Language);
  return (
    <ScreenTitle
      firstPart={switchLanguage(locale, logInTitle).firstPart}
      secondPart={switchLanguage(locale, logInTitle).secondPart}
    />
  );
};


export default LogInTitle;
