import React, { FC } from 'react';
import styled from 'styled-components';
import { Row } from '../../../../styles';
import HookFrame from './HookFrame';
import HomeCircleGear from '../../UI/HomeCircleGear';

type Props = {
  isAnimOn: boolean;
  src: string;
}

const Hook: FC <Props> = ({
  isAnimOn,
  src,
}) => (
  <>
    <Container
      stater={isAnimOn}
    >
      <Row
        sm
        center
      >
        <HomeCircleGear
          marginBottom="-18px"
          isAnimOn={isAnimOn}
        />
      </Row>
      <HookFrame
        src={src}
      />
    </Container>
  </>
);


export default Hook;

const Container = styled.div<{stater: boolean}>`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-left: ${(props) => (props.stater ? '0' : '-600px')};
  transition: margin-left 0.7s ease-in-out;
`;
