import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '../../../styles/Icon';
import { CHECK, CLOSE } from '../../../styles/iconTypes';
import {
  CardAvatarWrapperStyle, CardTitleDescriptionTextStyle, CardTitleLightTextStyle, CardTitleTextStyle, Col, DIST_MEDIUM, DIST_SMALL, GREY_LIGHT, Row, shadowCard, SHADOW_GREY_DARK,
} from '../../../styles/index';


type Props = {
  error?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  description: string;
  title: string;
  titleLight: string;
}

const ReportCardSmall: FC<Props> = ({
  onClick,
  description,
  title,
  titleLight,
  error,
}) => (
  <React.Fragment>
    <CardContainer>
      <UpperRow>
        <Col size={1}>
          <IconWrapper
            error={error}
          >
            <Icon
              height="14"
              width="14"
              iconType={error ? CHECK : CHECK}
              fill="white"
              stroke="white"
              strokeWidth="2"
            />
          </IconWrapper>
        </Col>
        <Col size={9}>
          <CardTitleTextStyle>
            {title}
            {' '}
            <CardTitleLightTextStyle>
              {titleLight}
            </CardTitleLightTextStyle>
          </CardTitleTextStyle>
        </Col>
        <Col
          size={2}
          center
          ver
          onClick={onClick}
          cursor
        >
          <Icon
            height="16"
            width="16"
            iconType={CLOSE}
            fill={GREY_LIGHT}
          />
        </Col>
      </UpperRow>
      <LowerRow
        sm
        ver
      >
        <Col size={1} />
        <Col size={10}>
          <CardTitleDescriptionTextStyle>{description}</CardTitleDescriptionTextStyle>
        </Col>
        <Col size={1} />
      </LowerRow>
    </CardContainer>
  </React.Fragment>
);


export default ReportCardSmall;

const CardContainer = styled.div`
  background: white;
  ${shadowCard(SHADOW_GREY_DARK)}
`;

const UpperRow = styled(Row)`
    padding-top: ${DIST_SMALL};
    align-items: center;
`;

const IconWrapper = styled(CardAvatarWrapperStyle)`
    margin-left: -19px;
`;

const LowerRow = styled(Row)`
    font-size: 16px;
    padding-bottom: ${DIST_MEDIUM};
`;
