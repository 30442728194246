import React, { FC } from 'react';
import styled from 'styled-components';

import Img from 'react-image';
import {
  CardTitleDescriptionTextStyle, GREY, GREY_DARK, LINK_NO_STYLE, ORANGE, Row, TEXT_CARD_SMALL,
} from '../../../styles';
import Icon from '../../../styles/Icon';
import * as iconTypes from '../../../styles/iconTypes';
import convertToDate from '../../../hoc/convertToDate';

const IdeaSubtitle: FC<{
  authorName: string | null;
  authorUri: string;
  dateCreated: Date;
  bannerUri: string | null;
  ideaLink: string | null;
}> = ({
  authorName,
  authorUri,
  dateCreated,
  bannerUri,
  ideaLink,
}) => (
  <>
    {ideaLink
      ? (
        <a
          style={LINK_NO_STYLE}
          href={ideaLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row
            md
            center
            ver
          >
            <CheckIdeaText>
              Check this idea here:
            </CheckIdeaText>
            <Icon
              height="16"
              width="16"
              iconType={iconTypes.YOU_TUBE}
              fill={ORANGE}
              style={{
                marginTop: 4,
                marginLeft: 6,
              }}
            />
          </Row>
        </a>
      ) : null}
    <Row
      sm
      center
    >
      <CardTitleDescriptionTextStyle>
        {`Created: ${convertToDate(dateCreated)} by ${authorName ?? 'Dealem'}`}
      </CardTitleDescriptionTextStyle>
    </Row>
    {bannerUri
      ? (
        <a
          style={LINK_NO_STYLE}
          href={authorUri ?? ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row
            center
            sm
            bgrB
          >
            <ImageLogo
              src={bannerUri ?? ''}
              loader={
                <LogoPlaceholder />
              }
            />
          </Row>
        </a>
      ) : <Row bg />}
  </>
);

export default IdeaSubtitle;

const CheckIdeaText = styled.div`
  margin-top: 2px;
  color: ${GREY};
  ${TEXT_CARD_SMALL}
  :hover{
    text-decoration: underline
  }
`;

export const ImageLogo = styled(Img)<{grey?: boolean}>`
    width: 200px;
    height: 100%;
    object-fit: cover;
    z-index:2;
`;

const LogoPlaceholder = styled.div`
     width: 180px;
     height: 25px;
     /* border: 0.5px ${GREY_DARK} dashed;
     border-radius: 2px; */
`;
