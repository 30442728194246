import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import App from './App';
import Language from './contexts/Language';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducers';

import { ENV_TYPE } from './env.json';

const devEnv = ENV_TYPE === 'DEVELOPMENT';

const store = devEnv ? createStore(reducer, composeWithDevTools(
  applyMiddleware(ReduxThunk),
))
  : createStore(reducer,
    applyMiddleware(ReduxThunk));

const app = (
  <Provider store={store}>
    <Language.Provider value={{ locale: 'en-EN' }}>
      <App />
    </Language.Provider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
