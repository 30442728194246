import useWindowSize from '@rehooks/window-size';
import React from 'react';
import { connect } from 'react-redux';
import firebase from '../../../../firebase';
import DealPublicNavDesktop from './DealPublicNavDesktop';
import DealPublicNavMobile from './DealPublicNavMobile';

type Props = {
  userId: string;
  dealId: string;
  noLogin?: boolean;
}

const DealItemPublicNav: React.FunctionComponent<Props> = ({
  userId,
  dealId,
  noLogin,
}) => {
  const handleSignOut = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    // eslint-disable-next-line no-shadow
    event.preventDefault();
    firebase.database().ref('users').off();
    firebase.database().ref('deals').off();
    firebase.database().ref('usernames').off();
    firebase.database().ref('friends').off();

    firebase.auth().signOut().catch(
      (error) => {
        console.log(error);
      },
    );
  };
  const windowSize = useWindowSize();

  if (windowSize.innerWidth < 768) {
    return (
      <DealPublicNavMobile
        dealId={dealId}
        userId={userId}
        onSignOut={handleSignOut}
        noLogin={noLogin}
      />
    );
  }
  return (
    <DealPublicNavDesktop
      dealId={dealId}
      userId={userId}
      onSignOut={handleSignOut}
      noLogin={noLogin}
    />
  );
};


const mapStateToProps = ({ user }: any) => {
  const { userId } = user;
  return ({
    userId,
  });
};

export default connect(mapStateToProps,
  {})(DealItemPublicNav);
