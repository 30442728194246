import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Language from '../../../contexts/Language';
import switchLanguage from '../../../hoc/switchLanguage';
import {
  Col, EMAIL_LINK, FACEBOOK_LINK, GREY_DARK, INSTAGRAM_LINK, LINK_NO_STYLE, Row, ScreenLinkTextStyle, TWITTER_LINK,
} from '../../../styles';
import Icon from '../../../styles/Icon';
import * as iconTypes from '../../../styles/iconTypes';
import DealMenuBorder from '../../UI/Cards/DealCard/DealMenuBorder';
import {
  Border, Container, EODText, Panel, TextDark, texts,
} from '../DealPublicFooter/DealPublicFooterDesktop';
import DealPublicFooterIcon from '../DealPublicFooter/DealPublicFooterIcon';

const HomeFooterMobile: React.FunctionComponent = (

) => {
  const locale = useContext(Language);
  return (
    <Row
      center
    >
      <Container>
        <Row>
          <Col size={1} />
          <Col size={10}>
            <Border />
          </Col>
          <Col size={1} />
        </Row>
        <Row>
          <Col size={1} />
          <DealPublicFooterIcon
            iconType={iconTypes.INSTAGRAM}
            iconTypeHover={iconTypes.INSTAGRAM_SOLID}
            link={INSTAGRAM_LINK}
          />
          <Col
            size={1}
            center
            ver
          >
            <DealMenuBorder />
          </Col>
          <DealPublicFooterIcon
            iconType={iconTypes.FACEBOOK}
            iconTypeHover={iconTypes.FACEBOOK_SOLID}
            link={FACEBOOK_LINK}
          />
          <Col
            size={1}
            center
            ver
          >
            <DealMenuBorder />
          </Col>
          <DealPublicFooterIcon
            iconType={iconTypes.TWITTER}
            iconTypeHover={iconTypes.TWITTER_SOLID}
            link={TWITTER_LINK}
          />
          <Col
            size={1}
            center
            ver
          >
            <DealMenuBorder />
          </Col>
          <DealPublicFooterIcon
            iconType={iconTypes.AT}
            iconTypeHover={iconTypes.AT}
            link={EMAIL_LINK}
          />
          <Col size={1} />
        </Row>
        <Row
          bgr
          center
        >
          <Col size={1} />
          <Col size={10}>
            <Panel>
              <Row ver>
                <Col
                  size={1}
                  ver
                />
                <Col
                  size={10}
                  ver
                  center
                >
                  <Link
                    to="/"
                    style={LINK_NO_STYLE}
                  >
                    <ScreenLinkTextStyle>
                      {switchLanguage(null, texts).lawInfo}
                    </ScreenLinkTextStyle>
                  </Link>
                </Col>
                <Col
                  size={2}
                  ver
                />
              </Row>
              <Row md>
                <Col size={1} />
                <a
                  href="http://eodcode.com"
                  style={LINK_NO_STYLE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Col
                    size={10}
                    ver
                    center
                  >
                    <EODText>{switchLanguage(locale, texts).eod}</EODText>
                    <TextDark>
                      {switchLanguage(locale, texts).rr}
                    </TextDark>
                    <Icon
                      height="16"
                      width="16"
                      iconType={iconTypes.COPYRIGHTS}
                      fill={GREY_DARK}
                    />
                  </Col>
                </a>
                <Col size={1} />
              </Row>
              <Row
                center
                bgr
              >
                <Col size={1} />
                <Col
                  size={10}
                  center
                >
                  <TextDark>
                    {switchLanguage(locale, texts).as}
                  </TextDark>
                </Col>
                <Col size={1} />
              </Row>
              <Row
                center
                bgr
              >
                <Col size={1} />
                <Col
                  size={10}
                  center
                >
                  <TextDark>
                    {switchLanguage(locale, texts).gp}
                  </TextDark>
                </Col>
                <Col size={1} />
              </Row>
            </Panel>
          </Col>
          <Col size={1} />
        </Row>
      </Container>
    </Row>
  );
};


export default (HomeFooterMobile);
