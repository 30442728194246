import React from 'react';
import styled from 'styled-components';
import { Row, Col } from '../../../../styles/grid';
import IntroShareCards from './ShareCards';
import Browser from '../IntroStyle/HomeBrowser';
import { WhiteContainer } from '../../../../styles';

const Share = () => (
  <WhiteContainer>
    <Row
      center
    >
      <Container>
        <Col size={1} />
        <Col
          size={5}
          center
        >
          <IntroShareCards />
        </Col>
        <Col
          size={5}
          center
        >
          <Browser />
        </Col>
        <Col size={1} />
      </Container>
    </Row>
  </WhiteContainer>
);

export default Share;


const Container = styled.div`
   width: 1444px;
   display: flex;
   @media only screen and (max-width: 1200px){
     width: 100%;
  }
`;
