/* eslint-disable max-len */
export const SHADOW_GREY = 'rgba(190,190,206,0.50)';
export const SHADOW_GREY_DARK = 'rgba(101,101,136,0.50)';
export const SHADOW_GREY_DRAWER = 'rgba(190,190,207,0.2)';
export const SHADOW_GREY_PHONE_ICON = 'rgba(101, 101, 136, 0.37)';


export const shadowCardAvatarWrapper = (color: string) => `box-shadow: 0 10px 10px 0 ${color};`;
export const shadowCard = (color: string) => `box-shadow: 0 10px 40px 0 ${color};`;
export const shadowButton = (color: string) => `box-shadow: 0 10px 20px 0 ${color};`;
export const shadowButtonRound = (color: string) => `box-shadow: 0 -4px 40px 0 ${color};`;
export const shadowDrawer = (color: string) => `box-shadow: 0 8px 10px 0 ${color};`;
export const shadowNavAvatar = (color: string) => `box-shadow: 0 0px 20px 0 ${color};`;
