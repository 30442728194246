import React, { FC } from 'react';
import styled from 'styled-components';
import convertToDate from '../../../../../hoc/convertToDate';

import {
  CardTitleDescriptionTextStyle,
  Col,
  DealCardContainerStyle,
  GREY_MEDIUM,
  Row,
  TEXT_CARD_TITLE,
  TEXT_CARD_TITLE_LIGHT,
  TEXT_DEALNAME,
} from '../../../../../styles';
import DealCardPhoto from '../../../../UI/Cards/DealCard/DealCardPhoto';

type Props = {
  title: string;
  titleLight: string;
  dateDue?: Date|null;
  dealname: string;
  challengePhoto?: any;
  titleCenter?: boolean;
}

const ChallengeCard: FC <Props> = ({
  title,
  titleLight,
  dateDue,
  dealname,
  challengePhoto,
  children,
  titleCenter,
}) => (
  <Row
    center
  >
    <Col
      size={4}
      vEnd
    />
    <Col size={4}>
      <DealCardContainerStyle
        shadow
        drawer
        style={{
          minWidth: '344px',
        }}
      >
        <Row
          center
          md
        >
          <TitleCard>
            {title}
          </TitleCard>
          <TitleCardLight>
            {`   ${titleLight}`}
          </TitleCardLight>
        </Row>
        <Row
          center
        >
          <CardTitleDescriptionTextStyle>
            {`Due date:  ${convertToDate(dateDue)}` || "wasn't set"}
          </CardTitleDescriptionTextStyle>
        </Row>
        <Row
          md
          mdB
        >
          <Col size={1} />
          <Col
            size={10}
            center={titleCenter}
          >
            <DealNameText>
              {dealname}
            </DealNameText>
          </Col>
          <Col size={1} />

        </Row>
        {challengePhoto
          ? (
            <DealCardPhoto
              photoUri={challengePhoto}
              modalDisabled
            />
          ) : null}
        {children}
        <Row md />
      </DealCardContainerStyle>
    </Col>
    <Col
      size={4}
    />
  </Row>
);


export default ChallengeCard;

const TitleCard = styled.div`
      ${TEXT_CARD_TITLE}
`;

const TitleCardLight = styled.div`

      ${TEXT_CARD_TITLE_LIGHT}
      padding-left:3px;
`;

const DealNameText = styled.div`
  ${TEXT_DEALNAME}
  color: ${GREY_MEDIUM};
  display: flex;
  justify-content: center;
`;
