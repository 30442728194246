import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Language from '../../../contexts/Language';
import switchLanguage from '../../../hoc/switchLanguage';
import {
  ButtonEmpty, GREY, GREY_DARK, Row,
} from '../../../styles';
import * as iconTypes from '../../../styles/iconTypes';
import NavMenu from '../../Nav/Public/Home/NavMenu';
import MenuItem from './MenuItemLocal';


type Props = {
  userId: string;
  onSignOut: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const texts = {
  en: {
    logout: 'Logout',
    premium: 'premium',
    privacy: 'privacy',
    features: 'features',
    dealIdeas: 'Challenge Contract Ideas',
  },
};


const HomeMenuMobile: React.FunctionComponent<Props> = ({ onSignOut, userId }) => {
  const [onStart, setonStart] = useState(false);
  const locale = useContext(Language);
  useEffect(() => {
    setonStart(true);
  }, []);

  return (
    <ContainerFixed
      onStart={onStart}
    >
      <NavMenu />
      <MenuItem
        lg
        title={switchLanguage(locale, texts).features}
        iconType={iconTypes.GEAR}
        link="#intro"
        offset="48"
      />
      <MenuItem
        md
        title={switchLanguage(locale, texts).premium}
        iconType={iconTypes.DIAMOND}
        link="#premium"
      />
      <MenuItem
        md
        title={switchLanguage(locale, texts).dealIdeas}
        iconType={iconTypes.BULB}
        link="/ideas"
        toPublic
      />
      <MenuItem
        md
        title={switchLanguage(locale, texts).privacy}
        iconType={iconTypes.LOCK}
        link="/privacy"
        toPublic
      />
      {userId
        ? (
          <Row
            lg
            center
          >
            <ButtonEmpty
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onClick={onSignOut}
            >
              {switchLanguage(locale, texts).logout}
            </ButtonEmpty>
          </Row>
        ) : null}
      <Row
        lg
        center
      >
        <Border />
      </Row>

    </ContainerFixed>
  );
};


const mapStateToProps = (state: any) => ({
  userId: state.user.userId,
});


export default connect(mapStateToProps,
  {
  })(HomeMenuMobile);


export const ContainerFixed = styled.div<{onStart: boolean}>`
    position: fixed;
    background: ${GREY_DARK};
    right: 0;
    top: 0;
    bottom:0;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1;
    opacity: 0.9;
    width: 100vw;
    transform: translateY(${props => (props.onStart ? '0' : '100vw')});
    transition: all 300ms ease;
    z-index:12;
`;


const Border = styled.div`
   width:16px;
   height:1px;
   border-top: 1px ${GREY} dashed;
`;
