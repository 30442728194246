import * as actionTypes from '../actions/actionTypes';
import Login from '../../interfaces/loginTypes';

const initialState = {
  email: '',
  password: '',
  loading: false,
  success: false,
  error: false,
  errorMessage: '',
};

function reducer(state: Login = initialState, action: any) {
  switch (action.type) {
    case actionTypes.LOG_IN_EMAIL_CHANGED:
      return {
        ...state,
        email: action.payload,
        error: false,
        errorMessage: '',
      };
    case actionTypes.LOG_IN_PASSWORD_CHANGED:
      return {
        ...state,
        password: action.payload,
        error: false,
        errorMessage: '',
      };
    case actionTypes.LOG_IN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOG_IN_RESET:
      return {
        ...state,
        email: '',
        password: '',
        error: false,
        success: false,
        errorMessage: '',
      };
    case actionTypes.LOG_IN_USER_SUCCESS:
      return {
        ...state,
        email: '',
        password: '',
        error: false,
        success: true,
        loading: false,
        errorMessage: '',
      };
    case actionTypes.LOG_IN_FAILED:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        loading: false,
      };
    case actionTypes.LOG_IN_FAILED_OFF:
      return {
        ...state,
        error: false,
        errorMessage: '',
      };
    default:
      return state;
  }
}

export default reducer;
