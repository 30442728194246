import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useWindowSize from '@rehooks/window-size';
import Clipboard from 'clipboard';

import switchLanguage from '../../hoc/switchLanguage';
import {
  Col,
  Row,
  SHADOW_GREY,
  GREY_WHITE,
  ORANGE,
  GREY_LIGHT,
  GREY_DARK,
  DIST_MEDIUM,
  TEXT_CARD_SMALL,
  shadowCard,
  TEXT_CARD_USER_DECISION,
} from '../../styles/index';
import * as iconTypes from '../../styles/iconTypes';
import Icon from '../../styles/Icon';
import AnimOpacity from '../../hoc/AnimOpacity';
import { WWW } from '../../env.json';

const texts = {
  en: {
    copy: 'Link copied to clipboard',
    button1: 'done',
    button2: 'click link to share',
  },
};

interface IDealItemShareProps {
  dealId: string;
  isShareVisible: boolean;
}

const DealItemShare: React.FunctionComponent<IDealItemShareProps> = ({
  isShareVisible,
  dealId,
}) => {
  const [linkWasCopied, setLinkWasCopied] = useState<boolean>(false);

  const windowSize = useWindowSize();

  const { innerWidth } = windowSize;

  const linkLength = innerWidth < 400 ? 26 : 38;

  useEffect(() => {
    const clipboard = new Clipboard('.linkbutton');
    clipboard.on('success', (e: any) => {
      setLinkWasCopied(true);
      e.clearSelection();
      setTimeout(() => setLinkWasCopied(false), 1000);
    });
    return () => clipboard.destroy();
  }, []);

  const linkEnv = `${WWW}/deal/${dealId}`;

  const formattedLink = `${linkEnv.slice(0, linkLength)}...`;

  return (
    <AnimOpacity
      animOn={isShareVisible}
    >
      <Row>
        <Col size={1} />
        <Col
          size={10}
          className="linkbutton"
          data-clipboard-text={linkEnv}
          center
        >
          <LinkCardRow
            linkWasCopied={linkWasCopied}
          >
            <div>
              {linkWasCopied
                ? switchLanguage(null, texts).copy
                : formattedLink}
            </div>
            <Icon
              height="16"
              width="16"
              iconType={iconTypes.LINK}
              fill={linkWasCopied ? ORANGE : GREY_LIGHT}
            />
          </LinkCardRow>
        </Col>
        <Col size={1} />
      </Row>
      <BottomRow
        center
        md
      >
        <UserDecisionText
          className="linkbutton"
          data-clipboard-text={linkEnv}
        >
          {linkWasCopied
            ? switchLanguage(null, texts).button1
            : switchLanguage(null, texts).button2}
        </UserDecisionText>
      </BottomRow>
    </AnimOpacity>
  );
};

export default DealItemShare;


const LinkCardRow = styled.div<{linkWasCopied: boolean}>`
    justify-content: space-between;
    ${TEXT_CARD_SMALL}
    padding: 20px;
    ${shadowCard(SHADOW_GREY)};
    background: white;
    align-content: center;
    border-radius: 5px;
    cursor: pointer;
    color: ${(props) => (props.linkWasCopied
    ? ORANGE : GREY_WHITE)};
    fill: ${GREY_LIGHT};
    display: flex;
    width:100%;
    :hover{
    color:${ORANGE} ;
    fill: ${ORANGE}
    }
`;

const UserDecisionText = styled.div`
    text-align: center;
    color: ${GREY_LIGHT};
    ${TEXT_CARD_USER_DECISION}
    cursor: pointer;
    :hover{
    color:${GREY_DARK} ;
    }
`;

const BottomRow = styled(Row)`
    padding-bottom: ${DIST_MEDIUM};
`;
