export const INSTAGRAM_LINK = 'https://www.instagram.com/dealemapp/';
export const FACEBOOK_LINK = 'https://www.facebook.com/dealemapp';
export const TWITTER_LINK = 'https://twitter.com/dealemapp';
export const EMAIL_LINK = 'mailto:contact@eodcode.com';
export const VIDEO_LINK = 'https://firebasestorage.googleapis.com/v0/b/devdealem.appspot.com/o/Dealem%20IG%20Final_5.mp4?alt=media&token=002d6979-8822-46b6-ae46-d645995df94e';
export const GET_DEALEM_LINK = 'https://dealem.page.link/VG8L';
export const GOOGLE_PRIVACY = 'https://www.google.com/policies/privacy/';
export const ADMOB_PRIVACY = 'https://support.google.com/admob/answer/6128543?hl=en';
export const ANALYTICS_PRIVACY = 'https://firebase.google.com/policies/analytics';
export const CRASHLYTYCS_PRIVACY = 'https://firebase.google.com/support/privacy/';
export const MAILCHIMP_PRIVACY = 'https://mailchimp.com/legal/privacy/';
export const FACEBOOK_PRIVACY = 'https://www.facebook.com/about/privacy/';
export const APPLE_PRIVACY = 'https://www.apple.com/legal/privacy/';
export const APPLE_TERMS = 'https://www.apple.com/legal/internet-services/icloud/';
export const GOOGLE_TERMS = 'https://policies.google.com/terms';
export const FACEBOOK_TERMS = 'https://www.facebook.com/terms.php';
