import React from 'react';
import styled from 'styled-components';
import {
  Col, GREY_MEDIUM, Row, TEXT_DEALNAME,
} from '../../../../styles/index';


export interface IdealCardDealnameProps {
  dealname: string;
}

const DealCardDealname = ({ dealname }: IdealCardDealnameProps) => (
  <Row
    sm
    center
  >
    <Col size={1} />
    <Col size={10}>
      <DealNameText>
        {dealname}
      </DealNameText>
    </Col>
    <Col size={1} />
  </Row>
);

export default DealCardDealname;


const DealNameText = styled.div`
  ${TEXT_DEALNAME}
  color: ${GREY_MEDIUM};
  align-items: center;
  display: flex;
  justify-content: center;
`;
