import useWindowSize from '@rehooks/window-size';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  ORANGE, Col, FONT_SIZE_X_SMALL, Row,
} from '../../../../styles';

const IdeaSurprize: FC <{
  surprize?: boolean;
  surprizePenalty?: boolean;
}> = ({
  surprize,
  surprizePenalty,
}) => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  return (
    <Row
      sm
      center
    >
      <Col
        size={5}
        center
      >
        {surprize
          ? (
            <Badge>
              IT WILL BE A SURPRISE
            </Badge>
          ) : null}
      </Col>
      {innerWidth > 400
        ? (
          <Col
            size={2}
            center
          />
        ) : null}
      <Col
        size={5}
        center
      >
        {surprizePenalty
          ? (
            <Badge>
              IT WILL BE A SURPRISE
            </Badge>
          ) : null}
      </Col>
    </Row>
  );
};


export default IdeaSurprize;

export const Badge = styled.div`
   padding: 2px 8px;
   background: ${ORANGE};
   font-size: ${FONT_SIZE_X_SMALL};
   font-weight: 700;
   color: white;
   text-align: center;
   margin-bottom: -7px;
   border-radius: 8px;
   z-index: 2;
`;
