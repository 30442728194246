import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';
import { Col, GREY, GREY_MEDIUM } from '../../../../styles/index';

type Props = {
  isContentVisible: boolean;
  isBarHover: boolean;
}


const DealItemDrawerChevron: React.FunctionComponent<Props> = ({
  isContentVisible,
  isBarHover,
}) => {
  const [hasChanged, setChanged] = useState(false);
  const [anim, startAnim] = useState<boolean|null>(null);


  // useful staring open drawer
  useEffect(() => {
    if (isContentVisible) {
      setChanged(true);
    }
    return () => {
      // setChanged(false);
      startAnim(null);
    };
  }, [isContentVisible]);


  useEffect(() => {
    if (hasChanged && isContentVisible) {
      startAnim(true);
    }
    if (hasChanged && !isContentVisible) {
      startAnim(false);
    }
  }, [hasChanged, isContentVisible]);

  return (
    <Col
      size={3}
      ver
    >
      <AnimRotate
        className={anim ? 'rotate180' : ''}
      >
        <Icon
          height="20"
          width="20"
          iconType={iconTypes.CHEVRON_DOWN}
          fill={isBarHover ? GREY_MEDIUM : GREY}
        />
      </AnimRotate>
    </Col>
  );
};

export default DealItemDrawerChevron;

const AnimRotate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.5s;
  transition-timing-function: linear;
  transition-property: transform;
`;
