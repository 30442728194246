import React, { FC } from 'react';

import MediaDesc from './MediaDesc';
import MediaTitle from './MediaTitile';
import DealCardMedia from './DealCardMedia';


const MediaDrawer: FC<{
  title: string;
  videoUri: string;
  photoUri: string;
  description: any;
}> = ({
  title,
  videoUri,
  photoUri,
  description,
}) => (
  <>
    <DealCardMedia
      videoUri={videoUri}
      photoUri={photoUri}
    />
    <MediaTitle title={title} />
    <MediaDesc
      description={description}
    />
  </>
);

export default MediaDrawer;
