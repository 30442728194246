import useWindowSize from '@rehooks/window-size';
import _ from 'lodash';
import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { Row } from '../../../styles';
import Icon from '../../../styles/Icon';
import { generateIconRotate } from './HomeIcons';


type Props = {
  generateIconsNames: Function;
  numberOfIcons: number;
  iconColor: string;
  backgroundColor: string;
  width?: number;
  bordersRadius?: boolean;
  id: string;
}

const HomeRowIcons: FC<Props> = ({
  generateIconsNames,
  numberOfIcons,
  iconColor,
  backgroundColor,
  width,
  bordersRadius,
  id,
}) => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;


  const renderIcons = () => {
    const array = [];
    let i;
    let j;
    if (width) {
      j = width / 30;
    } else {
      j = innerWidth / 30;
    }
    // array.push(<IconColumn
    //   style={{ width: '1px' }}
    // />);
    for (i = 0; i < j; i++) {
      if (i && (i % 2 === 0)) {
        array.push(
          <IconColumn>
            <Icon
              height="22"
              width="22"
              iconType={generateIconsNames(_.random(1, numberOfIcons))}
              fill={iconColor}
              style={{
                transform: `rotate(${generateIconRotate(_.random(1, 4))})`,
                marginBottom: '12px',
              }}
            />
            <IconPadding />
          </IconColumn>,
        );
      } else {
        array.push(
          <IconColumn>
            <Icon
              height="22"
              width="22"
              iconType={generateIconsNames(_.random(1, numberOfIcons))}
              fill={iconColor}
              style={{
                transform: `rotate(${generateIconRotate(_.random(1, 4))})`,
              }}
            />
            <IconPadding />
          </IconColumn>,
        );
      }
    }
    return array;
  };


  return (
    <>
      <IconRow
        background={backgroundColor}
        width={width}
        borderRadiusTop={bordersRadius}
        id={id}
      >
        {renderIcons()}
      </IconRow>
      <IconRow
        background={backgroundColor}
        width={width}
        borderRadiusBottom={bordersRadius}
      >
        {renderIcons()}
      </IconRow>
    </>
  );
};


export default memo(HomeRowIcons);


const IconRow = styled(Row)<{background: string; width?: number; borderRadiusTop?: boolean; borderRadiusBottom?: boolean}>`
  background: ${(props) => props.background};
  width: ${(props) => (`${props.width}px`)};
  border-top-left-radius:${(props) => (props.borderRadiusTop ? '5px' : 0)};
  border-top-right-radius:${(props) => (props.borderRadiusTop ? '5px' : 0)};
  border-bottom-right-radius:${(props) => (props.borderRadiusBottom ? '5px' : 0)};
  border-bottom-left-radius:${(props) => (props.borderRadiusBottom ? '5px' : 0)};
`;

const IconColumn = styled.div<{bottom?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.bottom ? 'flex-start' : 'flex-end')};
  overflow: hidden;
`;

const IconPadding = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
`;
