import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {
  Col, GREY_LIGHT, Row, TEXT_CARD_SMALL,
} from '../../../../styles';
import ImageCard from '../../Image/ImageCard';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: '5px',
    borderColor: 'white',
    transform: 'translate(-50%, -50%)',
    zIndex: '10',
    padding: '12px',
    boxShadow: '0 10px 40px 0 rgba(190,190,207,0.50)',
  },
};


interface IDealCardPhotoProps {
  title?: string;
  photoUri: string;
  modalDisabled?: boolean;
}

const ExamplePhoto: React.FunctionComponent<IDealCardPhotoProps> = ({
  title,
  photoUri,
  modalDisabled,
}) => {
  const [isModalOpen, openModal] = useState(false);

  const renderTitle = () => {
    if (title) {
      return (
        <Row
          ver
          center
          sm
        >
          <PictureText>
            {title}
          </PictureText>
        </Row>
      );
    } return <Row />;
  };

  const renderModal = () => (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => openModal(false)}
      // @ts-ignore
      style={modalStyle}
    >
      <ModalImage>
        <ImageCard
          image={photoUri}
          modalDisabled={modalDisabled}
        />
      </ModalImage>
    </Modal>
  );


  const renderImage = () => (
    <Row
      md
    >
      <Col size={1} />
      <Col
        size={10}
        center
      >
        <ImageContainer
          onClick={modalDisabled ? () => null : () => openModal(true)}
        >
          <ImageCard
            image={photoUri}
            modalDisabled={modalDisabled}
          />
        </ImageContainer>
      </Col>
      <Col size={1} />
    </Row>
  );
  return (
    <>
      {renderModal()}
      {renderImage()}
      {renderTitle()}
    </>
  );
};

export default ExamplePhoto;


const PictureText = styled.div`
    text-align: center;
    ${TEXT_CARD_SMALL}
    color:${GREY_LIGHT}
`;


const ImageContainer = styled.div`
   align-items: center;
   justify-content: center;
   @media only screen and (max-width: 400px){
    height: 206px;
  }
  height: 276px;
  /* flex: 1; */
  width: 100%;
`;

const ModalImage = styled.div`
    max-width: 860px;
    max-height: 860px;
    @media only screen and (max-width: 767px){
     width: 300px;
    }
`;
