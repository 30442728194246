import React from 'react';
import styled from 'styled-components';

import {
  ORANGE, Col, GREY_DARK, Row, TEXT_CARD_TITLE,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';

const IdeaLinesDeal = () => (
  <Row
    style={{ marginTop: '6px' }}
    center
  >
    <Col size={1} />
    <Col
      size={2}
      center
    >
      <Icon
        height="16"
        width="16"
        iconType={iconTypes.ARROW_DOWN_DECOR}
        fill={GREY_DARK}
      />

    </Col>
    <Col
      size={4}
      center
    >
      <DealTitle>
        CONTRACT
      </DealTitle>
    </Col>
    <Col
      size={2}
      center
    >
      <Icon
        height="16"
        width="16"
        iconType={iconTypes.ARROW_DOWN_DECOR}
        fill={ORANGE}
      />
    </Col>
    <Col size={1} />
  </Row>
);

export default IdeaLinesDeal;

const DealTitle = styled.div`
  ${TEXT_CARD_TITLE}
  font-size:24px;
`;
