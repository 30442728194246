import React from 'react';
import styled from 'styled-components';
import {
  GREY, rotate, rotateBack, TEXT_DEALNAME, GREY_DARK, SHADOW_GREY,
} from '../styles';
import Icon from '../styles/Icon';
import * as iconTypes from '../styles/iconTypes';
import NavAppName from '../components/Nav/Public/NavAppName';

const Loading = () => (
  <>
    <ContainerBorderOuter>
      <OuterBorder />
    </ContainerBorderOuter>
    <ContainerBorder>
      <InnerBorder />
    </ContainerBorder>
    <Container>
      <Circle>
        <Icon
          height="12"
          width="12"
          iconType={iconTypes.REFRESH}
          fill={GREY_DARK}
        />
      </Circle>
    </Container>
    <Deals>
      <NavAppName />
    </Deals>
  </>
);

export default Loading;


const InnerBorder = styled.div`
  animation: ${rotate} 8s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  border: 0.5px dashed ${GREY_DARK};
  z-index: 10;
`;

const OuterBorder = styled.div`
  animation: ${rotateBack} 4s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 0.5px dashed ${GREY_DARK};
`;

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
`;

const ContainerBorder = styled(Container)`
 z-index: 20;
`;

const ContainerBorderOuter = styled(Container)`
 z-index: 30;
`;

const Deals = styled.div`
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translateX(-50%) translateY(-55%);
    ${TEXT_DEALNAME}
    font-size:14;
    color: ${GREY};
    @media only screen and (max-height: 612px) {
      display: none;
    }
`;

const Circle = styled.div`
    display: flex;
    width: 45px;
    height: 45px;
    border-radius: 22.5px;
    background: white;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0px 20px 0 ${SHADOW_GREY};
    animation: ${rotate} 2s linear infinite;
`;
