import useWindowScrollPosition from '@rehooks/window-scroll-position';
import React, {
  FC, ReactNode, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  Col, Row, GREY_DARK, GreyContainer,
} from '../../../../styles';
import Hook from './Hook';
import phoneDeal from '../../../../assets/phoneDeal.png';
import phoneFeatures from '../../../../assets/phoneFeatures.png';

type Props ={
  positionY: number;
  children: ReactNode;
  features?: boolean;
}

const PhoneRow: FC <Props> = ({
  positionY,
  children,
  features,
}) => {
  const [isAnimOn, toggleAnim] = useState(false);
  const [isOpacityOn, turnOpacityOff] = useState(true);
  const position = useWindowScrollPosition();
  useEffect(() => {
    if (position.y > positionY) {
      toggleAnim(true);
      setTimeout(() => {
        turnOpacityOff(false);
      }, 1000);
    }
  }, [position, positionY]);
  return (
    <GreyContainer
      padTop="12px"
    >
      <RailRow>
        <Rail
          isOpacityOn={isOpacityOn}
        />
      </RailRow>
      <Row
        center
      >
        <Container>
          <Col size={1} />
          <Col
            size={5}
            center
          >
            <Hook
              isAnimOn={isAnimOn}
              src={features ? phoneFeatures : phoneDeal}
            />
          </Col>
          <Col
            size={5}
            center
          >
            {children}
          </Col>
          <Col size={1} />
        </Container>
      </Row>
    </GreyContainer>
  );
};

export default PhoneRow;

const RailRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 58px;
  margin-bottom: -64px;
`;


const Container = styled.div`
   width: 1444px;
   display: flex;
   @media only screen and (max-width: 1200px){
     width: 100%;
  }
`;

const Rail = styled.div <{ isOpacityOn: boolean }>`
    border-bottom: 1px dashed ${GREY_DARK};
    border-left: 1px dashed ${GREY_DARK};
    border-right: 1px dashed ${GREY_DARK};
    border-top: 1px dashed ${GREY_DARK};
    padding: 2px;
    transition: width 1.5s linear;
    height:19px;
    width: 300px;
    box-shadow: inset 0 0 4px rgba(101,101,136,0.50);
    margin-right: 848px;
    transition: opacity 1s ease-out;
    opacity: ${(props) => (props.isOpacityOn ? 1 : 0)};
    @media only screen and (max-width: 1200px){
      margin-right: 70%;
      width: 35%;
      margin-left: -2px;
   }
`;
