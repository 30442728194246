import React, {
  FC, memo, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  GREY_DARK, shadowNavAvatar, SHADOW_GREY_DARK, WHITE_DARK,
} from '../../../styles';
import Icon from '../../../styles/Icon';
import * as iconTypes from '../../../styles/iconTypes';

type Props = {
  marginBottom?: string;
  marginTop?: string;
  marginRight?: string;
  isAnimOn?: boolean;
}

const HomeCircleGear: FC<Props> = ({
  marginBottom,
  marginTop,
  marginRight,
  isAnimOn,
}) => {
  const [isPinVisible, setPinVisible] = useState(false);
  const [isOpacityOff, setOpacityOff] = useState(false);
  useEffect(() => {
    if (isAnimOn) {
      setTimeout(() => {
        setOpacityOff(true);
      }, 1500);
      setTimeout(() => {
        setPinVisible(true);
      }, 1700);
      setTimeout(() => {
        setOpacityOff(false);
      }, 1900);
    }
  }, [isAnimOn]);
  return (
    <Circle
      marginBottom={marginBottom}
      marginTop={marginTop}
      marginRight={marginRight}
    >
      <IconWrapper
        className={isAnimOn ? 'rotate-wrapper' : ''}
      >
        <IconAnim
          className={isAnimOn ? 'rotate-icon' : ''}
          isOpacityOff={isOpacityOff}
        >
          <Icon
            height="22"
            width="22"
            iconType={isPinVisible ? iconTypes.PIN : iconTypes.GEAR}
            fill={GREY_DARK}
          />
        </IconAnim>
      </IconWrapper>
    </Circle>
  );
};


export default memo(HomeCircleGear);

const Circle = styled.div<{marginBottom?: string; marginTop?: string; marginRight?: string}>`
   
    display: flex;
    width: 58px;
    height: 58px;
    border-radius: 29px;
    justify-content: center;
    align-items: center;
    background: ${WHITE_DARK};
  ${shadowNavAvatar(SHADOW_GREY_DARK)};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
    margin-top: ${(props) => (props.marginTop ? props.marginBottom : 0)};
    margin-right: ${(props) => (props.marginRight ? props.marginBottom : 0)};
    z-index:2;
`;

const IconAnim = styled.div<{isOpacityOff: boolean}>`
   justify-content: center;
    align-items: center;
    display: flex;
    transition: opacity 0.2s linear;
    opacity: ${(props) => (props.isOpacityOff ? 0 : 1)}
`;

const IconWrapper = styled.div`
    width: 52px;
    height: 52px;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: dashed; 
    border-color: ${GREY_DARK};
    display: flex;
`;
