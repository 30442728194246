import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';

import Language from '../../contexts/Language';
import firebase from '../../firebase';
import switchLanguage from '../../hoc/switchLanguage';
import { DealId } from '../../interfaces/dealTypes';
import {
  ORANGE,
  ButtonEmpty,
  ERROR_COLOR,
  FONT_SIZE_MEDIUM,
  GREY,
  GREY_LIGHT,
  GREY_MEDIUM,
  LETTER_SPACING_NEG,
  Row,
} from '../../styles';

export const texts = {
  en: {
    button: 'Remove comment ?',
    error: 'error occurred',
    success: 'comment removed',
  },
};

type Props = {
  dealId: DealId;
  commentId: string;
  closeModal: () => any;
}

const DealDeleteComment: FC <Props> = ({
  dealId,
  commentId,
  closeModal,
}) => {
  const locale = useContext(Language);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleCommentRemove = () => {
    firebase.firestore().doc(`deals/${dealId}/comments/${commentId}`)
      .delete()
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          closeModal();
        }, 1500);
      })
      .catch(() => {
        setError(true);
        setTimeout(() => {
          setError(false);
          closeModal();
        }, 1500);
      });
  };

  const buttonText = success ? switchLanguage(locale, texts).succes
    : switchLanguage(locale, texts).button;

  return (
    <ModalImage>
      <Row center>
        <Button
          error={error}
          disabled={error || success}
          onClick={handleCommentRemove}
        >
          {error ? switchLanguage(locale, texts).error
            : buttonText }
        </Button>
      </Row>
      <Row
        center
        md
      >
        <Line />
      </Row>
    </ModalImage>
  );
};

export default DealDeleteComment;

const ModalImage = styled.div`
    border-radius: 5px;
    padding: 24px;
    @media only screen and (max-width: 400px){
     width: 100%;
    }
    width: 360px;
`;

const Line = styled.div`
    border-top: 1px dashed ${GREY};
    width: 24px;
`;

export const FormIconLinkStyle = styled.span`
    font-size: ${FONT_SIZE_MEDIUM};
    color: ${GREY_MEDIUM};
    letter-spacing: ${LETTER_SPACING_NEG};
  `;


const Button = styled(ButtonEmpty)<{error: boolean}>`
    border-color:${(props) => (props.error ? ERROR_COLOR : GREY_LIGHT)}; 
    color:${(props) => (props.error ? ERROR_COLOR : GREY_MEDIUM)}; 
    :hover{
    color: ${(props) => (props.error ? ERROR_COLOR : ORANGE)};
    border-color: ${(props) => (props.error ? ERROR_COLOR : ORANGE)};
  }
`;
