import React, { useState } from 'react';
import styled from 'styled-components';
import { GREY, GREY_MEDIUM, Col } from '../../../../styles/index';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';


interface IdealMenuButtonComments {
  numberOfComments: number;
  isVisible: boolean;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const DealMenuButtonComments = (props: IdealMenuButtonComments) => {
  const { onClick, isVisible, numberOfComments } = props;
  const [isHover, onHover] = useState(false);
  return (
    <CommentsIconWrapper
      size={2}
      ver
      center
      cursor
      onClick={onClick}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <Icon
        height="20"
        width="20"
        iconType={iconTypes.SPEECH_BUBBLE}
        fill={isVisible || isHover ? GREY_MEDIUM : GREY}
        hoverFill={GREY_MEDIUM}
      />
      <NumberOfComments
        isHover={isHover}
        isVisible={isVisible}
      >
        {numberOfComments}
      </NumberOfComments>
    </CommentsIconWrapper>
  );
};

export default DealMenuButtonComments;

const IconWrapperMenu = styled(Col)`
   padding-left: 14px;
`;

const CommentsIconWrapper = styled(IconWrapperMenu)`
  padding-left: 14px;
`;

const NumberOfComments = styled.span<{isVisible: boolean; isHover: boolean}>`
  color: ${props => (props.isVisible || props.isHover ? GREY_MEDIUM : GREY)};
  margin-left: 4px;
  font-weight: 400;
  font-size: 14px;
`;
