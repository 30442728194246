import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import pod3 from '../../../../assets/3pod.svg';
import { Row, SHADOW_GREY_DARK, VIDEO_LINK } from '../../../../styles';

const IntroVideo = () => (
  <>
    <Row
      center
      sm
    >
      <ModalImage>
        <ReactPlayer
          url={VIDEO_LINK}
          playing={false}
          controls
          width="424px"
          height="424px"
          volume={0.6}
        />
      </ModalImage>
    </Row>
    <Row
      center
      sm
      style={{
        borderTop: '1px #17171c dashed',
      }}
    >

      <img
        alt=""
        src={pod3}
        height="680px"
        width="800px"
        style={{
          marginRight: 82,
          marginTop: 24,
          marginBottom: -194,
        }}
      />

    </Row>
  </>
);

export default IntroVideo;
const ModalImage = styled.div`
    padding: 12px;
    align-items: center;
    justify-content: center;
    display: flex;
    box-shadow:0 0 40px 0 ${SHADOW_GREY_DARK};
    z-index: 99;
    object-fit: cover;
    margin-bottom:-400px;
    background-color: white;
    margin-top: 24px;
`;
