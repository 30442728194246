
export const LINK_NO_STYLE = { textDecoration: 'none', color: 'inherit' };

export {
  ORANGE,
  ORANGE_HOVER,
  ERROR_COLOR,
  ERROR_COLOR_HOVER,
  GREY_WHITE,
  GREY_LIGHT,
  GREY,
  GREY_MEDIUM,
  GREY_DARK,
  WHITE_DARK,
  DASH_COLOR,
} from './colors';

export {
  DIST_X_X_X_SMALL,
  DIST_X_X_SMALL,
  DIST_X_SMALL,
  DIST_SMALL,
  DIST_MEDIUM,
  DIST_BIGGER,
  DIST_BIG,
  DIST_LARGE,
  CARD_CIRCLE_SIZE,
  CARD_CIRCLE_INNER_LINE,
  CARD_CIRCLE_MARGIN_NEGATIVE,
  CARD_DESKTOP_WIDTH,
  ROBOT_SIZE,
} from './sizes';

export {
  SHADOW_GREY,
  SHADOW_GREY_DARK,
  SHADOW_GREY_DRAWER,
  SHADOW_GREY_PHONE_ICON,
  shadowCardAvatarWrapper,
  shadowCard,
  shadowButton,
  shadowButtonRound,
  shadowDrawer,
  shadowNavAvatar,
} from './shadows';


export {
  FONT_SIZE_X_SMALL,
  FONT_SIZE_SMALL,
  FONT_SIZE_MEDIUM,
  FONT_SIZE_BIGGER,
  FONT_SIZE_BIG,
  FONT_SIZE_LARGE,
  FONT_HEAVY,
  FONT_BOLD,
  LETTER_SPACING_X_NEG,
  LETTER_SPACING_NEG,
  LETTER_SPACING_X_POS,
  TEXT_LOGO,
  TEXT_TITLE_SMALL,
  TEXT_TITLE_LIGHT,
  TEXT_DEALNAME,
  TEXT_CARD_USER_DECISION,
  TEXT_TITLE_INFOCARD,
  TEXT_CARD_SMALL,
  TEXT_CARD_TITLE,
  TEXT_CARD_TITLE_LIGHT,
  ScreenLinkTextStyle,
  CardTitleTextStyle,
  CardTitleLightTextStyle,
  CardTitleDescriptionTextStyle,
  FONT_EXAMPLE,
  FONT_EXAMPLE_MOBILE,
  H1,
  H2,
  H3,
  H4,
  FONT_FUTURA,
  FONT_FUTURA_BOLD,
} from './texts';

export {
  DealCardContainerStyle,
  CardAvatarWrapperStyle,
  CardAvatarWrapperWhiteStyle,
  CardAvatarCircleDashStyle,
  TEXT_AREA_FORMAT,
  REPORT_MODAL_STYLE,
  ThinContainer,
  GreyContainer,
  WhiteContainer,
} from './components';


export {
  Button,
  ButtonHeader,
  ButtonRound,
  ButtonEmpty,
  ButtonNoStyle,
} from './buttons';

export {
  Col,
  Row,
} from './grid';

export {
  rotate,
  rotateBack,
} from './keyframes';
export {
  INSTAGRAM_LINK,
  FACEBOOK_LINK,
  TWITTER_LINK,
  EMAIL_LINK,
  VIDEO_LINK,
  GET_DEALEM_LINK,
} from './links';
