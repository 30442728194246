import React from 'react';
import styled from 'styled-components';

import {
  ORANGE, Col, GREY_DARK, Row,
} from '../../../../styles';

const IdeaThinLines = () => (
  <Row
    center
    xsm
  >
    <Col size={17} />
    <Col
      size={2}
      jEnd
    >
      <LineDuo
        height="40px"
      />
    </Col>
    <Col size={17} />
  </Row>
);


export default IdeaThinLines;


const LineDuo = styled.div<{colorLine?: boolean; height: string}>`
  width: 1px;
  border-right: 1px ${ORANGE} dashed;
  border-left: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  min-height: ${(props) => props.height};
  height: auto;
  width: 100%;
`;
