/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import { Col, Row } from '../../../styles';
import ImageCard from '../Image/ImageCard';


const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: '5px',
    borderColor: 'white',
    transform: 'translate(-50%, -50%)',
    zIndex: '99',
    padding: '12px',
    boxShadow: '0 10px 40px 0 rgba(190,190,207,0.50)',
  },
};


interface Props {
  photoUri: string;
  modalDisabled?: boolean;
  videoUri?: string;
}

const DealCardMedia: React.FunctionComponent<Props> = ({
  photoUri,
  modalDisabled,
  videoUri,
}) => {
  const [isModalOpen, openModal] = useState(false);
  const renderModal = () => (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => openModal(false)}
      // @ts-ignore
      style={modalStyle}
    >
      {videoUri
        ? (
          <ModalImage>
            <ReactPlayer
              url={videoUri}
              playing
              controls
              width="100%"
              height="100%"
              volume={0.2}
            />
          </ModalImage>
        )
        : (
          <ModalImage>
            <ImageCard
              image={photoUri}
              modalDisabled={modalDisabled}
            />
          </ModalImage>
        )}
    </Modal>
  );
  const renderImage = () => (
    <>
      <Row
        md
      >
        <Col size={1} />
        <Col
          size={10}
          center
        >
          <ImageContainer
            onClick={modalDisabled ? () => null : () => openModal(true)}
          >
            {videoUri
              ? (
                <VideoWrapper
                  url={videoUri}
                  width="100%"
                  height="100%"
                />
              )
              : (
                <ImageCard
                  image={photoUri}
                  modalDisabled={modalDisabled}
                />
              )}
          </ImageContainer>
        </Col>
        <Col size={1} />
      </Row>
    </>
  );
  return (
    <>
      {renderImage()}
      {renderModal()}
    </>
  );
};


export default DealCardMedia;


const ImageContainer = styled.div`
   align-items: center;
   justify-content: center;
   @media only screen and (max-width: 400px){
    height: 160px;
  }
   @media only screen and (max-width: 340px){
    height: 144px;
  }
  height: 206px;
  /* flex: 1; */
  width: 100%;
`;

const ModalImage = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    max-width: 800px;
    height: 800px;
    @media only screen and (max-width: 767px){
     height: 100%;
     width: 300px;
    }
    z-index: 99;
    object-fit: cover;
`;

const VideoWrapper = styled(ReactPlayer)`
   align-items: center;
   justify-content: center;
   @media only screen and (max-width: 400px){
    height: 160px;
  }
   @media only screen and (max-width: 340px){
    height: 144px;
  }
  height: 206px;
  /* flex: 1; */
  width: 100%;
    border-radius: 5; 
    cursor: ${(props) => (props.modalDisabled ? null : 'pointer')};
    object-fit: cover;
`;
