import React, { FC } from 'react';
import styled from 'styled-components';

import { Col, Row } from '../../../../styles';
import ArrowsRow from '../../../Idea/IdeaItem/IdeaBody/ArrowsRow';
import ConsequencesTitle from '../../../Idea/IdeaItem/IdeaBody/ConsequencesTitle';
import IdeaBottomIcons from '../../../Idea/IdeaItem/IdeaBody/IdeaBottomIcons';
import ExampleChallenge from './ExampleChallenge';
import IdeaCurvedBottom from '../../../Idea/IdeaItem/IdeaBody/IdeaCurvedBottom';
import IdeaCurvedLines from '../../../Idea/IdeaItem/IdeaBody/IdeaCurvedLines';
import IdeaDealIcon from '../../../Idea/IdeaItem/IdeaBody/IdeaDealIcon';
import IdeaLine from '../../../Idea/IdeaItem/IdeaBody/IdeaLine';
import IdeaLinesDeal from '../../../Idea/IdeaItem/IdeaBody/IdeaLinesDeal';
import ExamplePersons from './ExamplePersons';
import IdeaPrizePenalty from '../../../Idea/IdeaItem/IdeaBody/IdeaPrizePenalty';
import IdeaSurprize from '../../../Idea/IdeaItem/IdeaBody/IdeaSurprize';
import IdeaThinLines from '../../../Idea/IdeaItem/IdeaBody/IdeaThinLines';
import IdeaTopArrows from '../../../Idea/IdeaItem/IdeaBody/IdeaTopArrows';

const ExampleBody: FC <{
  challengePhoto?: any;
  dateDue?: string;
  dealname: string;
  committedUsername: string;
  witnessUsername: string;
  witnessInitiator?: boolean;
  committedInitiator?: boolean;
  prize: string;
  penalty: string;
  surprize?: boolean;
  surprizePenalty?: boolean;
  titleCenter?: boolean;
}> = ({
  children,
  challengePhoto,
  dateDue,
  dealname,
  committedUsername,
  witnessUsername,
  witnessInitiator,
  committedInitiator,
  prize,
  penalty,
  surprize,
  surprizePenalty,
  titleCenter,
}) => (
  <Row
    center
  >
    <Container>
      <Row
        center
      >
        <IdeaTopArrows />
      </Row>
      <Row
        center
      >
        <Col
          size={4}
          vEnd
        />
        <Col size={4}>
          <ExampleChallenge
            title={`${committedUsername}'s`}
            titleLight="Challenge"
            dateDue={dateDue}
            challengePhoto={challengePhoto}
            dealname={dealname}
            titleCenter={titleCenter}
          >
            <>
              {children}
            </>
          </ExampleChallenge>
        </Col>
        <Col
          size={4}
        />
      </Row>
      <Row center>
        <ThinContainer>
          <IdeaLine
            lineHeight={30}
          />
          <ExamplePersons
            committedUsername={committedUsername}
            witnessUsername={witnessUsername}
            witnessInitiator={witnessInitiator}
            committedInitiator={committedInitiator}
          />
          <Row center>
            <ThinContainer>
              <IdeaDealIcon />
              <IdeaLinesDeal />
              <IdeaCurvedLines />
              <IdeaThinLines />
              <IdeaCurvedBottom />
              <IdeaBottomIcons />
              <ArrowsRow />
              <ConsequencesTitle />
              <IdeaPrizePenalty
                prize={prize}
                penalty={penalty}
              />
              <IdeaSurprize
                surprize={surprize}
                surprizePenalty={surprizePenalty}
              />
            </ThinContainer>
          </Row>
        </ThinContainer>
      </Row>
    </Container>
  </Row>
);


export default ExampleBody;


const Container = styled.div<{bgColor?: string}>`
    width: 1024px;
  `;

const ThinContainer = styled.div`
  @media only screen and (max-width: 400px){
     width: 100%;
  }
  width: 360px;
`;
