import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logo from '../../../assets/LogoDealem.svg';
import { GREY_DARK, LINK_NO_STYLE, TEXT_LOGO } from '../../../styles';


const NavAppName = () => (
  <>
    <LinkToTop
      to="/"
      style={LINK_NO_STYLE}
    >
      <img
        alt=""
        src={logo}
        height="58px"
        width="58px"
      />
      <AppName>
        Dealem
      </AppName>
    </LinkToTop>
  </>
);


export default NavAppName;

export const AppName = styled.span`
   ${TEXT_LOGO}
   color: ${GREY_DARK};
   margin-left: 8px;
`;

const LinkToTop = styled(Link)`
   display: flex;
   align-items: center;
`;
