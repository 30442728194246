import React from 'react';

type Props = {
  locale: null|string;
}


const Language = React.createContext<Props>({ locale: null });


export default Language;
