import React, { FC } from 'react';
import styled from 'styled-components';

import {
  Col,
  FONT_BOLD,
  FONT_SIZE_X_SMALL,
  GREY,
  GREY_DARK,
  LETTER_SPACING_NEG,
  Row,
  SHADOW_GREY_PHONE_ICON,
  shadowNavAvatar,
  TEXT_CARD_SMALL,
  ORANGE,
} from '../../../../styles';
import ImageCardAvatar from '../../../UI/Image/ImageCardAvatar';
import robot from '../../../../assets/robotFace.png';
import prof from '../../../../assets/profFace.png';

const ExamplePersons: FC<{
  committedUsername: string;
  witnessUsername: string;
  witnessInitiator?: boolean;
  committedInitiator?: boolean;
}> = ({
  committedUsername,
  witnessUsername,
  witnessInitiator,
  committedInitiator,
}) => (
  <Row
    center
  >
    <Col size={1} />
    <Col
      size={5}
      center
    >
      <ContainerStyle>
        <Row>
          <Col size={1}>
            <CircleSmall>
              <ImageCardAvatar
                image={prof}
              />
            </CircleSmall>
          </Col>
          <Col
            size={9}
            center
            xsm
          >
            <TextDark>
              {committedUsername}
            </TextDark>
          </Col>
          <Col size={2} />
        </Row>
        <Row>
          <Col size={1} />
          <Col
            size={10}
            center
          >
            <TextLight>
              challenged
            </TextLight>
          </Col>
          <Col size={1} />
        </Row>
        {committedInitiator
          ? (
            <Row
              xsm
            >
              <Col size={1} />
              <Col
                size={10}
                center
              >
                <Badge>
                  INITIATOR
                </Badge>
              </Col>
              <Col size={1} />
            </Row>
          ) : (
            <Row
              xsm
              smB
            >
              <Col size={1} />
              <Col
                size={10}
                center
              />
              <Col size={1} />
            </Row>
          )}
      </ContainerStyle>
    </Col>
    <Col size={2} />
    <Col
      size={5}
      center
    >
      <ContainerStyle>
        <Row>
          <Col size={1} />
          <Col
            size={10}
            center
            xsm
          >
            <TextDark>
              {witnessUsername}
            </TextDark>
          </Col>
          <Col size={1}>
            <CircleSmall
              right
            >
              <ImageCardAvatar
                image={robot}
              />
            </CircleSmall>
          </Col>
        </Row>
        <Row>
          <Col size={1} />
          <Col
            size={10}
            center
          >
            <TextLight>
              supervisor
            </TextLight>
          </Col>
          <Col size={1} />
        </Row>
        {witnessInitiator
          ? (
            <Row
              xsm
            >
              <Col size={1} />
              <Col
                size={10}
                center
              >
                <Badge>
                  INITIATOR
                </Badge>
              </Col>
              <Col size={1} />
            </Row>
          ) : (
            <Row
              xsm
              smB
            >
              <Col size={1} />
              <Col
                size={10}
                center
              />
              <Col size={1} />
            </Row>
          )}
      </ContainerStyle>
    </Col>
    <Col size={1} />
  </Row>

);

export default ExamplePersons;


export const ContainerStyle = styled.div<{shadow?: boolean; drawer?: boolean}>`
  width: 100%;
  background: white;
  box-shadow: 0 10px 40px 0 rgba(190,190,207,0.50);
  border-radius: 5px;
`;


const Badge = styled.div`
   /* width: 100%; */
   padding: 2px 8px;
   background: ${ORANGE};
   font-size: ${FONT_SIZE_X_SMALL};
   font-weight: 700;
   color: white;
   text-align: center;
   margin-bottom: -7px;
   border-radius: 8px;
   z-index: 2;
`;

const CircleSmall = styled.div<{right?: boolean}>`
    position:relative;
    margin-top: -14px; 
    margin-left: ${(props) => (props.right ? '0' : '-14px')};
    margin-right: ${(props) => (props.right ? '-14px' : '0')};
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    background: white;

    ${shadowNavAvatar(SHADOW_GREY_PHONE_ICON)}; 
    z-index:2;
`;

export const TextDark = styled.div`
  color: ${GREY_DARK};
  align-items: center;
  font-weight: ${FONT_BOLD};    
  ${TEXT_CARD_SMALL}
    align-items: center;
`;

export const TextLight = styled.div`
   font-size: ${FONT_SIZE_X_SMALL};
  letter-spacing: ${LETTER_SPACING_NEG};
    color: ${GREY};
    align-items: center;
    margin-top: 2px;
`;
