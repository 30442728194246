import useWindowSize from '@rehooks/window-size';
import React, { FC, memo } from 'react';
import styled from 'styled-components';

import {
  CardTitleDescriptionTextStyle,
  CardTitleLightTextStyle,
  CardTitleTextStyle,
  Col,
  GREY_DARK,
  GREY_MEDIUM,
  H2,
  Row,
  SHADOW_GREY_PHONE_ICON,
  shadowNavAvatar,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';


type Props ={
  title: string;
  titleLight: string;
  description: string;
  iconType: string;
  grey?: boolean;
  titleTagComponent?: any;
}

const IntroCard: FC<Props> = ({
  title,
  titleLight,
  description,
  iconType,
  grey,
  titleTagComponent = <H2 />,
}) => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  return (
    <UpperRow
      sm
      ver
    >
      <Col
        size={1}
      >
        <Circle
          grey={grey}
        >
          <IconWrapper>
            <Icon
              height="18"
              width="18"

              iconType={iconType}
              fill={GREY_DARK}
            />
          </IconWrapper>
        </Circle>
      </Col>
      <ContainerTitle
        size={innerWidth > 1024 ? 9 : 11}
        center
      >
        <UpperRowTitle
          component={titleTagComponent}
        >
          <Title>
            {title}
          </Title>
          <TitleLight>
            {titleLight}
          </TitleLight>
        </UpperRowTitle>
        <Description>
          {description}
        </Description>
      </ContainerTitle>
      {innerWidth > 1024 && <Col size={2} />}
    </UpperRow>
  );
};


export default memo(IntroCard);


const ContainerTitle = styled(Col)`
  flex-direction: column;
  padding-left: 12px;
`;

const UpperRowTitle = styled(
  ({ component, ...props }) => React.cloneElement(component, props),
)`
display: flex;
`;

const Title = styled(CardTitleTextStyle)`
  color: ${GREY_DARK};
  font-size: 17px;
`;
const TitleLight = styled(CardTitleLightTextStyle)`
  color:${GREY_DARK};
  font-size: 17px;
`;

const Description = styled(CardTitleDescriptionTextStyle)`
    color:${GREY_MEDIUM};
    font-size: 14px;
`;

const UpperRow = styled(Row)`
    padding-top: 4px;
    /* height: 72px; */
    /* border-left: 1px dashed white; */
`;


const Circle = styled.div<{grey?: boolean}>`
    display: flex;
    width: 38px;
    height: 38px;
    border-radius: 19px;
    justify-content: center;
    align-items: center;
    /* background:${GREY_MEDIUM};
    ${shadowNavAvatar(SHADOW_GREY_PHONE_ICON)} */
    background: ${(props) => (props.grey ? GREY_MEDIUM : 'WHITE_DARK')};
  ${((props) => (props.grey
    ? shadowNavAvatar(GREY_DARK) : shadowNavAvatar(SHADOW_GREY_PHONE_ICON)))}; 
    z-index:2;
`;


const IconWrapper = styled.div`
    width: 34px;
    height: 34px;
    border-radius: 17px;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: dashed; 
    border-color: ${GREY_DARK};
    display: flex;
`;
