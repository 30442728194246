import useWindowScrollPosition from '@rehooks/window-scroll-position';
import useWindowSize from '@rehooks/window-size';
import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  GREY_DARK, ORANGE, Row, shadowCard, SHADOW_GREY_DARK,
} from '../../../styles';
import Arrow from './Arrow';
import ArrowBottom from './ArrowBottom';


const Arrows = () => {
  const [isWaveAnimOn, turnOnWave] = useState(false);
  const [isArrowAnimOn, turnOnArrowAnim] = useState(false);
  const [iconRandomNumber, setIconRandomNumber] = useState(0);
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  const numberOfIcons = innerWidth / 104;
  const num = Math.floor(numberOfIcons);

  useEffect(() => {
    const createRandomNumber = setInterval(() => {
      const randomIcon = Math.floor(_.random(0, num));
      if (num === randomIcon && !isArrowAnimOn) {
        setIconRandomNumber(randomIcon + 1);
      }
      if (!isArrowAnimOn) {
        setIconRandomNumber(randomIcon);
      }
    }, 1000);
    if (isArrowAnimOn) {
      clearInterval(createRandomNumber);
    }
    return () => clearInterval(createRandomNumber);
  }, [num, isArrowAnimOn, innerWidth]);

  const position = useWindowScrollPosition();

  useEffect(() => {
    if (position.y > 280 && position.y < 600) {
      const animTime = (num * 50) + 250;
      turnOnArrowAnim(true);
      turnOnWave(true);
      setTimeout(() => {
        turnOnWave(false);
      }, animTime);
      setTimeout(() => {
        turnOnArrowAnim(false);
      }, (animTime * 2) + 500);
    }
  }, [num, position]);


  const renderArrowsTop = () => {
    const array = [];
    let i;
    const j = innerWidth / 104;
    array.push(<ArrowColumn
      style={{ width: '1px' }}
    />);
    for (i = 0; i < j; i++) {
      const randomAnim = !isArrowAnimOn && iconRandomNumber === i;
      if (i && (i % 3 === 0)) {
        array.push(
          <Arrow
            fill={GREY_DARK}
          >
            <LineTopLong
              isWaveAnimOn={randomAnim || isWaveAnimOn}
              delay={isArrowAnimOn ? i * 50 : 0}
            />
          </Arrow>,
        );
      }
      if (i && (i % 2 === 0)) {
        array.push(
          <Arrow
            fill={GREY_DARK}
          >
            <LineTop
              isWaveAnimOn={randomAnim || isWaveAnimOn}
              delay={isArrowAnimOn ? i * 50 : 0}
            />
          </Arrow>,
        );
      } else {
        array.push(
          <Arrow
            fill={GREY_DARK}
          >
            <LineTopShort
              isWaveAnimOn={randomAnim || isWaveAnimOn}
              delay={isArrowAnimOn ? i * 50 : 0}
            />
          </Arrow>,
        );
      }
    }
    return array;
  };


  const renderArrowsBottom = () => {
    const array = [];
    let i;
    const j = innerWidth / 104;
    array.push(<ArrowColumn
      style={{ width: '1px' }}
    />);
    for (i = 0; i < j; i++) {
      const randomAnim = !isArrowAnimOn && iconRandomNumber === i;
      if (i && (i % 3 === 0)) {
        array.push(
          <ArrowBottom
            fill={ORANGE}
            bottom
          >
            <LineBottom
              isWaveAnimOn={randomAnim || isWaveAnimOn}
              delay={isArrowAnimOn ? i * 50 : 0}
            />
          </ArrowBottom>,
        );
      }
      if (i && (i % 2 === 0)) {
        array.push(
          <ArrowBottom
            fill={ORANGE}
            bottom
          >
            <LineBottomShort
              isWaveAnimOn={randomAnim || isWaveAnimOn}
              delay={isArrowAnimOn ? i * 50 : 0}
            />
          </ArrowBottom>,
        );
      } else {
        array.push(
          <ArrowBottom
            fill={ORANGE}
            bottom
          >
            <LineBottomLong
              isWaveAnimOn={randomAnim || isWaveAnimOn}
              delay={isArrowAnimOn ? i * 50 : 0}
            />
          </ArrowBottom>,
        );
      }
    }
    return array;
  };

  return (
    <>
      <Row
        center
      >
        {renderArrowsTop()}
      </Row>
      <Background>
        <Row
          center
        >
          {renderArrowsBottom()}
        </Row>
      </Background>
    </>
  );
};


export default memo(Arrows);


const ArrowColumn = styled.div<{bottom?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.bottom ? 'flex-start' : 'flex-end')};
  height: 90px;
  position: relative;
  overflow: hidden;
`;

const Background = styled.div`
  width: 100%;
  background: white;
  ${shadowCard(SHADOW_GREY_DARK)};
`;

const LineTop = styled.div<{isWaveAnimOn?: boolean; delay: number}>`
  width: 1px;
  border-left: 1px ${GREY_DARK} dashed;
  margin-top: 6px;
  min-height: ${(props) => (props.isWaveAnimOn ? '8px' : '20px')};
  transition: min-height 0.3s linear ${(props) => props.delay}ms;
  height: auto;
`;

const LineTopShort = styled(LineTop)`
  min-height: ${(props) => (props.isWaveAnimOn ? '0px' : '12px')};
`;

const LineTopLong = styled(LineTop)`
  min-height: ${(props) => (props.isWaveAnimOn ? '30px' : '42px')};
`;
// eslint-disable-next-line
const LineBottom = styled.div<{isWaveAnimOn?: boolean; delay: number; onRandom?: string}>`
  width: 1px;
  min-height: ${(props) => (props.isWaveAnimOn ? '32px' : '20px')};
 
  transition: min-height 250ms linear ${(props) => props.delay}ms;
  border-left: 1px ${ORANGE} dashed;
  height: auto;
`;

const LineBottomShort = styled(LineBottom)`
  min-height: ${(props) => (props.isWaveAnimOn ? '24px' : '12px')};
`;

const LineBottomLong = styled(LineBottom)`
  min-height: ${(props) => (props.isWaveAnimOn ? '54px' : '42px')};
`;
