import React, { FC } from 'react';
import styled from 'styled-components';

import {
  Col, FONT_EXAMPLE, Row, ORANGE, FONT_EXAMPLE_MOBILE,
} from '../../../../styles';
import IntroCommentNoAnim from './IntroCommentNoAnim';

const IntroComment: FC <{
  text: string;
  imageUrl?: string;
  rightAvatar?: boolean;
  imageProf?: boolean;
  noMargins?: boolean;
}> = ({
  text, imageUrl, rightAvatar, imageProf, noMargins,
}) => (
  <Row md>
    <Col size={1} />
    <ExampleText size={9}>
      <IntroCommentNoAnim
        exampleText={text}
        imageUrl={imageUrl}
        rightAvatar={rightAvatar}
        imageProf={imageProf}
        noMargins={noMargins}
      />
    </ExampleText>
    <Col size={2} />
  </Row>
);

export default IntroComment;


const ExampleText = styled(Col)`
   font-family: ${FONT_EXAMPLE};
   color: ${ORANGE};
   font-size: 20px;
   padding-left: 12px;
   @media only screen and (max-width: 768px){
    font-family: ${FONT_EXAMPLE_MOBILE};
    font-size: 11px;
  }
`;
