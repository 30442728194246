import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Clipboard from 'clipboard';

import {
  Col,
  FONT_SIZE_SMALL,
  GREY,
  GREY_DARK,
  LETTER_SPACING_NEG,
  LINK_NO_STYLE,
  Row,
} from '../../../styles';
import Icon from '../../../styles/Icon';
import * as iconTypes from '../../../styles/iconTypes';
import DealMenuBorder from '../../UI/Cards/DealCard/DealMenuBorder';


const IdeaMenuBottom: FC <{
  setPreviewOn: any;
  linkToDealIdea: string;
  invitedIsWitness: boolean;
}> = ({
  setPreviewOn,
  linkToDealIdea,
  invitedIsWitness,
}) => {
  const [linkWasCopied, setLinkWasCopied] = useState<boolean>(false);
  useEffect(() => {
    const clipboard = new Clipboard('.linkbutton');
    clipboard.on('success', (e: any) => {
      setLinkWasCopied(true);
      e.clearSelection();
      setTimeout(() => setLinkWasCopied(false), 1000);
    });
    return () => clipboard.destroy();
  }, []);


  const handleLink = () => {
    if (linkWasCopied) {
      return (
        <Row
          style={{
            height: '42px',
          }}
          center
          ver
        >
          <Preview
            style={{
              paddingTop: '14px',
            }}
          >
            Copied to clipboard
          </Preview>
        </Row>
      );
    }
    return (
      <Row
        md
      >
        <a
          style={LINK_NO_STYLE}
          href={invitedIsWitness ? linkToDealIdea.concat('_friend') : linkToDealIdea.replace('_friend', '')}
          rel="noopener noreferrer"
        >
          <Col
            size={4}
            ver
            jEnd
          >
            <Preview>
              {invitedIsWitness ? "set Friend's Challenge" : 'set Your challenge'}
            </Preview>
          </Col>
        </a>
        <Col
          size={1}
          center
          ver
        >
          <DealMenuBorder />
        </Col>
        <Col
          size={1}
          ver
          center
          cursor
          className="linkbutton"
          data-clipboard-text={linkToDealIdea}
        >

          <Icon
            height="18"
            width="16"
            iconType={iconTypes.SHARE}
            fill={GREY_DARK}
          />
        </Col>
        <Col
          size={1}
          center
          ver
        >
          <DealMenuBorder />
        </Col>
        <Col
          size={3}
          ver
          center
          onClick={() => setPreviewOn(true)}
          cursor
        >
          <Preview>
            Contract Preview
          </Preview>
          <Icon
            height="12"
            width="12"
            iconType={iconTypes.TRIANGLE_RIGHT}
            fill={GREY_DARK}
          />
        </Col>
        <Col size={1} />
      </Row>
    );
  };


  return (
    <>
      <Row
        center
        md
      >
        <Border />
      </Row>
      {handleLink()}
    </>
  );
};


export default IdeaMenuBottom;

const Preview = styled.span`
   font-size: ${FONT_SIZE_SMALL};
  letter-spacing: ${LETTER_SPACING_NEG};
    color: ${GREY_DARK};
    align-items: center;
    padding-right: 8px;
`;

export const Border = styled.span`
    border-top: 1px dashed ${GREY};
    height: 1px; 
    width: 100%;
    @media only screen and (max-width: 400px){
    width: 320px;
    }
`;
