import moment from 'moment';

const convertToDate = (date) => {
  if (date) {
    return (
      moment(
        date.toDate(),
      ).calendar(undefined, { sameElse: 'MMMM Do YYYY' })
    );
  }
  return null;
};


export default convertToDate;
