import React from 'react';
import styled from 'styled-components';
import {
  FONT_SIZE_MEDIUM, GREY, GREY_MEDIUM, LETTER_SPACING_NEG, Row,
} from '../../styles/index';

interface Props {
  question: string;
  answer: string;
}

const LoginSignUpTexts = ({ question, answer }: Props) => (
  <Row
    bgr
    center
  >
    <Container>
      <Row center>
        <FormQuestionLinkText>
          {question}
        </FormQuestionLinkText>
      </Row>
      <Row
        center
        sm
      >
        <FormQuestionLinkTextDark>
          {answer}
        </FormQuestionLinkTextDark>
      </Row>
    </Container>
  </Row>
);


export default LoginSignUpTexts;


const Container = styled.div`
  width: 425px;
`;
const FormQuestionLinkText = styled.span`
  font-size: ${FONT_SIZE_MEDIUM};
  color: ${GREY};
  letter-spacing: ${LETTER_SPACING_NEG};
  line-height: 18px;
`;
const FormQuestionLinkTextDark = styled(FormQuestionLinkText)`
  color: ${GREY_MEDIUM};
`;
