import React from 'react';
import styled from 'styled-components';

import { Col, Row } from '../../../../styles';
import NavAppName from '../NavAppName';

export const texts = {
  en: {
    features: 'Features',
    premium: 'Premium',
    logIn: 'Login',
    logout: 'Logout',
  },
};

const PrivacyNav: React.FunctionComponent = () => (
  <Row
    md
    center
  >
    <Container>
      <Row>
        <Col size={1} />
        <Col
          size={10}
          ver
          center
        >
          <NavAppName />
        </Col>
        <Col size={1} />
      </Row>
    </Container>
  </Row>
);
export default (PrivacyNav);

const Container = styled.div`
    width: 768px;
  `;
