import React, { FC } from 'react';
import styled from 'styled-components';

import {
  Col, FONT_EXAMPLE, Row, ORANGE,
} from '../../../../styles';
import IntroCommentNoAnim from '../Comments/IntroCommentNoAnim';
import robot from '../../../../assets/robot.png';

const IntroDealExampleSM: FC <{
  text: string;
  imageUrl?: string;
  rightAvatar?: boolean;
  imageProf?: boolean;
}> = ({
  text, imageUrl, rightAvatar, imageProf,
}) => (
  <Row>
    <Col size={1} />
    <ExampleText
      size={10}
      center
    >
      <IntroCommentNoAnim
        exampleText={text}
        imageUrl={imageUrl || robot}
        rightAvatar={rightAvatar}
        imageProf={imageProf}
      />
    </ExampleText>
    <Col size={1} />
  </Row>
);

export default IntroDealExampleSM;


const ExampleText = styled(Col)`
   font-family: ${FONT_EXAMPLE};
   color: ${ORANGE};
   font-size: 32px;
   padding-left: 12px;
`;
