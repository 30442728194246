import useWindowSize from '@rehooks/window-size';
import React from 'react';
import styled from 'styled-components';

import {
  Col, Row, TEXT_CARD_TITLE, TEXT_CARD_TITLE_LIGHT,
} from '../../../../styles';


const ConsequencesTitle = () => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  return (
    <Row
      sm
      center
    >
      <Col
        size={5}
        center
      >
        <TitleCardLight>
          Challenge
        </TitleCardLight>
        <TitleCard>
          Accomplished
        </TitleCard>
      </Col>
      {innerWidth > 400
        ? (
          <Col
            size={2}
            center
          />
        ) : null}
      <Col
        size={5}
        center
      >
        <TitleCardLight>
          Challenge
        </TitleCardLight>
        <TitleCard>
          Failed
        </TitleCard>

      </Col>
    </Row>
  );
};


export default ConsequencesTitle;

const TitleCard = styled.div`
      ${TEXT_CARD_TITLE}
      padding-right: 2px;
`;
const TitleCardLight = styled.div`
      ${TEXT_CARD_TITLE_LIGHT}
      padding-right: 2px;
`;
