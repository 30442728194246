import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

type Props = {
  animOn: boolean;
}

const AnimOpacity: React.FunctionComponent<Props> = ({
  animOn,
  children,
}) => {
  const [isContentVisible, setContentVisible] = useState(false);
  const [isOpacityOn, startAimOpacity] = useState(false);


  useEffect(() => {
    if (animOn) {
      setTimeout(() => {
        setContentVisible(true);
        startAimOpacity(true);
      }, 200);
    }
    if (!animOn) {
      setTimeout(() => {
        setContentVisible(false);
        startAimOpacity(false);
      }, 200);
    }
  }, [animOn]);

  return (
    <AnimaOpacity
      isOpacityOn={isOpacityOn}
    >
      {isContentVisible && children}
    </AnimaOpacity>
  );
};

export default AnimOpacity;


const AnimaOpacity = styled.div<{isOpacityOn: boolean}>`

   transition: opacity 0.2s linear;
   opacity: ${(props) => (props.isOpacityOn ? 1 : 0)}
`;
