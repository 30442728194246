import React, { FC, useContext } from 'react';
import styled from 'styled-components';

import Language from '../../contexts/Language';
import AnimOpacity from '../../hoc/AnimOpacity';
import convertToDate from '../../hoc/convertToDate';
import switchLanguage from '../../hoc/switchLanguage';
import { DateCreated, DateDone, DateDue } from '../../interfaces/dealTypes';
import {
  Col, GREY, GREY_DARK, Row, TEXT_CARD_SMALL,
} from '../../styles';


const texts = {
  en: {
    dueDate: 'Due date',
    isNotSet: " wasn't set",
    completed: 'Completed',
    dealCompleted: 'Created',
  },
};


type IDealItemDatesProps = {
  areDatesVisible: boolean;
  dateCreated: DateCreated;
  dateDone: DateDone;
  dateDue: DateDue;
}


const DealItemDates: FC <IDealItemDatesProps> = ({
  areDatesVisible,
  dateCreated,
  dateDone,
  dateDue,
}) => {
  const locale = useContext(Language);

  const renderDateDone = () => {
    if (dateDone) {
      return (
        <DateDoneRow>
          <Col size={1} />
          <DateDoneCol isBorderVisible>
            <DateDoneTextBold>
              {switchLanguage(locale, texts).completed}
            </DateDoneTextBold>
            <DateDoneText>
              {' '}
              {convertToDate(dateDone)}
            </DateDoneText>
          </DateDoneCol>
          <Col size={1} />
        </DateDoneRow>
      );
    } return null;
  };

  return (
    <AnimOpacity
      animOn={areDatesVisible}
    >
      <DateDoneRow>
        <Col size={1} />
        <DateDoneCol up>
          <DateDoneTextBold>
            {switchLanguage(locale, texts).dealCompleted}
          </DateDoneTextBold>
          <DateDoneText>
            {' '}
            {convertToDate(dateCreated)}
          </DateDoneText>
        </DateDoneCol>
        <Col size={1} />
      </DateDoneRow>
      <DateDoneRow>
        <Col size={1} />
        <DateDoneCol
          isBorderVisible
        >
          <DateDoneTextBold>
            {dateDue
              ? switchLanguage(null, texts).dueDate
              : switchLanguage(null, texts).dueDate}
          </DateDoneTextBold>
          <DateDoneText>
            {dateDue
              ? `${' '}${convertToDate(dateDue)}`
              : switchLanguage(null, texts).isNotSet}
          </DateDoneText>
        </DateDoneCol>
        <Col size={1} />
      </DateDoneRow>
      {renderDateDone()}
    </AnimOpacity>
  );
};

export default DealItemDates;


const DateDoneRow = styled(Row)`
  padding-bottom: 24px;
  justify-content: center;
  text-align: center;
  align-items: center;
`;


const DateDoneCol = styled.div<{up?: boolean; isBorderVisible?: boolean}>`
  padding-top: ${(props) => (props.up ? '12px' : '24px')};
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  flex: 10;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: row;
  display:flex;
  border-top:  ${(props) => (props.isBorderVisible && `1px dashed ${GREY}`)};
`;

const DateDoneText = styled.span`
  color: ${GREY};
  ${TEXT_CARD_SMALL}
`;
const DateDoneTextBold = styled.span`
  color: ${GREY_DARK};
  ${TEXT_CARD_SMALL}
  margin-right: 4px;
`;
