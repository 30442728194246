import React, { FC, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import GoBack from '../components/PublicGoBackBar';
import LogInContainer from '../components/LogIn/LogInContainer';
import LoginDownloadIcons from '../components/LogIn/LoginDownloadIcons';
import LoginSignUpTexts from '../components/LogIn/LoginSignUpTexts';
import LogInTitle from '../components/LogIn/LogInTitle';
import LoginNav from '../components/Nav/Public/LoginNav/LoginNav';
import NextButton from '../components/UI/Buttons/NextButton';
import ReportCardSmall from '../components/UI/Cards/ReportCardSmall';
import Input from '../components/UI/Inputs/Input';
import Language from '../contexts/Language';
import switchLanguage from '../hoc/switchLanguage';
import Login from '../interfaces/loginTypes';
import {
  logInEmailChanged,
  logInErrorReset,
  logInPasswordChanged,
  logInReset,
  logInHandleLogin,
} from '../store/actions';
import {
  GREY, Row, SHADOW_GREY, shadowCard,
} from '../styles';


const texts = {
  en: {
    email: 'Your email',
    password: 'Password',
    warringTitle: 'Log In',
    warningTitleLight: 'problem',
    question: "Don't have Dealem account ?",
    answer: 'Download app and sign up for free! ',
    sign: 'and sign up for free!',
  },
};


type Props = {
  location: {
    pathname: string;
    state: {
      dealId?: string;
    };
  };
  history: {push: Function};
  handleLogin: (email: string, password: string) => void;
  emailChanged: (email: string) => void;
  passwordChanged: (password: string) => void;
  reset: () => void;
  errorReset: () => void;
}


const LogIn: FC<Login & Props> = ({
  success,
  error,
  loading,
  email,
  password,
  errorMessage,
  handleLogin,
  emailChanged,
  passwordChanged,
  reset,
  errorReset,
  location,
  history,
}) => {
  const locale = useContext(Language);

  const logInHandler = () => {
    handleLogin(email, password);
  };

  useEffect(() => {
    if (success) {
      if (location.state) {
        history.push(
          `deal/${location.state.dealId}`,
        );
      } else {
        reset();
        history.push('/');
      }
    }
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location, success]);

  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    logInHandler();
  };

  return (
    <>
      <LoginNav />
      <GoBack />
      <LogInTitle />
      <LogInContainer>
        <LogInPanel>
          <form>
            <Panel
              slide={error}
            >
              <Input
                placeholder={switchLanguage(locale, texts).email}
                onChange={(e) => emailChanged((e.target as HTMLTextAreaElement).value.trim())}
                disabled={loading}
                border
                value={email}
                narrow
              />
              <Input
                password
                placeholder={switchLanguage(locale, texts).password}
                onChange={(e) => passwordChanged((e.target as HTMLTextAreaElement).value.trim())}
                disabled={loading}
                value={password}
                narrow
              />
            </Panel>
            <LoginButtonWrapper>
              <NextButton
                loading={loading}
                onClick={onButtonClick}
                error={error}
              />
            </LoginButtonWrapper>
            {error
              ? (
                <WarringWrapper>
                  <ReportCardSmall
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => errorReset()}
                    description={errorMessage}
                    title={switchLanguage(locale, texts).warringTitle}
                    titleLight={switchLanguage(locale, texts).warringTitleLight}
                    error
                  />
                </WarringWrapper>
              ) : null}
          </form>
        </LogInPanel>
      </LogInContainer>
      <LoginSignUpTexts
        question={switchLanguage(locale, texts).question}
        answer={switchLanguage(locale, texts).answer}
      />
      <LoginDownloadIcons />
      <Row
        center
        bgr
      >
        <Border />
      </Row>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  email: state.logIn.email,
  password: state.logIn.password,
  success: state.logIn.success,
  error: state.logIn.error,
  errorMessage: state.logIn.errorMessage,
  loading: state.logIn.loading,
  userId: state.user.userId,
});


export default connect(mapStateToProps,
  {
    handleLogin: logInHandleLogin,
    emailChanged: logInEmailChanged,
    passwordChanged: logInPasswordChanged,
    reset: logInReset,
    errorReset: logInErrorReset,
  })(LogIn);

const LogInPanel = styled.div`
  z-index: 10;
  width: 360px;
  @media (max-width: 425px) {
    width: 100%;
  }
`;

const WarringWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 10px 40px 0 rgba(190,190,207,0.50);
  border-radius: 5px;
  max-height: 128px;
  width: 100%;
  margin-top: -10px;
  box-sizing: border-box;
  text-align: left;
`;

const Panel = styled.div<{slide?: boolean|null}>`
    background: #FFFFFF;
    ${shadowCard(SHADOW_GREY)};
    border-radius: 5px;
    width: 100%;
    z-index: 10;
    transition: margin-top 0.1s linear;
    margin-top:${(props) => (props.slide ? '20px' : '100px')};
    @media (max-width: 425px) {
      margin-top:${(props) => (props.slide ? '20px' : '40px')};
  }
`;

const LoginButtonWrapper = styled.div`
    z-index: 12;
    margin-top: -12px;
    display: flex;
    justify-content: center;
`;

const Border = styled.div`
    border-top: dashed 1px ${GREY};
    margin-top: 8px;
    margin-bottom: 8px;
    width: 20px;
`;
