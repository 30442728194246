import * as React from 'react';
import styled from 'styled-components';
import {
  Col,
  CardTitleTextStyle,
  CardTitleLightTextStyle,
  CardTitleDescriptionTextStyle,
} from '../../styles/index';

interface IDealTitleTextProps {
  title: string;
  titleLight: string;
  description: string;
}

const DealTitleText: React.FunctionComponent<IDealTitleTextProps> = ({
  title,
  titleLight,
  description,
}) => (
  <Container
    size={9}
    center
  >
    <UpperRow>
      <CardTitleTextStyle
        data-test="test-tag"
      >
        {title}
      </CardTitleTextStyle>
      <CardTitleLightTextStyle>
        {titleLight}
      </CardTitleLightTextStyle>
    </UpperRow>
    <CardTitleDescriptionTextStyle>
      {description}
    </CardTitleDescriptionTextStyle>
  </Container>
);

export default DealTitleText;

const Container = styled(Col)`
  flex-direction: column;
`;

export const UpperRow = styled.div`
   display: flex;
`;
