import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Language from '../../../contexts/Language';
import switchLanguage from '../../../hoc/switchLanguage';
import {
  Col,
  DIST_LARGE,
  DIST_MEDIUM,
  FONT_SIZE_MEDIUM,
  GREY,
  GREY_DARK,
  GREY_MEDIUM,
  LETTER_SPACING_NEG,
  LINK_NO_STYLE,
  ORANGE,
  Row,
  ScreenLinkTextStyle,
  SHADOW_GREY,
  shadowCard,
  TWITTER_LINK,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  EMAIL_LINK,
} from '../../../styles';
import Icon from '../../../styles/Icon';
import * as iconTypes from '../../../styles/iconTypes';
import { TextLight } from '../../UI/Cards/DealCard/DealCardPrize';
import DealMenuBorder from '../../UI/Cards/DealCard/DealMenuBorder';
import DealPublicFooterIcon from '../DealPublicFooter/DealPublicFooterIcon';

export const texts = {
  en: {
    contact: 'Contact us:',
    contactShort: 'Contact:',
    gp: 'Google Play and the Google Play logo are trademarks of Google LLC.',
    logIn: 'Login',
    logout: 'Logout',
    homePage: 'home',
    terms: 'terms and conditions',
    privacy: 'privacy',
    lawInfoShort: 'terms and conditions',
    eod: 'EOD code',
    rr: 'all rights reserved',
    as: 'Apple, the Apple logo, iPhone are trademarks of Apple Inc., registered in the U.S. and other countries and regions. App Store is fq service mark of Apple Inc',
  },
};

const HomeFooterDesktop: React.FunctionComponent = () => {
  const locale = useContext(Language);
  return (
    <Row
      center
    >
      <Container>
        <Row>
          <Col size={1} />
          <Col size={10}>
            <Border />
          </Col>
          <Col size={1} />
        </Row>
        <Row>
          <Col size={1} />
          <DealPublicFooterIcon
            iconType={iconTypes.INSTAGRAM}
            iconTypeHover={iconTypes.INSTAGRAM_SOLID}
            link={INSTAGRAM_LINK}
          />
          <Col
            size={1}
            center
            ver
          >
            <DealMenuBorder />
          </Col>
          <DealPublicFooterIcon
            iconType={iconTypes.TWITTER}
            iconTypeHover={iconTypes.TWITTER_SOLID}
            link={TWITTER_LINK}
          />
          <Col
            size={1}
            center
            ver
          >
            <DealMenuBorder />
          </Col>
          <DealPublicFooterIcon
            iconType={iconTypes.FACEBOOK}
            iconTypeHover={iconTypes.FACEBOOK_SOLID}
            link={FACEBOOK_LINK}
          />
          <Col
            size={1}
            center
            ver
          >
            <DealMenuBorder />
          </Col>
          <DealPublicFooterIcon
            iconType={iconTypes.AT}
            iconTypeHover={iconTypes.AT}
            link={EMAIL_LINK}
          />
          <Col size={1} />
        </Row>
        <Row
          bgr
          center
        >
          <Col size={1} />
          <Col size={10}>
            <Panel>
              <Row ver>
                <Col
                  size={1}
                  ver
                />
                <Col
                  size={4}
                  ver
                  center
                  cursor
                >
                  <Link
                    to="/terms"
                    style={LINK_NO_STYLE}
                  >
                    <ScreenLinkTextStyle>
                      {switchLanguage(locale, texts).terms}
                    </ScreenLinkTextStyle>
                  </Link>
                </Col>
                <Col
                  size={1}
                  center
                >
                  <DealMenuBorder />
                </Col>
                <a
                  href="http://eodcode.com"
                  style={LINK_NO_STYLE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Col
                    size={5}
                    ver
                    center
                    cursor
                  >
                    <EODText>{switchLanguage(locale, texts).eod}</EODText>
                    <TextDark>
                      {switchLanguage(locale, texts).rr}
                    </TextDark>
                    <Icon
                      height="16"
                      width="16"
                      iconType={iconTypes.COPYRIGHTS}
                      fill={GREY_DARK}
                      // style={{ marginLeft: '4px' }}
                    />
                  </Col>
                </a>
                <Col size={1} />
              </Row>
              <Row
                center
                sm
              >
                <Col size={2} />
                <Col
                  size={8}
                  center
                >
                  <TextLaw>
                    {switchLanguage(locale, texts).as}
                  </TextLaw>
                </Col>
                <Col size={2} />
              </Row>
              <Row
                center
                sm
              >
                <Col size={2} />
                <Col
                  size={8}
                  center
                >
                  <TextLaw>
                    {switchLanguage(locale, texts).gp}
                  </TextLaw>
                </Col>
                <Col size={2} />
              </Row>
            </Panel>
          </Col>
          <Col size={1} />
        </Row>
      </Container>
    </Row>
  );
};


export default (HomeFooterDesktop);

export const FormIconLinkStyle = styled.span`
    font-size: ${FONT_SIZE_MEDIUM};
    color: ${GREY_MEDIUM};
    letter-spacing: ${LETTER_SPACING_NEG};
  `;

export const Container = styled.div`
    width: 768px;
    margin-top: ${DIST_LARGE};
    margin-bottom: -1px;  
  `;

export const Border = styled.span`
    border-top: 1px dashed ${GREY};
    height: 1px; 
    width: 100%;
    margin-bottom: ${DIST_MEDIUM};
`;

export const Panel = styled.div`
    background: #FFFFFF;
    ${shadowCard(SHADOW_GREY)};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    z-index: 10;
    padding: ${DIST_MEDIUM} 0;
`;

export const TextDark = styled(TextLight)`
  color: ${GREY_DARK};
  align-items: center;
  margin-right: 4px;
`;

export const TextLaw = styled(TextDark)`
  margin-right: 0;
  align-items: center;
  color: ${GREY_MEDIUM};
`;

export const EODText = styled(TextDark)`
  color:${ORANGE};
`;
