import useWindowSize from '@rehooks/window-size';
import React, { memo } from 'react';

import firebase from '../../../../firebase';
import HomeNavDesktop from './HomeNavDesktop';
import HomeNavMobile from './HomeNavMobile';


const HomeNav: React.FunctionComponent = () => {
  const windowSize = useWindowSize();

  const handleSignOut = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    // eslint-disable-next-line no-shadow
    event.preventDefault();
    firebase.database().ref('users').off();
    firebase.database().ref('deals').off();
    firebase.database().ref('usernames').off();
    firebase.database().ref('friends').off();

    firebase.auth().signOut().catch(
      (error) => {
        console.log(error);
      },
    );
  };

  if (windowSize.innerWidth < 1200) {
    return (
      <HomeNavMobile
        onSignOut={handleSignOut}
      />
    );
  }

  return (
    <HomeNavDesktop
      onSignOut={handleSignOut}

    />
  );
};


export default memo(HomeNav);
