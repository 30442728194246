import React, { FC } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {
  Col, GREY, GREY_DARK, GREY_MEDIUM, Row, H2, FONT_FUTURA,
} from '../../../styles';


type Props = {
  title: string;
  subtitle: string;
  description: string;
  dark?: boolean;
  id: string;
  H2on?: boolean;
  border?: boolean;
  centered?: boolean;
}

const HomeTitleSection: FC <Props> = ({
  title,
  subtitle,
  description,
  dark,
  id,
  H2on,
  border,
  centered,
}) => (
  <>
    <Row
      center
      ver
      smB
    >
      <Subtile
        id={id}
        component={H2on ? <H2 /> : <P />}
      >
        {subtitle}
      </Subtile>
    </Row>
    <Row
      center
      ver
    >
      <Title
        dark={dark}
      >
        {title}
      </Title>
    </Row>
    <Row
      center
      ver
      bgr
    >
      <Col size={1} />
      <Col
        size={10}
        center
      >
        {border
          ? (
            <Row
              center
              bg
            >
              <Border />
            </Row>
          )
          : (
            <Description
              dark={dark}
              centered={centered}
            >
              {ReactHtmlParser(description)}
            </Description>
          )}
      </Col>
      <Col size={1} />
    </Row>
  </>

);

export default HomeTitleSection;


const Subtile = styled(
  ({ component, ...props }) => React.cloneElement(component, props),
)`
font-size: 16px;
color: ${GREY_MEDIUM};
letter-spacing: 2.67px;
text-transform: uppercase;
padding-top:24px;
`;


const Title = styled.h1<{dark?: boolean}>`
  font-family: ${FONT_FUTURA};
  font-size: 40px;
  color: ${(props) => (props.dark ? GREY_DARK : 'white')};
  letter-spacing: -2px;
  line-height: 48px;
  font-weight: 400;
  margin: 0;
  text-align: center;
`;


const Description = styled.p<{dark?: boolean; centered?: boolean}>`
   /* display: flex; */
   justify-content: ${(props) => (props.centered ? 'center' : 'normal')}
   font-size: 16px;
   color: ${(props) => (props.dark ? GREY : 'white')};
    letter-spacing: -1px;
    width: 425px;
    @media (max-width: 425px) {
    width: 100%;
  }
`;
const P = styled.p`
  
`;

export const Border = styled.div`
    border-top: 1px dashed ${GREY_DARK};
    height: 1px; 
    width: 42px;
`;
