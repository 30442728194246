import React from 'react';
import styled from 'styled-components';

type Props = {
  isContentVisible: boolean;
  isHeightOn: boolean;
  isOpacityOn: boolean;
  children?: React.ReactNode;
  mediaListLength: number;
}

const AnimSlideDown: React.FunctionComponent<Props> = ({
  isHeightOn,
  isOpacityOn,
  isContentVisible,
  children,
  mediaListLength,
}) => (
  <AnimHeight
    isHeightOn={isHeightOn}
    mediaListLength={mediaListLength}
  >
    <AnimOpacity
      isOpacityOn={isOpacityOn}
    >
      {isContentVisible && children}
    </AnimOpacity>
  </AnimHeight>
);

export default AnimSlideDown;

const AnimHeight = styled.div<{isHeightOn: boolean; mediaListLength: number}>`
   max-height: ${(props) => (props.isHeightOn ? `${1000 + (props.mediaListLength * 300)}px` : '0')};
   transition: max-height 1s linear;
   height:auto;
   overflow: hidden; 
`;


const AnimOpacity = styled.div<{isOpacityOn: boolean}>`
   transition: opacity 0.5s linear;
   opacity: ${(props) => (props.isOpacityOn ? 1 : 0)}
`;
