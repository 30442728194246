import React, { useContext } from 'react';
import styled from 'styled-components';

import robotLoading from '../../../../assets/robotLoading.gif';
import Language from '../../../../contexts/Language';
import switchLanguage from '../../../../hoc/switchLanguage';
import {
  Col, DealCardContainerStyle, DIST_SMALL, FONT_FUTURA, GREY, GREY_DARK, GREY_MEDIUM, H3, Row, TEXT_CARD_SMALL, WhiteContainer,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';
import IntroCommentNoAnim from '../Comments/IntroCommentNoAnim';
import DealCardAttachmentExample from '../../../UI/Cards/DealCard/DealCardAttachmentExample';
import ExamplePhoto from '../../../UI/Cards/DealCard/ExamplePhoto';
import IntroCardMobile from '../IntroStyle/IntroCardMobile';


const texts = {
  en: {
    subtitle: 'More features',
    title: 'Attachments & sharing',
    why: 'WHY IT WORKS LIKE THAT ?',
    descriptionWhy: 'You want to show your progress through photos ? Show were you started and proof that you finished. It feels good !',
    photoTitle: 'Photos',
    photoTitleLight: 'or videos',
    photoDescription: 'You can attach a photo/video of the starting point. Likewise, you can add a photo/video proving your achievement.',
    photoExample: 'I did it, have a look!',
    pdfTitle: 'Attach',
    pdfTitleLight: ' more text',
    pdfDescription: "Sometimes the challenge description can be long. No problem! You can attach a pdf file to deal's invitation.",
    pdfExample: 'Woohoo, good job!',
    publicTitle: 'Public',
    publicTitleLight: ' vs private challenge contract',
    publicDescription: 'You can switch your challenge from private to public anytime.\nIf you want to let others know about your challenge, create a public link and spread the news! Everyone can follow your progress in their browser or/and in Dealem app!',
  },
};

const FeaturesMobile = () => {
  const locale = useContext(Language);
  return (
    <WhiteContainer
      padBot="0px"
    >
      <Row>
        <Col size={1} />
        <Col size={10}>
          <Container>
            <Subtile>
              {switchLanguage(locale, texts).subtitle}
            </Subtile>
            <Title>
              {switchLanguage(locale, texts).title}
            </Title>
            <IntroCardMobile
              title={switchLanguage(locale, texts).photoTitle}
              titleLight={switchLanguage(locale, texts).photoTitleLight}
              description={switchLanguage(locale, texts).photoDescription}
              iconType={iconTypes.PHOTO}
            />
            <IntroCardMobile
              title={switchLanguage(locale, texts).pdfTitle}
              titleLight={switchLanguage(locale, texts).pdfTitleLight}
              description={switchLanguage(locale, texts).pdfDescription}
              iconType={iconTypes.PAPERCLIP}
              titleTagComponent={<H3 />}
            />
            <IntroCardMobile
              title={switchLanguage(locale, texts).publicTitle}
              titleLight={switchLanguage(locale, texts).publicTitleLight}
              description={switchLanguage(locale, texts).publicDescription}
              iconType={iconTypes.LINK}
              titleTagComponent={<H3 />}
            />
          </Container>
        </Col>
        <Col size={1} />
      </Row>
      <Row
        bg
        center
      >
        <Col size={1} />
        <Col size={10}>
          <DealCardContainerStyle
            shadow
            drawer
          >
            <Row md />
            <DealCardAttachmentExample
              attachment={{
                attachmentName: 'Attachment.pdf',
              }}
            />
            <ExamplePhoto
              photoUri={robotLoading}
              modalDisabled
            />
            <Row
              md
              mdB
            >
              <Col size={4} />
              <Col
                size={4}
                center
                ver
              >
                <ProofText>
                  Proof
                </ProofText>
              </Col>
              <Col
                size={4}
                jEnd
                ver
              >
                <Icon
                  height="20"
                  width="20"
                  iconType={iconTypes.CHEVRON_DOWN}
                  fill={GREY_MEDIUM}
                />
              </Col>
              <Col size={1} />
            </Row>
          </DealCardContainerStyle>
        </Col>
        <Col size={1} />
      </Row>

      <Row
        center
        sm
      >
        <IntroCommentNoAnim
          exampleText={switchLanguage(locale, texts).pdfExample}
          rightAvatar
        />
      </Row>
    </WhiteContainer>
  );
};


export default FeaturesMobile;


const ProofText = styled.span`
    text-align: center;
    ${TEXT_CARD_SMALL}
    color:${GREY};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subtile = styled.p`
  font-size: 14px;
  color: ${GREY_MEDIUM};
  letter-spacing: 2.67px;
  text-transform: uppercase;
  font-weight: bold;
`;

const Title = styled.h1`
  font-size: 32px;
  color: ${GREY_DARK};
  letter-spacing: -2px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: ${DIST_SMALL};
  margin-top: 12px;
  font-family:${FONT_FUTURA};
`;
