export const CALENDAR = 'CALENDAR';
export const SHARE = 'SHARE';
export const SPEECH_BUBBLE = 'SPEECH_BUBBLE';
export const MINIMIZE = 'MINIMIZE';
export const CUP = 'CUP';
export const USER = 'USER';
export const CHEVRON_DOWN = 'CHEVRON_DOWN';
export const PHOTO = 'PHOTO';
export const PAPERCLIP = 'PAPERCLIP';
export const MAXIMIZE = 'MAXIMIZE';
export const DOWNLOAD = 'DOWNLOAD';
export const LINK = 'LINK';
export const CLIPBOARD = 'CLIPBOARD';
export const ARROW_LEFT = 'ARROW_LEFT';
export const APPSTORE = 'APPSTORE';
export const APPSTORE_SOLID = 'APPSTORE_SOLID';
export const GOOGLE_PLAY = 'GOOGLE_PLAY';
export const GOOGLE_PLAY_SOLID = 'GOOGLE_PLAY_SOLID';
export const REFRESH = 'REFRESH';
export const QUESTION_MARK = 'QUESTION_MARK';
export const HOME = 'HOME';
export const INSTAGRAM = 'INSTAGRAM';
export const INSTAGRAM_SOLID = 'INSTAGRAM_SOLID';
export const FACEBOOK = 'FACEBOOK';
export const FACEBOOK_SOLID = 'FACEBOOK_SOLID';
export const TWITTER = 'TWITTER';
export const TWITTER_SOLID = 'TWITTER_SOLID';
export const COPYRIGHTS = 'COPYRIGHTS';
export const ARROW_RIGHT = 'ARROW_RIGHT';
export const CLOSE = 'CLOSE';
export const CHECK = 'CHECK';
export const EYE = 'EYE';
export const EYE_CLOSED = 'EYE_CLOSED';
export const ARROW_DOWN_DECOR = 'ARROW_DOWN_DECOR';
export const GEAR = 'GEAR';
export const GEAR_SOLID = 'GEAR_SOLID';
export const HOOK = 'HOOK';
export const HIDE_WINDOW = 'HIDE_WINDOW';
export const CLOSE_WINDOW = 'CLOSE_WINDOW';
export const RELOAD = 'RELOAD';
export const BULB = 'BULB';
export const BULB_SOLID = 'BULB_SOLID';
export const DIAMOND = 'DIAMOND';
export const DIAMOND_SOLID = 'DIAMOND_SOLID';
export const DIAMOND_TWO = 'DIAMOND_TWO';
export const SWITCH_OFF = 'SWITCH_OFF';
export const SWITCH_ON = 'SWITCH_ON';
export const NO_ADDS = 'NO_ADDS';
export const MEGAPHONE = 'MEGAPHONE';
export const STOP = 'STOP';
export const HANDSHAKE = 'HANDSHAKE';
export const PICTURE_TWO = 'PICTURE_TWO';
export const PIN = 'PIN';
export const MORE = 'MORE';
export const LOCK = 'LOCK';
export const LOCK_SOLID = 'LOCK_SOLID';
export const AT = 'AT';
export const STAR = 'STAR';
export const STAR_SOLID = 'STAR_SOLID';
export const STAIRS_UP = 'STAIRS_UP';
export const DOWNLOAD_CLOUD = 'DOWNLOAD_CLOUD';
export const TRIANGLE_RIGHT = 'TRIANGLE_RIGHT';
export const MONOCLE = 'MONOCLE';
export const BALANCE = 'BALANCE';
export const ANCHOR = 'ANCHOR';
export const MELONIK = 'MELONIK';
export const PDF = 'PDF';
export const TIK_TOK = 'TIK_TOK';
export const SMILE = 'SMILE';
export const SAD = 'SAD';
export const PLAY = 'PLAY';
export const SAVE = 'SAVE';
export const ROBOT = 'ROBOT';
export const YOU_TUBE = 'YOU_TUBE';
