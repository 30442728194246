import React, { FC } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openMenu } from '../../../../store/actions/userActions';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';
import {
  Col, FONT_HEAVY, FONT_SIZE_MEDIUM, LETTER_SPACING_X_POS, Row,
} from '../../../../styles/index';


type Props = {
  openMenu: Function;
}

// eslint-disable-next-line no-shadow
const NavMenu: FC <Props> = ({ openMenu }) => (
  <Row
    center
    ver
    md
  >
    <Col
      size={2}
      center
      ver
    />
    <Col
      size={1}
    />
    <Col
      size={6}
      center
      ver
    >
      <Title>
        dealem
      </Title>
    </Col>
    <Col
      ver
      jEnd
      size={2}
      cursor
      onClick={() => openMenu(false)}
    >
      <Icon
        height="18"
        width="18"
        iconType={iconTypes.CLOSE}
        fill="white"
      />
    </Col>
    <Col size={1} />
  </Row>
);

const mapStateToProps = (state: any) => ({
  openMenu: state.openMenu,
});

export default connect(mapStateToProps,
  {
    openMenu,
  })(NavMenu);


const Title = styled.div`
    color: white;
    letter-spacing: ${LETTER_SPACING_X_POS};
    text-align: center;
    text-transform: uppercase;
    font-size: ${FONT_SIZE_MEDIUM};
    font-weight: ${FONT_HEAVY}
`;
