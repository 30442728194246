import React, { FC, useContext } from 'react';
import styled from 'styled-components';

import Language from '../../../contexts/Language';
import switchLanguage from '../../../hoc/switchLanguage';
import {
  Col, DealCardContainerStyle, DIST_LARGE, Row,
} from '../../../styles';
import DealCardAuthor from '../../UI/Cards/DealCard/DealCardAuthor';
import DealCardDealname from '../../UI/Cards/DealCard/DealCardDealname';
import DealCardPrize from '../../UI/Cards/DealCard/DealCardPrize';
import DealCardTitle from '../../UI/Cards/DealCard/DealCardTitle';
import DealCardWitness from '../../UI/Cards/DealCard/DealCardWitness';
import DealItemDone from '../../Deal/DealItemDone';


const texts = {
  en: {
    deal: 'challenge',
    surprize: 'is surprize.',
    errorTitle: 'Error',
    errorTitleLight: ' occurred',
    errorDescription: 'Something went wrong.',
    successTitle: 'Claim',
    successTitleLight: ' send',
    successDescription: 'wait for the friend to accept it.',
  },
};

type Props = {
  author: {
    bannerUri: string;
    authorName: string;
    authorUri: string;
    authorAvatar: string;
  } | null;
  committedUsername: string;
  dealname: string;
  invitedIsWitness: boolean;
  penalty: string | null;
  prize: string | null;
  surprize: boolean;
  surprizePenalty: boolean;
  witnessUsername: string;
}


const IdeaPreview: FC <Props> = (
  {
    author,
    committedUsername,
    dealname,
    invitedIsWitness,
    penalty,
    prize,
    surprize,
    surprizePenalty,
    witnessUsername,
  },
) => {
  const locale = useContext(Language);


  let letter = '';
  if (committedUsername !== undefined) {
    letter = committedUsername.charAt(0);
  }

  return (
    <>
      <Row
        center
      >
        <Col size={1} />
        <Col
          size={10}
          center
        >
          <Container>
            <DealCardContainerStyle
              shadow
              drawer
            >
              <DealCardTitle
                letter={letter}
                title={invitedIsWitness ? 'Your' : `${committedUsername}'s `}
                titleLight={switchLanguage(locale, texts).deal}
                description={`${committedUsername} will commit to`}
                committedId=""
                avatarLoading={false}
                onAvatarLoading={() => null}
                dealId=""
                committedProfilePhoto=""
                preview
              />
              <DealCardDealname
                dealname={dealname}
              />
              <DealCardWitness
                witnessUsername={witnessUsername}
                witnessProfilePhoto=""
                are={!invitedIsWitness}
                preview
              />
              <DealCardPrize
                prize={prize}
                done={false}
                surprize={surprize}
                surprizeReveal={false}
              />
              <DealCardPrize
                prize={penalty}
                done={false}
                surprize={surprizePenalty}
                surprizeReveal={false}
                penalty
              />
              <DealCardAuthor
                author={author}
              />
              <DealItemDone
                done={false}
                preview
              />
              <Row md />
            </DealCardContainerStyle>
          </Container>
        </Col>
        <Col size={1} />
      </Row>
    </>
  );
};

export default IdeaPreview;


export const Container = styled.div`
    margin-top: ${DIST_LARGE};
    display: flex;
    flex-direction: column;
    align-items:center;
    margin-right: 1px;
    @media only screen and (max-width: 400px){
     width: 100%;
    }
`;
