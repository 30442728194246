import React, { FC } from 'react';
import styled from 'styled-components';
import {
  GREY_DARK,
} from '../../styles/index';

interface IScreenTitle {
  firstPart: string;
  secondPart: string;
}

const ScreenTitle: FC <IScreenTitle> = ({
  firstPart,
  secondPart,
}) => (
  <ScreenTitleWrapper>
    <ScreenTitleBold>
      {firstPart}
      {' '}
      <ScreenTitleLight>{secondPart}</ScreenTitleLight>
    </ScreenTitleBold>
  </ScreenTitleWrapper>
);
export default ScreenTitle;


const ScreenTitleWrapper = styled.div`
  margin-top: 56px;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const ScreenTitleBold = styled.h2`
  font-size: 28px;
  color: ${GREY_DARK};
  letter-spacing: -0.88px;
  font-weight: 600;
  margin: 0;
`;

const ScreenTitleLight = styled.span`
  font-weight: 400;
`;
