import React, { useContext } from 'react';
import styled from 'styled-components';
import Language from '../../../../contexts/Language';
import switchLanguage from '../../../../hoc/switchLanguage';
import * as iconTypes from '../../../../styles/iconTypes';
import { Col, Row } from '../../../../styles/index';
import DealMenuBorder from '../../../UI/Cards/DealCard/DealMenuBorder';
import NavAppName from '../NavAppName';
import NavAvatar from '../NavAvatar';
import NavLinkStore from '../NavLinkStore';

export const texts = {
  en: {
    features: 'Features',
    premium: 'Premium',
    logIn: 'Login',
    logout: 'Logout',
  },
};

const LoginNavDesktop: React.FunctionComponent = () => {
  const locale = useContext(Language);
  return (
    <Row
      md
      center
    >
      <Container>
        <Row>
          <Col size={1} />
          <Col
            size={4}
            ver
            center
          >
            <NavAppName />
          </Col>
          <Col
            size={2}
          >
            <NavLinkStore
              iconType={iconTypes.GEAR}
              iconTypeHover={iconTypes.GEAR_SOLID}
              link="https://dealemapp.com/#intro"
              text={switchLanguage(locale, texts).features}
            />
          </Col>
          <Col
            size={2}
          >
            <NavLinkStore
              iconType={iconTypes.DIAMOND}
              iconTypeHover={iconTypes.DIAMOND_SOLID}
              link="https://dealemapp.com/#premium"
              text={switchLanguage(locale, texts).premium}
            />
          </Col>
          <Col
            size={1}
            center
            ver
          >
            <DealMenuBorder />
          </Col>
          <Col
            size={1}
            ver
          >
            <NavAvatar />
          </Col>
          <Col size={1} />
        </Row>
      </Container>
    </Row>
  );
};


export default (LoginNavDesktop);

const Container = styled.div`
    width: 768px;
  `;
