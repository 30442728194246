import useWindowScrollPosition from '@rehooks/window-scroll-position';
import React, { memo, useEffect, useState } from 'react';
import Typist from 'react-typist';
import styled from 'styled-components';
import useWindowSize from '@rehooks/window-size';

import {
  Col,
  GREY_DARK,
  Row,
  SHADOW_GREY_PHONE_ICON,
  shadowNavAvatar,
  WHITE_DARK,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';
import {
  ARROW_LEFT,
  ARROW_RIGHT,
  CLOSE,
  CLOSE_WINDOW,
  HIDE_WINDOW,
} from '../../../../styles/iconTypes';
import www from '../../../../assets/www.jpg';
import wwwTablet from '../../../../assets/wwwTablet.jpg';

const Browser = () => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  const [isAnimOn, toggleAnim] = useState(false);
  const position = useWindowScrollPosition();
  useEffect(() => {
    if (position.y > 4000) {
      toggleAnim(true);
    }
  }, [position]);
  return (
    <Row
      center
    >
      <Container>
        <Col size={1} />
        <Col
          size={10}
          center
        >
          <Wrapper>
            <BrowserShape>
              <BrowserTopContainer>
                <Icon
                  height="14"
                  width="14"
                  iconType={HIDE_WINDOW}
                  fill={GREY_DARK}
                />
                <IconWrapper>
                  <Icon
                    height="14"
                    width="14"
                    iconType={CLOSE_WINDOW}
                    fill={GREY_DARK}
                  />
                </IconWrapper>
              </BrowserTopContainer>
              <BrowserMiddleContainer>
                <Icon
                  height="10"
                  width="10"
                  iconType={ARROW_LEFT}
                  fill={GREY_DARK}
                  stroke={GREY_DARK}
                  strokeWidth="2"
                />
                <IconWrapper>
                  <Icon
                    height="10"
                    width="10"
                    iconType={ARROW_RIGHT}
                    fill={GREY_DARK}
                    stroke={GREY_DARK}
                    strokeWidth="2"
                  />
                </IconWrapper>
                <IconWrapper>
                  <Icon
                    height="10"
                    width="10"
                    iconType={CLOSE}
                    fill={GREY_DARK}
                    stroke={GREY_DARK}
                    strokeWidth="2"
                  />
                </IconWrapper>
                <BrowserSearchBar>
                  {isAnimOn
                    ? (
                      <Typist>
                        http://dealem.app/deal/
                      </Typist>
                    ) : null}
                </BrowserSearchBar>
              </BrowserMiddleContainer>
              <BrowserBottomContainer>
                <Col size={6} />
                <Col size={6}>
                  <BrowserImage
                    src={innerWidth > 1024 ? www : wwwTablet}
                    alt="ala"
                  />
                </Col>
              </BrowserBottomContainer>
            </BrowserShape>
          </Wrapper>
        </Col>
        <Col size={1} />
      </Container>
    </Row>
  );
};

export default memo(Browser);


const Container = styled.div`
  display: flex;
`;

const BrowserShape = styled.div`
     border: 1px ${GREY_DARK} dashed;
     border-top-left-radius: 12px;
     border-top-right-radius: 12px;
     ${shadowNavAvatar(SHADOW_GREY_PHONE_ICON)}
     background:${WHITE_DARK};
`;

const BrowserTopContainer = styled.div`
    border-bottom: 1px ${GREY_DARK} dashed;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12px;
`;

const BrowserMiddleContainer = styled.div`
    border-bottom: 1px ${GREY_DARK} dashed;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 4px 12px;

`;

const BrowserSearchBar = styled.div`
   width: 100%;
   height: 100%;
   border: 1px ${GREY_DARK} dashed;
   font-size: 12px;
    letter-spacing: -0.5px;
    color: GREY_DARK;
    display: flex;
    align-items: center;
    padding-left: 12px;
    margin-left: 12px;
`;

const BrowserBottomContainer = styled.div`
    width: 100%;
    padding: 5px;

`;

const IconWrapper = styled.div`
  margin-left: 12px;
  display:flex;
  align-items: center;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const BrowserImage = styled.img`
    @media only screen and (max-width: 1200px){
    width: 339px;
    height: 100%;
  }
  width: 760px;
  height: 760px;
  background: white;
`;
