import firebase from '../../firebase';
import * as actionTypes from './actionTypes';


export function userCheck() {
  return (dispatch: any) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch({
          type: actionTypes.USER_LOGGED_IN,
          payload: {
            userId: user.uid,
            username: user.displayName,
            lastSignIn: user.metadata.lastSignInTime,
          },
        });
      } else {
        dispatch({
          type: actionTypes.USER_NOT_LOGGED_IN,
          payload: null,
        });
      }
    });
  };
}

export function hidePublicFooter() {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.HIDE_PUBLIC_FOOTER,
      payload: false,
    });
    setTimeout(() => dispatch({
      type: actionTypes.HIDE_PUBLIC_FOOTER,
      payload: true,
    }), 1000);
  };
}


export function openMenu(isMenuOpen: boolean) {
  return ({
    type: actionTypes.OPEN_MENU,
    payload: isMenuOpen,
  });
}

export function imagesLoadingOff() {
  return ({
    type: actionTypes.IMAGES_LOADING_OFF,
  });
}
