import React from 'react';
import styled from 'styled-components';
import {
  GREY,
  ORANGE,
  Row,
  Col,
  GREY_MEDIUM,
  TEXT_TITLE_INFOCARD,
  FONT_SIZE_BIG,
  LETTER_SPACING_NEG,
  DIST_MEDIUM,
} from '../../../styles/index';


interface IInfoCardDahedProps {
  title: string;
  description: string;
}

const InfoCardDahed: React.FunctionComponent<IInfoCardDahedProps> = ({
  title,
  description,
}) => (
  <CardContainer>
    <Row
      md
    >
      <Col size={1} />
      <Col size={8}>
        <TitleInfoCard>
          {title}
        </TitleInfoCard>
      </Col>
      <Col size={3} />
    </Row>
    <Row
      md
    >
      <Col size={1} />
      <Col size={10}>
        <TextInfoCard>
          {description}
        </TextInfoCard>
      </Col>
      <Col size={1} />
    </Row>
    <Row
      md
    >
      <Col size={1} />
      <Col size={11}>
        <Border />
      </Col>
    </Row>
  </CardContainer>
);

export default InfoCardDahed;

const TextInfoCard = styled.span`
    font-size: ${FONT_SIZE_BIG};
    letter-spacing: ${LETTER_SPACING_NEG};
    color: ${GREY_MEDIUM};
`;

const TitleInfoCard = styled.span`
    ${TEXT_TITLE_INFOCARD}
    color: ${GREY}
`;

const CardContainer = styled.div`
    max-width: 360px;
    height: 100%;
    border-radius: 5; 
    border-width: 1px;
    border-style: dashed; 
    border-color: ${GREY};
    justify-content: center;
    align-items: center;
`;


const Border = styled.div`
  border: 1px solid ${ORANGE};
  width: ${DIST_MEDIUM};
  height: 1px;
  margin-bottom: ${DIST_MEDIUM};
`;
