import React from 'react';
import styled from 'styled-components';

import apBadge from '../../../assets/apBadge.svg';
import gpBadge from '../../../assets/gpBadge.svg';
import {
  Col, DIST_MEDIUM, DIST_SMALL, Row,
} from '../../../styles';

const IdeaStoreBadges = () => (
  <Row bgr>
    <Col size={1} />
    <BadgesColumn size={9}>
      <a
        href="https://apps.apple.com/app/id1483466835"
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
      >
        <img
          src={apBadge}
          height="48px"
          alt="app store"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.eodcode.dealem.bodhidharma"
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
      >
        <GpBadge
          src={gpBadge}
          height="48px"
          alt="google play"
        />
      </a>
    </BadgesColumn>
    <Col size={1} />
  </Row>
);


export default IdeaStoreBadges;

const BadgesColumn = styled(Col)`
  margin-bottom: 24px;
  @media only screen and (max-width: 425px){
     justify-content: center;
  }
`;

const GpBadge = styled.img`
  margin-left: ${DIST_MEDIUM};
  @media only screen and (max-width: 375px){
    margin-left: ${DIST_SMALL};
  }
`;
