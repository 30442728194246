import React, { FC } from 'react';
import { Col, Row } from '../../../styles';
import Icon from '../../../styles/Icon';
import MenuItemLinkLocal from './MenuItemLinkLocal';
import MenuItemLinkToPublic from './MenuItemLinkToPublic';


type Props = {
  lg?: boolean;
  sm?: boolean;
  md?: boolean;
  title: string;
  iconType: string;
  link: string;
  offset?: string;
  toPublic?: boolean;
}

const MenuItem: FC <Props> = ({
  lg,
  sm,
  md,
  title,
  iconType,
  link,
  offset,
  toPublic,
}) => (
  <Row
    center
    ver
    lg={lg}
    md={md}
    sm={sm}
  >
    <Col
      size={2}
      center
      ver
    >
      <Icon
        height="18"
        width="18"
        iconType={iconType}
        fill="white"
      />
    </Col>
    <Col
      size={8}
      ver
      center
    >
      {toPublic
        ? (
          <MenuItemLinkToPublic
            link={link}
            title={title}
          />
        )
        : (
          <MenuItemLinkLocal
            link={link}
            title={title}
            offset={offset}
          />
        )}
    </Col>
    <Col
      ver
      center
      size={2}
    />
  </Row>
);

export default MenuItem;
