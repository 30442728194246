import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Col, Row, VIDEO_LINK } from '../../../../styles';

const IntroVideoMobile = () => (
  <Row
    center
  >
    <Col size={1} />
    <Col
      size={10}
      center
    >
      <ModalImage>
        <ReactPlayer
          url={VIDEO_LINK}
          playing={false}
          controls
          width="100%"
          volume={0.6}
        />
      </ModalImage>
    </Col>
    <Col size={1} />
  </Row>
);


export default IntroVideoMobile;

const ModalImage = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 99;
    object-fit: cover;
    background-color: white;
    margin-top: 12px;
    margin-bottom: 24px;
    border-radius: 4px;
`;
