import React, { useContext } from 'react';
import styled from 'styled-components';

import Language from '../../../../contexts/Language';
import switchLanguage from '../../../../hoc/switchLanguage';
import {
  FONT_FUTURA, GREY_DARK, GREY_MEDIUM, H3,
} from '../../../../styles';
import * as iconTypes from '../../../../styles/iconTypes';
import IntroCard from '../IntroStyle/IntroCard';
import IntroDealExample from '../Comments/IntroComment';

// CONTENT
export const texts = {
  en: {
    subtitle: 'Sharing challenges',
    title: 'Make your challenge public',
    why: 'WHY IT WORKS LIKE THAT ?',
    publicTitle: 'Public',
    publicTitleLight: ' vs private challenge contract',
    publicDescription: 'You can switch your challenge from private to public anytime.\nIf you want to let others know about your challenge, create a public link and spread the news! Everyone can follow your progress in their browser or/and in Dealem app!',
    publicExample: 'Eva and Jenny should know that deal exists !',
    shareTitle: 'Share',
    shareTitleLight: ' your deal',
    shareDescription: 'Send public link via sms, social media, or email to let others know about your deals. Praise and spread the news about yours challenges so far via sms, your Social Media or mail.',
    privateTitle: 'Change',
    privateTitleLight: ' your mind',
    privateDescription: 'You can make deal private again anytime.',
    shareExample: 'http://dealem.app/deal/chall-134 ',
    descriptionWhy: 'Share your deal whenever you want to get more people cheering for you or create more social pressure to fulfill your commitment or just to have more fun.',
  },
};

const ShareCards = () => {
  const locale = useContext(Language);
  return (
    <Container>
      <Subtile>
        {switchLanguage(locale, texts).subtitle}
      </Subtile>
      <Title>
        {switchLanguage(locale, texts).title}
      </Title>

      <IntroCard
        title={switchLanguage(locale, texts).publicTitle}
        titleLight={switchLanguage(locale, texts).publicTitleLight}
        description={switchLanguage(locale, texts).publicDescription}
        iconType={iconTypes.SHARE}
        titleTagComponent={<H3 />}
      />
      <IntroDealExample
        text={switchLanguage(locale, texts).shareExample}
        imageProf
      />
      <IntroCard
        title={switchLanguage(locale, texts).privateTitle}
        titleLight={switchLanguage(locale, texts).privateTitleLight}
        description={switchLanguage(locale, texts).privateDescription}
        iconType={iconTypes.CLOSE}
        titleTagComponent={<H3 />}
      />
    </Container>
  );
};

export default ShareCards;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 160px;
  @media only screen and (max-width: 1200px){
    padding-top: 24px;
  }
  @media only screen and (max-width: 800px){
    padding-right: 20px;
  }
`;

const Subtile = styled.h2`
  font-size: 14px;
  color: ${GREY_MEDIUM};
  letter-spacing: 2.67px;
  text-transform: uppercase;
  font-weight: bold;
`;

const Title = styled.h1`
  font-size: 32px;
  color: ${GREY_DARK};
  letter-spacing: -2px;
  line-height: 48px;
  font-weight: 400;
  margin-bottom: 32px;
  font-family: ${FONT_FUTURA};
`;

export const Border = styled.div`
    border-top: 1px dashed ${GREY_DARK};
    height: 1px; 
    width: 42px;
`;
