import React from 'react';
import styled from 'styled-components';

import {
  ORANGE,
  Col,
  GREY_DARK,
  Row,
  TEXT_CARD_TITLE,
  TEXT_CARD_TITLE_LIGHT,
} from '../../../../styles';

const IdeaCurvedBottom = () => (
  <Row
    center
    sm
  >
    <Col size={4} />
    <Col
      size={3}
      sm
    >
      <LineCurvedBottom
        height="100px"
      />
    </Col>
    <Col
      size={6}
      center
    >
      <ContainerStyleSmall>
        <Row
          center
        >
          <TitleCard
            style={{
              paddingRight: '2px',
              textAlign: 'end',
            }}
          >
            What
          </TitleCard>
          <TitleCardLight
            style={{
              paddingLeft: '2px',
            }}
          >
            if ?
          </TitleCardLight>
        </Row>
      </ContainerStyleSmall>
    </Col>
    <Col
      size={3}
      jEnd
      sm
    >
      <LineCurvedBottomRight
        height="100px"
        colorLine
      />
    </Col>
    <Col size={4} />
  </Row>
);

IdeaCurvedBottom.propTypes = {

};

export default IdeaCurvedBottom;

const LineCurvedBottom = styled.div<{colorLine?: boolean; height: string}>`
  width: 1px;
  border-left: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  border-top: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  min-height: ${(props) => props.height};
  height: auto;
  width: 100%;
  border-top-left-radius: 10px;
`;
const LineCurvedBottomRight = styled.div<{colorLine?: boolean; height: string}>`
  width: 1px;
  border-right: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  border-top: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  min-height: ${(props) => props.height};
  height: auto;
  width: 100%;
  border-top-right-radius: 10px;
`;


export const ContainerStyle = styled.div<{shadow?: boolean; drawer?: boolean}>`
  width: 100%;
  background: white;
  box-shadow: 0 10px 40px 0 rgba(190,190,207,0.50);
  border-radius: 5px;
`;

export const ContainerStyleSmall = styled.div<{shadow?: boolean; drawer?: boolean}>`
  border-radius: 5px;
  width: 100%;
`;

const TitleCard = styled.div`
      ${TEXT_CARD_TITLE}
`;


const TitleCardLight = styled.div`
      ${TEXT_CARD_TITLE_LIGHT}
`;
