import React from 'react';
import AttachmentRow from '../IntroStyle/AttachmentRow';
import FeaturesCards from './FeaturesCards';

const FeaturesDesktop = () => (
  <AttachmentRow>
    <FeaturesCards />
  </AttachmentRow>
);

export default FeaturesDesktop;
