import React from 'react';
import styled from 'styled-components';

import { Row } from '../../../../styles';
import ArrowsRow from './ArrowsRow';
import IdeaLine from './IdeaLine';

const IdeaTopArrows = () => (
  <Row
    center
  >
    <ThinContainer>
      <ArrowsRow />
      <IdeaLine />
    </ThinContainer>
  </Row>
);


export default IdeaTopArrows;


const ThinContainer = styled.div` 
  width: 360px;
`;
