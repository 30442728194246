import React from 'react';

import HomeFooter from '../components/Footer/Home/HomeFooter';
import Intro from '../components/Home/Intro/Intro';
import Arrows from '../components/Home/Arrows/Arrows';
import CheckIdeas from '../components/Home/CheckIdeas/CheckIdeas';
import HomeHeader from '../components/Home/Header/HomeHeader';
import Premium from '../components/Home/Premium/Premium';
import SM from '../components/Home/SM/SM';
import HomeNav from '../components/Nav/Public/Home/HomeNav';
import LoadingHome from './LoadingHome';
import Cookies from '../components/Cookies/Cookies';

const Home = () => (
  <div>
    <HomeNav />
    <HomeHeader />
    <Arrows />
    <Intro />
    <CheckIdeas />
    <SM />
    <Premium />
    <HomeFooter />
    <LoadingHome />
    <Cookies />
  </div>
);

export default Home;
