import React, { FC, useContext } from 'react';
import styled from 'styled-components';


import Img from 'react-image';
import Language from '../../../../contexts/Language';
import switchLanguage from '../../../../hoc/switchLanguage';
import {
  Col,
  GREY,
  GREY_DARK,
  Row,
  TEXT_CARD_SMALL,
  LINK_NO_STYLE,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';

const texts = {
  en: {
    contractIdea: 'Contract idea created by',
    penalty: 'Challenge failed - consequences',
    surprize: 'is surprise',
  },
};

const DealCardAuthor: FC<{author: any}> = ({
  author,
}) => {
  const locale = useContext(Language);
  if (author) {
    const { bannerUri, authorUri } = author;
    return (
      <>
        <a
          style={LINK_NO_STYLE}
          href={authorUri ?? ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row
            sm
          >
            <Col size={1} />
            <Col size={1}>
              <Wrapper>
                <CircleCol>
                  <Icon
                    height="14"
                    width="14"
                    iconType={iconTypes.BULB}
                    fill={GREY_DARK}
                  />
                </CircleCol>
              </Wrapper>
            </Col>
            <Col
              size={8}
            >
              <TextDark>{switchLanguage(locale, texts).contractIdea}</TextDark>
            </Col>
            <Col size={1}>
              <Icon
                height="14"
                width="14"
                iconType={iconTypes.ARROW_RIGHT}
                fill={GREY_DARK}
              />
            </Col>
            <Col size={1} />
          </Row>

          <Row
            sm
            center
          >
            <Col size={2} />
            <Col
              size={9}
            >

              <ImageLogo
                src={bannerUri ?? ''}
                loader={
                  <LogoPlaceholder />
                }
              />
            </Col>
            <Col size={1} />
          </Row>
        </a>
      </>
    );
  }
  return null;
};


export default DealCardAuthor;


export const Container = styled.div`
    display: flex;
`;

export const CircleCol = styled.div`
  border-width: 1.2px;
  border-style: dashed;
  border-color: ${GREY};
  border-radius: 10px;
  width:  20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const TextLight = styled.div`
    ${TEXT_CARD_SMALL}
    color: ${GREY};
    line-height: 20px;
    align-items: center;
`;

export const TextDark = styled(TextLight)`
  color: ${GREY_DARK};
  align-items: center;
  margin-right: 4px;

`;

export const Wrapper = styled.div`
    margin-right: 4px;
`;

export const ImageLogo = styled(Img)<{grey?: boolean}>`
   @media only screen and (max-width: 426px){
    max-width: 218px;
  }
    max-width: 242px;
    height: 100%;
    object-fit: cover;
    z-index:2;
`;

const LogoPlaceholder = styled.div`
     width: 100%;
     height: 100%;
     border: 0.5px ${GREY_DARK} dashed;
     border-radius: 2px;
`;
