import useWindowSize from '@rehooks/window-size';
import React from 'react';
import IntroDealDesktop from './IntroDealDesktop';
import IntroDealTablet from './IntroDealTablet';

const IntroDeal = () => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;

  if (innerWidth < 1024) {
    return (
      <IntroDealTablet />
    );
  }
  return (
    <IntroDealDesktop />
  );
};

export default IntroDeal;
