import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import firebase from '../../firebase';
import convertToDate from '../../hoc/convertToDate';
import { CommittedId, DealId } from '../../interfaces/dealTypes';
import {
  ORANGE,
  CARD_CIRCLE_SIZE,
  CardAvatarWrapperStyle,
  CardTitleDescriptionTextStyle,
  Col,
  FONT_SIZE_MEDIUM,
  GREY_DARK,
  GREY_MEDIUM,
  LETTER_SPACING_NEG,
  REPORT_MODAL_STYLE,
  Row,
  SHADOW_GREY,
  shadowCard,
  TEXT_CARD_SMALL,
} from '../../styles';
import Icon from '../../styles/Icon';
import * as iconTypes from '../../styles/iconTypes';
import ImageCardAvatar from '../UI/Image/ImageCardAvatar';
import ImageCardAvatarPlaceholder from '../UI/Image/ImageCardAvatarPlaceholder';
import DealCommentReport from './DealCommentReport';
import DealDeleteComment from './DealDeleteComment';

interface IDealItemCommentProps {
  leftComment?: boolean;
  commentText: string;
  commentDate: any;
  userId: string;
  dealId: DealId;
  commentId: string;
  committedId: CommittedId;
  witnessId: string;
  witnessProfilePhoto: string;
  committedProfilePhoto: string;
  committedUsername: string;
  witnessUsername: string;
}

const DealItemComment: React.FunctionComponent<IDealItemCommentProps> = ({
  leftComment,
  commentText,
  commentDate,
  userId,
  dealId,
  commentId,
  committedId,
  witnessId,
  witnessProfilePhoto,
  committedProfilePhoto,
  committedUsername,
  witnessUsername,
}) => {
  const [username, setUsername] = useState('');
  const [imageUrl, setImage] = useState<string|null>(null);
  const [isImageUrlFetched, fetchingImage] = useState(false);
  const [isModalOpen, openModal] = useState(false);
  const { currentUser } = firebase.auth();
  const cUserId = currentUser ? currentUser.uid : '';

  let letter = '';
  if (username) {
    letter = username.charAt(0);
  }

  useEffect(() => {
    if (userId === witnessId) {
      setUsername(witnessUsername);
      setImage(witnessProfilePhoto);
      fetchingImage(true);
    } else
    if (userId === committedId) {
      setUsername(committedUsername);
      setImage(committedProfilePhoto);
      fetchingImage(true);
    } else {
      firebase.firestore()
        .collection('users')
        .doc(userId)
        .collection('publicData')
        .doc('publicData')
        .get()
        .then((snapshot: any) => {
          if (snapshot.data()) {
            setImage(snapshot.data().profilePhoto);
            setUsername(snapshot.data().username);
            fetchingImage(true);
          } else {
            setUsername('Unknown');
            fetchingImage(true);
          }
        });
    }
  }, [committedId, committedProfilePhoto, committedUsername, userId, witnessId, witnessProfilePhoto, witnessUsername]);


  const renderModal = () => (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => openModal(false)}
      // @ts-ignore
      style={REPORT_MODAL_STYLE}
    >
      {(cUserId === userId || cUserId === committedId)
        ? (
          <DealDeleteComment
            dealId={dealId}
            commentId={commentId}
            closeModal={() => openModal(false)}
          />
        )
        : (
          <DealCommentReport
            dealId={dealId}
            commentId={commentId}
            reportedId={userId}
            closeModal={() => openModal(false)}
          />
        )}

    </Modal>
  );

  const renderAvatar = () => {
    if (isImageUrlFetched && imageUrl) {
      return (
        <ImageCardAvatar
          image={imageUrl}
        />
      );
    }
    if (isImageUrlFetched && !imageUrl) {
      return (
        <CardAvatarWrapperStyle>
          <Letter>
            {letter}
          </Letter>
        </CardAvatarWrapperStyle>
      );
    }
    return (
      <ImageCardAvatarPlaceholder />
    );
  };


  const renderRightComment = () => (
    <>
      <MessageRow>
        <Col size={2}>
          {renderAvatar()}
        </Col>
        <MessageTriangle size={1} />
        <Message
          size={8}
        >
          <Col
            ver
            size={11}
          >
            {commentText}
          </Col>
          {currentUser
            ? (
              <Col
                cursor
                size={1}
                center
                ver
                onClick={() => openModal(true)}
              >
                <Icon
                  height="14"
                  width="14"
                  iconType={iconTypes.MORE}
                  fill="white"
                />
              </Col>
            ) : <Col size={1} />}
        </Message>
      </MessageRow>
      <CommentUsernameRow>
        <Col
          size={3}
        />
        <Col
          size={9}
        >
          <CardTitleDescriptionTextStyle>
            <Name>{username}</Name>
            {moment(commentDate).calendar(undefined, { sameElse: 'MMMM Do YYYY' })}
          </CardTitleDescriptionTextStyle>
        </Col>
      </CommentUsernameRow>
    </>
  );

  const renderLeftComment = () => (
    <>
      <MessageRow
        leftComment={leftComment}
      >
        <Message
          size={9}
        >
          <Col
            ver
            size={11}
          >
            {commentText}
          </Col>
          {currentUser
            ? (
              <Col
                cursor
                size={1}
                center
                ver
                onClick={() => openModal(true)}
              >
                <Icon
                  height="14"
                  width="14"
                  iconType={iconTypes.MORE}
                  fill="white"
                />
              </Col>
            ) : <Col size={1} />}
        </Message>
        <MessageTriangleLeft size={1} />
        <Col size={2}>
          {renderAvatar()}
        </Col>
      </MessageRow>
      <CommentUsernameRow>
        <Col
          size={9}
        >
          <CardTitleDescriptionTextStyle>
            <Name>{username}</Name>
            {convertToDate(commentDate)}
          </CardTitleDescriptionTextStyle>
        </Col>
        <Col
          size={3}
        />
      </CommentUsernameRow>
    </>
  );

  if (leftComment) {
    return (
      <>
        {renderModal()}
        {renderLeftComment()}
      </>
    );
  }
  return (
    <>
      {renderModal()}
      {renderRightComment()}
    </>
  );
};

export default memo(DealItemComment);

const Letter = styled.div`
    font-size: 19px;
    color: #FFFFFF;
    font-weight: 400;
`;


const MessageRow = styled(Row)<{leftComment?: boolean}>`
    position:relative;
    align-items: center;
    margin-right: -20px;
    padding-left: 40px;
    ${(props) => (props.leftComment
    ? ('margin-right: 20px; margin-leftComment: -20px; padding-leftComment: 0;'
    ) : '')
}
    justify-content: flex-end;
`;

const Name = styled.span`
  color: ${GREY_DARK};
  padding-right: 4px;
`;

const MessageTriangle = styled(Col)`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent ${ORANGE} transparent transparent;
`;

const MessageTriangleLeft = styled(Col)`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent ${ORANGE};
`;

const Message = styled(Col)`
  ${TEXT_CARD_SMALL}
  background: ${ORANGE};
  ${shadowCard(SHADOW_GREY)};
  border-radius: 5px;
  padding: 20px;
  color: #FFFFFF;
  line-height: 20px;
`;

const CommentUsernameRow = styled(Row)<{leftComment?: boolean}>`
    align-items: center;
    margin-bottom: 24px;
    margin-top: 24px;
    text-align: ${(props) => (props.leftComment ? 'end' : '')};
    padding-left: ${(props) => (props.leftComment ? '0' : '40px')};
    margin-right: ${(props) => (props.leftComment ? '14px' : '-20px')};
`;


export const CardProfileImage = styled.img`
    ${CARD_CIRCLE_SIZE}
`;

export const FormIconLinkStyle = styled.span`
    font-size: ${FONT_SIZE_MEDIUM};
    color: ${GREY_MEDIUM};
    letter-spacing: ${LETTER_SPACING_NEG};
  `;
