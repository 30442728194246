import React from 'react';
import Checkbox from 'react-simple-checkbox';
import styled from 'styled-components';

import {
  FONT_SIZE_MEDIUM,
  GREY_MEDIUM,
  LETTER_SPACING_NEG,
  Row,
} from '../../styles';

export const texts = {
  en: {
    button: 'Report comment',
    reason: 'reason for report',
    terms: 'violation of app terms',
    myRights: 'violation of my rights',
    otherReason: 'other reason',
  },
};

const DealCommentReportReason = ({ title, checked, onChange }) => (
  <Row
    center
    md
    ver
    cursor
    onClick={onChange}
  >
    <TextWrapper>
      <FormIconLinkStyle>
        {title}
      </FormIconLinkStyle>
    </TextWrapper>
    <div>
      <Checkbox
        borderThickness={1}
        checked={checked}
        onChange={onChange}
      />
    </div>
  </Row>
);
export default DealCommentReportReason;

export const FormIconLinkStyle = styled.span`
    font-size: ${FONT_SIZE_MEDIUM};
    color: ${GREY_MEDIUM};
    letter-spacing: ${LETTER_SPACING_NEG};
  `;

const TextWrapper = styled.div`
    padding-top: 3px;
`;
