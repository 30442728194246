import React, { useContext } from 'react';
import styled from 'styled-components';

import Language from '../../../contexts/Language';
import switchLanguage from '../../../hoc/switchLanguage';
import {
  FACEBOOK_LINK,
  GREY, GREY_DARK, INSTAGRAM_LINK, TWITTER_LINK,
} from '../../../styles';
import { Col, Row } from '../../../styles/grid';
import * as iconTypes from '../../../styles/iconTypes';
import DealPublicFooterIcon from '../../Footer/DealPublicFooter/DealPublicFooterIconSM';
import DealMenuBorder from '../../UI/Cards/DealCard/DealMenuBorder';
import IntroDealExample from '../Intro/Deal/IntroDealExampleSM';

export const texts = {
  en: {
    follow: 'FOLLOW DEALEM, they have cookies <3',
    desc: 'Creative, fun ideas of challenges and consequences. Updates, and many more.',
  },
};

const SMFollow = () => {
  const locale = useContext(Language);
  return (
    <Container>
      <Row jEnd>
        <IntroDealExample
          text={switchLanguage(locale, texts).follow}
          rightAvatar

        />
      </Row>
      <Row
        sm
      >
        <DealPublicFooterIcon
          iconType={iconTypes.INSTAGRAM}
          iconTypeHover={iconTypes.INSTAGRAM_SOLID}
          link={INSTAGRAM_LINK}
        />
        <Col
          size={1}
          center
          ver
        >
          <DealMenuBorder />
        </Col>
        <DealPublicFooterIcon
          iconType={iconTypes.TWITTER}
          iconTypeHover={iconTypes.TWITTER_SOLID}
          link={TWITTER_LINK}
        />
        <Col
          size={1}
          center
          jEnd
        >
          <DealMenuBorder />
        </Col>
        <DealPublicFooterIcon
          iconType={iconTypes.FACEBOOK}
          iconTypeHover={iconTypes.FACEBOOK_SOLID}
          link={FACEBOOK_LINK}
        />
      </Row>
      <Row
        center
        bgr
      >
        <Description>
          {switchLanguage(locale, texts).desc}
        </Description>
      </Row>
    </Container>
  );
};

export default SMFollow;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 42px;
  @media only screen and (max-width: 1024px){
    padding-top: 24px;
  }
`;


export const Border = styled.div`
    border-top: 1px dashed ${GREY_DARK};
    height: 1px; 
    width: 42px;
`;

const Description = styled.p`
   font-size: 16px;
   color: ${GREY};
   letter-spacing: -1px;

`;
