import styled from 'styled-components';
import {
  ORANGE,
  ORANGE_HOVER,
  DIST_BIG,
  DIST_MEDIUM,
  DIST_SMALL,
  ERROR_COLOR,
  FONT_HEAVY,
  FONT_SIZE_SMALL,
  GREY_LIGHT,
  GREY_MEDIUM,
  LETTER_SPACING_X_POS,
  shadowButton,
  shadowButtonRound,
} from './index';
import { DIST_LARGE } from './sizes';

export const Button = styled.button<{ error?: boolean }>`
    background-color: ${(props) => (props.error ? ERROR_COLOR : ORANGE)};
    box-shadow: ${(props) => (props.error
    ? shadowButton(ERROR_COLOR) : shadowButton(ORANGE))};
    border-radius: ${DIST_MEDIUM};
    font-weight:${FONT_HEAVY};
    font-size: ${FONT_SIZE_SMALL};
    letter-spacing: ${LETTER_SPACING_X_POS};
    color: #FFFFFF;
    text-align: center;
    padding: ${DIST_MEDIUM} ${DIST_BIG};
    border: none;
    text-transform: uppercase;
    :hover{
    outline-style: none !important;
    cursor: pointer;
    ${(props) => (props.error ? ERROR_COLOR : ORANGE)};
    }
    :active{
        outline-style: none;
    }
    :focus{
        outline-style: none;
    }
`;

export const ButtonHeader = styled(Button)`
  background: ${ORANGE};
  box-shadow: 0 10px 20px 0 ${ORANGE};
  padding: ${DIST_MEDIUM} ${DIST_LARGE};
  border-radius: 30px;
  :hover{
    background-color:  ${ORANGE_HOVER};
    box-shadow: 0 10px 20px 0 ${ORANGE_HOVER};
  }
`;


export const ButtonRound = styled.button<{ error?: boolean | null }>`
    ${(props) => (props.error
    ? `background: ${ERROR_COLOR};
       box-shadow: 0 -2px 14px 0 ${ERROR_COLOR};
    `
    : `background: ${ORANGE};
       box-shadow: 0 -2px 14px 0 ${ORANGE};
       :hover{
         background-color:  ${ORANGE_HOVER};
         ${shadowButtonRound(ORANGE_HOVER)};
      }`)
}
    border-radius: 100%;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 24px;
  :hover{
    outline-style: none !important;
    cursor: pointer;
    }
    :active{
        outline-style: none;
    }
    :focus{
        outline-style: none;
    }
`;


export const ButtonEmpty = styled.button`
  font-size: ${FONT_SIZE_SMALL};
  letter-spacing: ${LETTER_SPACING_X_POS};
  color: ${GREY_MEDIUM};
  border: 1px solid ${GREY_LIGHT};
  border-radius: ${DIST_BIG};
  padding: ${DIST_SMALL} ${DIST_MEDIUM};
  background: white;
  text-transform: uppercase;
  :hover{
    outline-style: none;
    cursor: pointer;
    color: ${ORANGE};
    border-color: ${ORANGE};
  }
  :active{
    outline-style: none;
  }
  :focus{
    outline-style: none;
  }
`;


export const ButtonNoStyle = styled.button`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-transform: inherit;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    text-decoration: none;
`;
