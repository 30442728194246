import React from 'react';
import styled from 'styled-components';

import PrivacyNav from '../components/Nav/Public/Privacy/PrivacyNav';
import PublicGoBackBar from '../components/PublicGoBackBar';
import { ORANGE, Row } from '../styles';
import privacy from '../law/privacy';
import {
  APPLE_PRIVACY, ADMOB_PRIVACY, ANALYTICS_PRIVACY, CRASHLYTYCS_PRIVACY, FACEBOOK_PRIVACY, GOOGLE_PRIVACY, MAILCHIMP_PRIVACY,
} from '../styles/links';

const Privacy = () => (
  <div>
    <PrivacyNav />
    <Row
      center
      bg
    >
      <Container>
        <Title>{privacy.title}</Title>
        <Subtitle>{privacy.subtitle1}</Subtitle>
        <p>
          {privacy.desc1}
        </p>
        <Subtitle>{privacy.subtitle2}</Subtitle>
        <p>
          {privacy.desc2}
        </p>
        <ul>
          <li>
            <Link
              href={APPLE_PRIVACY}
              target="_blank"
              rel="noopener noreferrer"
            >
              Apple
            </Link>
          </li>
          <li>
            <Link
              href={GOOGLE_PRIVACY}
              target="_blank"
              rel="noopener noreferrer"
            >
              Google
            </Link>
          </li>
          <li>
            <Link
              href={FACEBOOK_PRIVACY}
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </Link>
          </li>
        </ul>
        <Subtitle>{privacy.subtitle3}</Subtitle>
        <p>
          {privacy.desc3}
        </p>
        <Subtitle>{privacy.subtitle4}</Subtitle>
        <p>
          {privacy.desc4}
        </p>
        <Subtitle>{privacy.subtitle5}</Subtitle>
        <p>
          {privacy.desc5}
        </p>
        <p>
          {privacy.links1}
        </p>
        <ul>
          <li>
            <Link
              href={GOOGLE_PRIVACY}
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play Services
            </Link>
          </li>
          <li>
            <Link
              href={ADMOB_PRIVACY}
              target="_blank"
              rel="noopener noreferrer"
            >
              AdMob
            </Link>
          </li>
          <li>
            <Link
              href={ANALYTICS_PRIVACY}
              target="_blank"
              rel="noopener noreferrer"
            >
              Google
              Analytics for Firebase
            </Link>
          </li>
          <li>
            <Link
              href={CRASHLYTYCS_PRIVACY}
              target="_blank"
              rel="noopener noreferrer"
            >
              Firebase
              Crashlytics
            </Link>
          </li>
          <li>
            <Link
              href={MAILCHIMP_PRIVACY}
              target="_blank"
              rel="noopener noreferrer"
            >
              Mailchimp
            </Link>
          </li>
        </ul>
        <Subtitle>{privacy.subtitle6}</Subtitle>
        <p>
          {privacy.desc6}
        </p>
        <Subtitle>{privacy.subtitle7}</Subtitle>
        <p>
          {privacy.desc7}
        </p>
        <Subtitle>{privacy.subtitle8}</Subtitle>
        <p>
          {privacy.desc8}
        </p>
        <Subtitle>{privacy.subtitle9}</Subtitle>
        <p>
          {privacy.desc9}
        </p>
        <Subtitle>{privacy.subtitle10}</Subtitle>
        <p>
          {privacy.desc10}
        </p>
        <Subtitle>{privacy.subtitle11}</Subtitle>
        <p>
          {privacy.desc11}
        </p>
        <Subtitle>{privacy.subtitle12}</Subtitle>
        <p>
          {privacy.desc12}
        </p>
      </Container>
    </Row>
    <PublicGoBackBar />
    <Row lg />
  </div>
);

export default Privacy;

const Container = styled.div`
max-width: 768px;
margin:0 24px;
`;
const Title = styled.h2`
justify-content: center;
margin-bottom: 48px;
`;

const Subtitle = styled.h4`
font-weight: 700;
margin: 24px 0;
`;

export const Link = styled.a`
  color:${ORANGE};
  text-decoration: underline;
`;
