import _ from 'lodash';
import React, {
  FC, useContext, useState,
} from 'react';
import styled from 'styled-components';

import Language from '../../contexts/Language';
import convertToDate from '../../hoc/convertToDate';
import switchLanguage from '../../hoc/switchLanguage';
import { Ideal } from '../../interfaces/dealTypes';
import {
  Col, DealCardContainerStyle, DIST_LARGE, Row,
} from '../../styles';
import DealCardAuthor from '../UI/Cards/DealCard/DealCardAuthor';
import DealCardDealname from '../UI/Cards/DealCard/DealCardDealname';
import DealCardPrize from '../UI/Cards/DealCard/DealCardPrize';
import DealCardTitle from '../UI/Cards/DealCard/DealCardTitle';
import DealCardWitness from '../UI/Cards/DealCard/DealCardWitness';
import DealItemCommentList from './DealCommentList';
import DealItemDates from './DealItemDates';
import DealItemDone from './DealItemDone';
import DealItemDrawer from './DealItemDrawer';
import DealItemMenuBottom from './DealItemMenuBottom';
import DealItemShare from './DealItemShare';


const texts = {
  en: {
    challenge: 'challenge',
    surprize: ' is surprize.',
    errorTitle: 'Error',
    errorTitleLight: ' occurred',
    errorDescription: 'Something went wrong.',
    successTitle: 'Claim',
    successTitleLight: ' send',
    successDescription: 'wait for the friend to accept it.',
  },
};

type Props = {
  author?: string;
  dealId: string;
  witnessProfilePhoto: string;
  committedProfilePhoto: string;
  commentList: {
    commentDate: string;
    commentText: string;
    userId: string;
    username: string;
    commentId: string;
  }[];
  fetchComments: any;
  setCommentLimit: any;
  commentLimit: number;
  commentsInfo: any;
  followers: any;
  isFollower: boolean;
  userId: string;
  noFollow: boolean;
  surprizeReveal: any;
  surprizePenaltyReveal: any;
  proof: any;
  startingPoint: any;
}

const DealItemPublic: FC <Ideal & Props> = (
  {
    author,
    done,
    attachment,
    committedId,
    committedUsername,
    dateCreated,
    dealname,
    dateDue,
    dateDone,
    prize,
    surprize,
    witnessId,
    witnessUsername,
    dealId,
    commentList,
    witnessProfilePhoto,
    committedProfilePhoto,
    fetchComments,
    setCommentLimit,
    commentLimit,
    commentsInfo,
    followers,
    isFollower,
    userId,
    noFollow,
    penalty,
    surprizePenalty,
    surprizeReveal,
    surprizePenaltyReveal,
    startingPoint,
    proof,
  },
) => {
  const bottomMenuInitialState = {
    areCommentsVisible: false,
    isShareVisible: false,
    areDatesVisible: false,
  };

  const [bottomMenuState, handleBottomMenuVisible] = useState<Record<string, any>>(bottomMenuInitialState);
  const [isMinimizeOn, setMinimizeOn] = useState(false);
  const locale = useContext(Language);

  const chooseBottomMenuElement = (key: string) => {
    if (bottomMenuState[key]) {
      const clearContent = _.mapValues(bottomMenuState, () => false);
      return clearContent;
    }
    const clearContent = _.mapValues(bottomMenuState, () => false);
    clearContent[key] = true;
    return clearContent;
  };

  const handleBottomMenuClick = (key: string) => {
    handleBottomMenuVisible(chooseBottomMenuElement(key));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMinimizeTextOn = (event: React.MouseEvent<HTMLInputElement>) => {
    setMinimizeOn(true);
    handleBottomMenuVisible(bottomMenuInitialState);
    setTimeout(() => setMinimizeOn(false), 100);
  };


  let letter = '';
  if (committedUsername !== undefined) {
    letter = committedUsername.charAt(0);
  }

  const {
    areCommentsVisible,
    isShareVisible,
    areDatesVisible,
  } = bottomMenuState;

  const { numberOfComments } = commentsInfo;

  return (
    <>
      <Row
        center
      >
        <Col size={1} />
        <Col
          size={10}
          center
        >
          <Container>
            <DealCardContainerStyle
              shadow
              drawer
            >
              <DealCardTitle
                letter={letter}
                title={`${committedUsername}'s `}
                titleLight={switchLanguage(locale, texts).challenge}
                description={`${convertToDate(dateCreated)}, committed to`}
                committedId={committedId}
                avatarLoading={false}
                onAvatarLoading={() => null}
                dealId={dealId}
                committedProfilePhoto={committedProfilePhoto}
              />
              <DealCardDealname
                dealname={dealname}
              />
              <DealCardWitness
                witnessUsername={witnessUsername}
                witnessProfilePhoto={witnessProfilePhoto}
              />
              <DealCardPrize
                prize={prize}
                done={done}
                surprize={surprize}
                surprizeReveal={surprizeReveal}
              />
              <DealCardPrize
                prize={penalty}
                done={done}
                surprize={surprizePenalty}
                surprizeReveal={surprizePenaltyReveal}
                penalty
              />
              <DealCardAuthor
                author={author}
              />
              <DealItemDone
                done={done}
              />
              <DealItemMenuBottom
                onBottomMenuClick={handleBottomMenuClick}
                areCommentsVisible={areCommentsVisible}
                numberOfComments={numberOfComments}
                isShareVisible={isShareVisible}
                areDatesVisible={areDatesVisible}
                isMinimizeOn={isMinimizeOn}
                onMinimizeTextOn={handleMinimizeTextOn}
                isFollower={isFollower}
                userId={userId}
                noFollow={noFollow}
                dealId={dealId}
                followers={followers}
              />
              <DealItemDates
                areDatesVisible={areDatesVisible}
                dateCreated={dateCreated}
                dateDue={dateDue}
                dateDone={dateDone}
              />
              <DealItemShare
                isShareVisible={isShareVisible}
                dealId={dealId}
              />
              <DealItemCommentList
                areCommentsVisible={areCommentsVisible}
                dealId={dealId}
                commentList={commentList}
                committedId={committedId}
                witnessId={witnessId}
                committedUsername={committedUsername}
                witnessUsername={witnessUsername}
                committedProfilePhoto={committedProfilePhoto}
                witnessProfilePhoto={witnessProfilePhoto}
                fetchComments={fetchComments}
                setCommentLimit={setCommentLimit}
                commentLimit={commentLimit}
              />
            </DealCardContainerStyle>
            <DealItemDrawer
              startingPoint={startingPoint}
              attachment={attachment}
              done={done}
              proof={proof}
              isMinimizeOn={isMinimizeOn}
              dealId={dealId}
            />
          </Container>
        </Col>
        <Col size={1} />
      </Row>
    </>
  );
};

export default DealItemPublic;


export const Container = styled.div`
    margin-top: ${DIST_LARGE};
    display: flex;
    flex-direction: column;
    align-items:center;
    margin-right: 1px;
    @media only screen and (max-width: 400px){
     width: 100%;
    }
`;
