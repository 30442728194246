import useWindowSize from '@rehooks/window-size';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  Col, FONT_SIZE_X_SMALL, GREY_MEDIUM, LETTER_SPACING_NEG, ORANGE, Row,
} from '../../../../styles';


const BadgeContainer: FC<{friend?: boolean}> = ({ friend }) => (
  <Row
    xsm
    center
  >
    <Badge>
      {friend ? 'FRIEND WILL SET IT' : ' IT WILL BE A SURPRISE'}
    </Badge>
  </Row>
);


const IdeaPrizePenalty: FC <{
  prize: string | null;
  penalty: string | null;
  friendPenalty?: boolean;
  friendPrize?: boolean;
  surprize?: boolean;
  surprizePenalty?: boolean;
}> = ({
  prize,
  penalty,
  surprize,
  surprizePenalty,
  friendPrize,
  friendPenalty,
}) => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  const pad = innerWidth > 400 ? '0 6px' : '0';

  return (
    <Row
      sm
      center
      style={{
        padding: pad,
      }}
    >
      <Col
        size={5}
        center
      >
        <div>
          {friendPrize
            ? (
              <BadgeContainer friend />
            )
            : <DealNameTextSmall>{prize}</DealNameTextSmall>}
          {surprize
            ? (
              <BadgeContainer />
            )
            : null}
        </div>
      </Col>
      {innerWidth > 400
        ? (
          <Col
            size={2}
            center
          />
        ) : null}
      <Col
        size={5}
        center
      >
        <div>
          {friendPenalty
            ? (
              <BadgeContainer friend />
            )
            : <DealNameTextSmall>{penalty}</DealNameTextSmall>}
          {surprizePenalty
            ? (
              <BadgeContainer />
            )
            : null}
        </div>
      </Col>
    </Row>
  );
};


export default IdeaPrizePenalty;

const DealNameTextSmall = styled.div`
  font-size: 11px;
  letter-spacing: ${LETTER_SPACING_NEG};
  color: ${GREY_MEDIUM};
  display: flex;
  justify-content: center;
  padding: 0 6px;
`;

const Badge = styled.div`
   padding: 2px 8px;
   background: ${ORANGE};
   font-size: ${FONT_SIZE_X_SMALL};
   font-weight: 700;
   color: white;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 8px;
   z-index: 2;
   height: 16px;
   text-transform: uppercase;
`;
