import * as actionTypes from '../actions/actionTypes';


const initialState = {

  // status
  userAuth: false,
  username: null,
  userId: null,

  loading: true,
  imagesLoading: true,

  // UI
  isPublicFooterVisible: true,
  isMenuOpen: false,

};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.USER_LOGGED_IN:
      return {
        ...state,
        userId: action.payload.userId,
        username: action.payload.username,
        lastSignIn: action.payload.lastSignIn,
        userAuth: true,
      };
    case actionTypes.USER_NOT_LOGGED_IN:
      return {
        ...state,
        userId: null,
        username: null,
        userAuth: false,
        loading: false,
      };

    case actionTypes.HIDE_PUBLIC_FOOTER:
      return {
        ...state,
        isPublicFooterVisible: action.payload,
      };

    case actionTypes.OPEN_MENU:
      return {
        ...state,
        isMenuOpen: action.payload,
      };


    case actionTypes.IMAGES_LOADING_OFF:
      return {
        ...state,
        loadingImages: false,
      };

    default:
      return state;
  }
}


export default reducer;
