import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Language from '../contexts/Language';
import switchLanguage from '../hoc/switchLanguage';
import {
  Col, GREY, ORANGE, Row, ScreenLinkTextStyle,
} from '../styles';
import Icon from '../styles/Icon';
import * as iconTypes from '../styles/iconTypes';


export const goBackText = {
  en: {
    goBack: 'Older Ideas',
  },
};

type Props = {
  location?: {
    pathname: string;
    state: {
      dealId?: string;
    };
  };
  setLimitDeal: any;
  limitDeal: number;
}

const MoreDealIdeas: React.FunctionComponent<Props> = ({
  setLimitDeal,
  limitDeal,
}) => {
  const [isIconPulsing, setIconPulsing] = useState(false);

  const locale = useContext(Language);
  return (
    <Row
      lg
      center
    >
      <Container>
        <Row
          ver
          center
        >
          <Col
            size={3}
            jEnd
            cursor
          >
            <BackIcon
              className={isIconPulsing ? 'animated slideOutDown infinite' : ''}
              onMouseEnter={() => setIconPulsing(true)}
              onMouseLeave={() => setIconPulsing(false)}
              onClick={() => setLimitDeal(limitDeal + 3)}
            >
              <Icon
                height="18"
                width="18"
                iconType={iconTypes.CHEVRON_DOWN}
                fill={isIconPulsing ? ORANGE : GREY}
              />
            </BackIcon>
          </Col>
          <Col
            size={6}
            center
          >

            <ScreenLinkTextStyle
              onClick={() => setLimitDeal(limitDeal + 3)}
              style={{ cursor: 'pointer' }}
              onMouseEnter={() => setIconPulsing(true)}
              onMouseLeave={() => setIconPulsing(false)}
            >
              {switchLanguage(locale, goBackText).goBack}
            </ScreenLinkTextStyle>

          </Col>
          <Col
            size={3}
            cursor
          >
            <BackIcon
              className={isIconPulsing ? 'animated slideOutDown infinite' : ''}
              onMouseEnter={() => setIconPulsing(true)}
              onMouseLeave={() => setIconPulsing(false)}
              onClick={() => setLimitDeal(limitDeal + 3)}
            >
              <Icon
                height="18"
                width="18"
                iconType={iconTypes.CHEVRON_DOWN}
                fill={isIconPulsing ? ORANGE : GREY}
              />
            </BackIcon>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default withRouter(MoreDealIdeas as any);


const Container = styled.div`
    width: 768px;
  `;

const BackIcon = styled.div`
    float: right;
`;
