import React from 'react';
import styled from 'styled-components';

import { Col, GreyContainer, Row } from '../../../styles';
import SMFollow from './SMFollow';
import SMSubscribe from './SMSubscribe';

const SMMobile = () => (
  <BackgroundContainer
    padTop="12px"
  >
    <Row
      sm
      center
    >
      <Col size={1} />
      <Col
        size={10}
        center
      >
        <SMSubscribe />
      </Col>
      <Col size={1} />

    </Row>
    <Row
      center
    >
      <SMFollow />
    </Row>
  </BackgroundContainer>
);

export default SMMobile;


const BackgroundContainer = styled(GreyContainer)`
   margin-top: -24px;
`;
