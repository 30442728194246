import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Col, GREY, GREY_MEDIUM, ORANGE, LINK_NO_STYLE,
} from '../../../../styles/index';
import Icon from '../../../../styles/Icon';
import { DealId } from '../../../../interfaces/dealTypes';


interface IdealMenuButton {
  isVisible: boolean;
  iconType: string;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  dealId: DealId;
  userId: string;
}

const DealMenuButtonColor = (props: IdealMenuButton) => {
  const {
    onClick, isVisible, iconType, dealId, userId,
  } = props;

  return (
    <IconWrapperMenu
      size={2}
      ver
      center
      cursor
      onClick={onClick}
      isVisible={isVisible}
    >
      {userId
        ? (
          <Icon
            height="20"
            width="20"
            iconType={iconType}
            fill={isVisible ? ORANGE : GREY}
            hoverFill={ORANGE}
          />
        ) : (
          <Link
            to={{
              pathname: '/logIn',
              state: { dealId },
            }}
            style={LINK_NO_STYLE}
          >
            <Icon
              height="20"
              width="20"
              iconType={iconType}
              fill={isVisible ? ORANGE : GREY}
              hoverFill={ORANGE}
            />
          </Link>
        )}
    </IconWrapperMenu>
  );
};

export default DealMenuButtonColor;

export const IconWrapperMenu = styled(Col)<{isVisible: boolean}>`
   color: ${(props) => (props.isVisible ? GREY_MEDIUM : GREY)};
  :hover{
    color:${GREY_MEDIUM} ;
    fill:${GREY_MEDIUM};
  }
`;
