import React from 'react';
import Img from 'react-image';
import styled from 'styled-components';
import prof from '../../../assets/prof.png';
import { ROBOT_SIZE } from '../../../styles';
import ImageCardAvatarPlaceholder from './ImageCardAvatarPlaceholder';

const ImageProf = () => (
  <>
    <Mappet
      src={prof}
      loader={
        <ImageCardAvatarPlaceholder />
      }
    />
  </>
);
export default ImageProf;


export const Mappet = styled(Img)`
   @media only screen and (max-width: 426px){
    height: 72px;
    width: 72px;
  }
    ${ROBOT_SIZE}
    object-fit: cover;
    margin-bottom:3px;
`;
