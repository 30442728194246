import React, { FC } from 'react';
import styled from 'styled-components';

import {
  CardTitleDescriptionTextStyle,
  Col,
  DealCardContainerStyle,
  GREY_DARK,
  GREY_MEDIUM,
  Row,
  SHADOW_GREY_PHONE_ICON,
  shadowNavAvatar,
  TEXT_CARD_TITLE,
  TEXT_CARD_TITLE_LIGHT,
  TEXT_DEALNAME,
} from '../../../../styles';
import Icon from '../../../../styles/Icon';
import * as iconTypes from '../../../../styles/iconTypes';
import DealCardPhoto from '../../../UI/Cards/DealCard/DealCardPhoto';

type Props = {
  title: string;
  titleLight: string;
  dateDue?: string;
  dealname: string;
  challengePhoto?: any;
  titleCenter?: boolean;
}

const ExampleChallenge: FC <Props> = ({
  title,
  titleLight,
  dateDue,
  dealname,
  challengePhoto,
  children,
  titleCenter,
}) => (
  <DealCardContainerStyle
    shadow
    drawer
    style={{
      minWidth: '344px',
    }}
  >
    <Row
      center
    >
      <Circle>
        <IconWrapper>
          <Icon
            height="18"
            width="18"

            iconType={iconTypes.STAIRS_UP}
            fill={GREY_DARK}
          />
        </IconWrapper>
      </Circle>
    </Row>
    <Row
      center
      sm
    >
      <TitleCard>
        {title}
      </TitleCard>
      <TitleCardLight>
        {`   ${titleLight}`}
      </TitleCardLight>
    </Row>
    <Row
      center
      mdB
    >
      <CardTitleDescriptionTextStyle>
        Due date:
        {' '}
        {` ${dateDue}` || "wasn't set"}
      </CardTitleDescriptionTextStyle>
    </Row>
    <Row
      sm
      mdB
    >
      <Col size={1} />
      <Col
        size={10}
        center={titleCenter}
      >
        <DealNameText>
          {dealname}
        </DealNameText>
      </Col>
      <Col size={1} />

    </Row>
    {challengePhoto
      ? (
        <DealCardPhoto
          photoUri={challengePhoto}
          modalDisabled
        />
      ) : null}
    {children}
    <Row md />
  </DealCardContainerStyle>
);

export default ExampleChallenge;

const Circle = styled.div<{grey?: boolean}>`
    margin-top: -21px;
    display: flex;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.grey ? GREY_MEDIUM : 'white')};
  ${((props) => (props.grey
    ? shadowNavAvatar(GREY_DARK) : shadowNavAvatar(SHADOW_GREY_PHONE_ICON)))}; 
    z-index:2;
`;

const IconWrapper = styled.div`
    width: 38px;
    height: 38px;
    border-radius: 19px;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: dashed; 
    border-color: ${GREY_DARK};
    display: flex;
`;

const TitleCard = styled.div`
      ${TEXT_CARD_TITLE}
`;
const TitleCardLight = styled.div`
      ${TEXT_CARD_TITLE_LIGHT}
      padding-left:3px;
`;

const DealNameText = styled.div`
  ${TEXT_DEALNAME}
  color: ${GREY_MEDIUM};
  display: flex;
  justify-content: center;
`;
