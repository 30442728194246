import useWindowSize from '@rehooks/window-size';
import React, { memo } from 'react';

import IntroDesktop from './IntroDesktop';
import IntroMobile from './IntroMobile';


export const texts = {
  en: {
    subtitle: 'CREATE',
    title: 'Interactions around challenge',
    description: 'Have an idea of action? Challenge, task, bet - you name it. Build enjoyable interaction with your friend around it with just few clicks. Create a challenge contract with surprising consequences! Download your app for free and give new experience a shot.',
  },
};

const Intro = () => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;

  if (innerWidth < 768) {
    return (
      <>
        <IntroMobile />
      </>
    );
  }
  return (
    <IntroDesktop />
  );
};

export default memo(Intro);
