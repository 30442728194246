import useWindowSize from '@rehooks/window-size';
import _ from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Row } from '../../../styles';
import Icon from '../../../styles/Icon';
import { generateIconRotate, generateIconRotateQ } from '../UI/HomeIcons';


type Props = {
  iconColor: string;
  backgroundColor: string;
  iconType: string;
  width?: number;
  bordersRadius?: boolean;
  rotate90?: boolean;
}

const PremiumCardBackground: FC<Props> = ({
  iconType,
  iconColor,
  backgroundColor,
  width,
  bordersRadius,
  rotate90,
}) => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  const rotateFunction = rotate90 ? generateIconRotateQ : generateIconRotate;

  const renderIcons = () => {
    const array = [];
    let i;
    let j;
    if (width) {
      j = width / 28;
    } else {
      j = innerWidth / 34;
    }
    for (i = 1; i < j; i++) {
      if (i && (i % 2 === 0)) {
        array.push(
          <IconColumn>
            <Icon
              height="22"
              width="22"
              iconType={iconType}
              fill={iconColor}
              style={{
                transform: `rotate(${rotateFunction(_.random(1, 4))})`,
                marginBottom: '12px',
              }}
            />
            <IconPadding />
          </IconColumn>,
        );
      } else {
        array.push(
          <IconColumn>
            <Icon
              height="22"
              width="22"
              iconType={iconType}
              fill={iconColor}
              style={{
                transform: `rotate(${rotateFunction(_.random(1, 4))})`,
              }}
            />
            <IconPadding />
          </IconColumn>,
        );
      }
    }
    return array;
  };


  return (
    <div>
      <IconRow
        center
        background={backgroundColor}
        width={width}
        borderRadiusTop={bordersRadius}
      >
        {renderIcons()}
      </IconRow>
      <IconRow
        center
        background={backgroundColor}
        width={width}
      >
        {renderIcons()}
      </IconRow>
      <IconRow
        center
        background={backgroundColor}
        width={width}
        borderRadiusBottom={bordersRadius}
      >
        {renderIcons()}
      </IconRow>
    </div>
  );
};


export default PremiumCardBackground;

// eslint-disable-next-line
const IconRow = styled(Row)<{background: string; width?: number; borderRadiusTop?: boolean; borderRadiusBottom?: boolean}>`
  background: ${(props) => props.background};
  width: ${(props) => (`${props.width}px`)};
  border-top-left-radius:${(props) => (props.borderRadiusTop ? '5px' : 0)};
  border-top-right-radius:${(props) => (props.borderRadiusTop ? '5px' : 0)};
  border-bottom-right-radius:${(props) => (props.borderRadiusBottom ? '5px' : 0)};
  border-bottom-left-radius:${(props) => (props.borderRadiusBottom ? '5px' : 0)};
`;

const IconColumn = styled.div<{bottom?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.bottom ? 'flex-start' : 'flex-end')};
  overflow: hidden;
`;

const IconPadding = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
`;
