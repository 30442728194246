import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../../../styles/Icon';
import { EYE, EYE_CLOSED } from '../../../styles/iconTypes';
import {
  ORANGE, Col, DIST_MEDIUM, ERROR_COLOR, GREY, GREY_WHITE, Row,
} from '../../../styles/index';

type Props = {
  border?: boolean;
  error?: boolean;
  placeholder: string;
  password?: boolean;
  value: string;
  narrow?: boolean;
  onChange: React.FormEventHandler<HTMLInputElement>;
  disabled?: boolean;
  type?: string;
  id?: string;
  name?: string;
  required?: boolean;
}

const Input: React.FunctionComponent<Props> = ({
  border,
  error,
  placeholder,
  password,
  value,
  narrow,
  onChange,
  disabled,
  name,
  type,
  id,
  required,
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);


  const handlePasswordIcon = () => {
    if (isPasswordVisible) {
      return (
        <Icon
          height="16"
          width="16"
          iconType={EYE}
          fill={GREY}
        />
      );
    }
    return (
      <Icon
        height="16"
        width="16"
        iconType={EYE_CLOSED}
        fill={GREY}
      />
    );
  };

  const handleIconClick = () => {
    if (password) {
      setPasswordVisible(!isPasswordVisible);
    }
  };

  return (
    <InputContainer
      border={border}
      error={error}
    >
      <Row
        ver
      >
        <Col
          size={1}
          ver
        />
        <Col
          size={8}
          ver
        >
          <InputStyle
            error={error}
            placeholder={placeholder}
            type={!isPasswordVisible && password ? 'password' : type}
            value={value}
            narrow={narrow}
            onChange={onChange}
            disabled={disabled}
            name={name}
            id={id}
            required={required}
          />
        </Col>
        <Col
          onClick={handleIconClick}
          size={3}
          ver
          center
          cursor
        >
          {password ? handlePasswordIcon() : null}
        </Col>
      </Row>
    </InputContainer>
  );
};

export default Input;


const InputContainer = styled.div<{border?: boolean; error?: boolean}>`
    ${(props) => (props.border
            && `border-bottom: 1px dashed ${props.error
              ? ERROR_COLOR : GREY_WHITE}`)};
    width: 100%;
`;

const InputStyle = styled.input<{narrow?: boolean; error?: boolean}>`
  border: none;
  background-color: white;
  cursor: text;
  padding: 0;
  width: 100%;
  padding: ${DIST_MEDIUM} 0;
  font-size: ${(props) => (props.narrow ? '12px' : '14px')};
  box-sizing: border-box;
    color: ${(props) => (props.error ? ERROR_COLOR : ORANGE)};
    :hover{
    outline-style: none !important;
    }
    :active{
        outline-style: none;
    }
    :focus{
        outline-style: none;
    }
`;
