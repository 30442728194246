import React from 'react';
import styled from 'styled-components';
import { Col, GREY } from '../../../../styles/index';


const DealMenuBorder = () => (
  <Col
    center
    ver
    size={1}
  >
    <Border />
  </Col>
);

export default DealMenuBorder;

const Border = styled.span`
border-right: 1px dashed ${GREY};
height: 18px; 
width:1;
`;
