import React from 'react';
import styled from 'styled-components';

import {
  Col, Row, TEXT_CARD_TITLE, TEXT_CARD_TITLE_LIGHT,
} from '../../../styles';

const IdeaTitle = () => (
  <Row>
    <Col size={4} />
    <Col
      size={4}
      center
      ver
    >
      <Title>
        Challenge
      </Title>
      <Title>
        Contract
      </Title>
      <TitleLight>
        Idea
      </TitleLight>
    </Col>
    <Col
      ver
      size={4}
    />
  </Row>
);

export default IdeaTitle;


const Title = styled.div`
  padding-left: -1px;
  padding-right: 3px;
  ${TEXT_CARD_TITLE}
  font-size:28px;
`;
const TitleLight = styled.div`
  ${TEXT_CARD_TITLE_LIGHT}
  font-size:28px;
`;
