import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './App.css';
import { AppRouter } from './routes/Router';
import {
  userCheck,
} from './store/actions/index';


// eslint-disable-next-line no-shadow
const App: React.FC<Iuser> = ({ userCheck }) => {
  useEffect(() => {
    userCheck();
  }, [userCheck]);
  return <AppRouter />;
};


interface Iuser{
  userCheck: Function;
}


const mapStateToProps = (state: Iuser) => ({
  userCheck: state.userCheck,
});

export default connect(mapStateToProps,
  {
    userCheck,
  })(App);
