import React, { FC } from 'react';
import Img from 'react-image';
import styled from 'styled-components';
import logo from '../../../../../assets/LogoDealem.svg';

import {
  Col, GREY_DARK, ORANGE, Row, SHADOW_GREY_PHONE_ICON, shadowNavAvatar, LINK_NO_STYLE,
} from '../../../../../styles';
import AuthorContainer from './AuthorContainer';

const Author: FC<{author: {
  bannerUri: string;
  authorName: string;
  authorUri: string;
  authorAvatar: string;
}| null;}> = ({
  author,
}) => (
  <AuthorContainer>
    <Row
      center
      sm
    >
      <Col size={4} />
      <Col
        size={3}
        sm
      >
        <LineCurvedBottom
          height="40px"
        />
      </Col>
      <Col
        size={6}
        center
      >
        <a
          style={LINK_NO_STYLE}
          href={author?.authorUri ?? ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ContainerStyleSmall>

            <Row
              center
              ver
            >
              {author
                ? (
                  <ImageAvatar
                    src={author?.authorAvatar}
                    loader={
                      <ImagePlaceholder />
                    }
                  />
                ) : (
                  <ImageLogo
                    src={logo}
                    loader={
                      <ImagePlaceholder />
                    }
                  />
                )}
            </Row>
          </ContainerStyleSmall>
        </a>
      </Col>
      <Col
        size={3}
        jEnd
        sm
      >
        <LineCurvedBottomRight
          height="40px"
          colorLine
        />
      </Col>
      <Col size={4} />
    </Row>
  </AuthorContainer>

);


export default Author;

const LineCurvedBottom = styled.div<{colorLine?: boolean; height: string}>`
  width: 1px;
  border-left: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  border-top: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  min-height: ${(props) => props.height};
  height: auto;
  width: 100%;
  border-top-left-radius: 10px;
`;
const LineCurvedBottomRight = styled.div<{colorLine?: boolean; height: string}>`
  width: 1px;
  border-right: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  border-top: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  min-height: ${(props) => props.height};
  height: auto;
  width: 100%;
  border-top-right-radius: 10px;
`;


export const ContainerStyle = styled.div<{shadow?: boolean; drawer?: boolean}>`
  width: 100%;
  background: white;
  box-shadow: 0 10px 40px 0 rgba(190,190,207,0.50);
  border-radius: 5px;
`;

export const ContainerStyleSmall = styled.div<{shadow?: boolean; drawer?: boolean}>`
  border-radius: 5px;
  width: 100%;
`;

export const ImageAvatar = styled(Img)<{grey?: boolean}>`
    margin-top: -10px;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    object-fit: cover;
    box-shadow: 0 0px 20px 0 ${SHADOW_GREY_PHONE_ICON};
    /* ${((props) => (props.grey
    ? shadowNavAvatar(GREY_DARK) : shadowNavAvatar(SHADOW_GREY_PHONE_ICON)))};  */
    z-index:2;
`;
export const ImageLogo = styled(Img)<{grey?: boolean}>`
    margin-top: -10px;
    width: 42px;
    height: 42px;
`;


const ImagePlaceholder = styled.div`
    margin-top: -10px;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    border: 0.5px ${GREY_DARK} dashed
`;
