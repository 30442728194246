import firebase from '../../firebase';
import * as actionTypes from './actionTypes';


export function logInEmailChanged(email: string) {
  return {
    type: actionTypes.LOG_IN_EMAIL_CHANGED,
    payload: email,
  };
}


export function logInPasswordChanged(password: string) {
  return {
    type: actionTypes.LOG_IN_PASSWORD_CHANGED,
    payload: password,
  };
}

export function logInReset() {
  return {
    type: actionTypes.LOG_IN_RESET,
  };
}

export function logInHandleLogin(email: string, password: string) {
  return (dispatch: any) => {
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      dispatch({
        type: actionTypes.LOG_IN_FAILED,
        payload: 'Enter proper email address',
      });
      setTimeout(() => {
        dispatch({
          type: actionTypes.LOG_IN_FAILED_OFF,
        });
      }, 7000);
    } else if (password.length === 0) {
      dispatch({
        type: actionTypes.LOG_IN_FAILED,
        payload: 'Password is needed',
      });
      setTimeout(() => {
        dispatch({
          type: actionTypes.LOG_IN_FAILED_OFF,
        });
      }, 7000);
    } else {
      dispatch({ type: actionTypes.LOG_IN_LOADING });
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then((user) => {
          dispatch({
            type: actionTypes.LOG_IN_USER_SUCCESS,
            payload: user,
          });
        })
        .catch((error) => {
          dispatch({
            type: actionTypes.LOG_IN_FAILED,
            payload: error.message,
          });
        })
        .then(() => setTimeout(() => {
          dispatch({
            type: actionTypes.LOG_IN_FAILED_OFF,
          });
        }, 10000));
    }
  };
}

export function logInErrorReset() {
  return {
    type: actionTypes.LOG_IN_FAILED_OFF,
  };
}
