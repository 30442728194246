import React from 'react';
import styled from 'styled-components';

import { Col, GREY, GREY_MEDIUM } from '../../../../styles';
import Icon from '../../../../styles/Icon';


interface IdealMenuButton {
  isVisible: boolean;
  iconType: string;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const DealMenuButton = (props: IdealMenuButton) => {
  const { onClick, isVisible, iconType } = props;
  return (
    <IconWrapperMenu
      size={2}
      ver
      center
      cursor
      onClick={onClick}
      isVisible={isVisible}
    >
      <Icon
        height="20"
        width="20"
        iconType={iconType}
        fill={isVisible ? GREY_MEDIUM : GREY}
        hoverFill={GREY_MEDIUM}
      />
    </IconWrapperMenu>
  );
};

export default DealMenuButton;

export const IconWrapperMenu = styled(Col)<{isVisible: boolean}>`
   color: ${(props) => (props.isVisible ? GREY_MEDIUM : GREY)};
  :hover{
    color:${GREY_MEDIUM} ;
    fill:${GREY_MEDIUM};
  }
`;
