import useWindowSize from '@rehooks/window-size';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DealPublicFooterMobile from './DealPublicFooterMobile';
import DealPublicFooterDesktop from './DealPublicFooterDesktop';

type Props = {
  isPublicFooterVisible: boolean;
  dealIdea?: boolean;
}

const DealItemPublicFooter: React.FunctionComponent<Props> = ({ isPublicFooterVisible, dealIdea }) => {
  const windowSize = useWindowSize();

  if (windowSize.innerWidth < 768) {
    return (
      <AnimaOpacity
        isPublicFooterVisible={isPublicFooterVisible}
      >
        <DealPublicFooterMobile />
      </AnimaOpacity>
    );
  }
  return (
    <AnimaOpacity
      isPublicFooterVisible={isPublicFooterVisible}
    >
      <DealPublicFooterDesktop
        dealIdea={dealIdea}
      />
    </AnimaOpacity>
  );
};


const mapStateToProps = ({ user }: any) => {
  const { isPublicFooterVisible } = user;
  return ({
    isPublicFooterVisible,
  });
};

export default connect(mapStateToProps,
  {})(DealItemPublicFooter);


const AnimaOpacity = styled.div<{isPublicFooterVisible: boolean}>`
   transition: opacity 0.3s linear;
   opacity: ${(props) => (props.isPublicFooterVisible ? 1 : 0)}
`;
