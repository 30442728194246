import React, { FC } from 'react';
import useWindowSize from '@rehooks/window-size';
import { Row } from '../../../../styles';
import IntroDealExample from '../Comments/IntroComment';
import ExampleBody from './ExampleBody';
import IntroCommentTop from '../Comments/IntroCommentTop';
import IntroCommentBottom from '../Comments/IntroCommentBottom';

const Example: FC = () => {
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  return (
    <>
      <ExampleBody
        challengePhoto={null}
        dateDue="1st July 2021"
        dealname="Install dad jokes detection software"
        committedUsername="Mark the Engineer"
        witnessUsername="WOOLY"
        witnessInitiator
        prize="WOOLY will clean the mess in Mark's workspace"
        penalty="WOOLY will simulate emotional instability for one day"
        surprize
        titleCenter
      >

        <>
          <IntroCommentTop
            commentText="Father, I want dad jokes detection software."
          />
          <Row bgr />
          <IntroCommentBottom
            commentText="ok WOOLY, we've got a deal <3"
          />
        </>
      </ExampleBody>
      {innerWidth < 768
        ? (
          <>
            <IntroCommentTop
              commentText="I get it - THIS CHALLENGE IS DAD JOKE. Success!"
              topMargin
            />
          </>
        )
        : (
          <Row
            center
          >
            <IntroDealExample
              text="Now I understand - THIS CHALLENGE IS DAD JOKE. Success!"
              rightAvatar
              noMargins
            />
          </Row>
        )}

      {innerWidth < 768
        ? (
          <IntroCommentBottom
            commentText="Great <3 let me check my SURPRISE!"
            topMargin
          />
        ) : (
          <Row
            center
          >
            <IntroDealExample
              text="Great <3 let me check my SURPRISE!"
              noMargins
              imageProf
            />
          </Row>
        )}
    </>
  );
};

export default Example;
