import React, { useContext } from 'react';
import styled from 'styled-components';
import useWindowSize from '@rehooks/window-size';

import Language from '../../../../contexts/Language';
import switchLanguage from '../../../../hoc/switchLanguage';
import {
  FONT_FUTURA, GREY_DARK, GREY_MEDIUM, H1,
} from '../../../../styles';
import { Row } from '../../../../styles/grid';
import * as iconTypes from '../../../../styles/iconTypes';
import IntroCard from '../IntroStyle/IntroCard';
import Why from '../IntroStyle/Why';
import IntroComment from '../Comments/IntroComment';
import IntroCommentTop from '../Comments/IntroCommentTop';

export const texts = {
  en: {
    subtitle: 'Start',
    title: 'Your journey with Dealem',
    dealIdeaTitle: 'Challenge',
    dealIdeaTitleLight: 'idea',
    dealIdeaDescription: 'Is there anything you care to accomplish or motivate your friend to take an effort? Take matters into your own hands and create a deal with your friend. Get backup for your challenge or give it to your friend.',
    dealExample: 'I will make my first home pizza !',
    dealExampleMobile: 'I will make my first home pizza !',
    prizeTitle: 'Consequences',
    prizeTitleLight: ' including surprizes!',
    prizeDescription: 'Every action has its consequences. In Dealem app you are able to create them - use your imagination.',
    prizeExample: "C'mon HUMAN! Now it's your turn to do that!",
    friendTitle: 'Creating',
    friendTitleLight: ' contract is easy',
    friendDescription: 'Set challenge, declare consequences, and send an invitation to your friend by one simple link. Share link as you like and let the magic happen.',
    why: "WHAT IS A UNIQUE FEATURE OF DEALEM'S INTERACTIONS?",
    descriptionWhy: 'SURPRISES are set beforehand but they come to light only afterwards without any change. That makes interactions much more EXITING.',
  },
};

const IntroDealCards = () => {
  const locale = useContext(Language);
  const robotText = switchLanguage(locale, texts).prizeExample;
  const windowSize = useWindowSize();
  return (
    <Container>
      <Subtile>
        {switchLanguage(locale, texts).subtitle}
      </Subtile>
      <Title>
        {switchLanguage(locale, texts).title}
      </Title>
      <IntroCard
        title={switchLanguage(locale, texts).dealIdeaTitle}
        titleLight={switchLanguage(locale, texts).dealIdeaTitleLight}
        description={switchLanguage(locale, texts).dealIdeaDescription}
        iconType={iconTypes.BULB}
        titleTagComponent={<H1 />}
      />
      <IntroCard
        title={switchLanguage(locale, texts).prizeTitle}
        titleLight={switchLanguage(locale, texts).prizeTitleLight}
        description={switchLanguage(locale, texts).prizeDescription}
        iconType={iconTypes.SMILE}
      />
      <IntroCard
        title={switchLanguage(locale, texts).friendTitle}
        titleLight={switchLanguage(locale, texts).friendTitleLight}
        description={switchLanguage(locale, texts).friendDescription}
        iconType={iconTypes.USER}
      />
      {windowSize.innerWidth < 1024
        ? (
          <IntroCommentTop
            commentText={robotText}
          />
        )
        : (
          <IntroComment
            text={switchLanguage(locale, texts).prizeExample}
            rightAvatar
          />
        )}
      <Row bgr>
        <Border />
      </Row>
      <Why
        why={switchLanguage(locale, texts).why}
        description={switchLanguage(locale, texts).descriptionWhy}
      />
    </Container>
  );
};

export default IntroDealCards;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  @media only screen and (max-width: 1200px){
    padding-top: 66px;
  }
`;

const Subtile = styled.p`
  font-size: 14px;
  color: ${GREY_MEDIUM};
  letter-spacing: 2.67px;
  text-transform: uppercase;
  font-weight: bold;
`;

const Title = styled.h2`
  font-size: 32px;
  color: ${GREY_DARK};
  letter-spacing: -2px;
  line-height: 48px;
  font-weight: 400;
  margin-bottom: 32px;
  font-family:${FONT_FUTURA};
`;

export const Border = styled.div`
    border-top: 1px dashed ${GREY_DARK};
    height: 1px; 
    width: 42px;
`;
