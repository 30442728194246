import React, { FC } from 'react';
import styled from 'styled-components';

import {
  ORANGE, Col, GREY_DARK, Row,
} from '../../../../styles';

const IdeaLine: FC<{lineHeight?: number}> = ({
  lineHeight = 24,
}) => (
  <Row
    center
  >
    <Col size={1} />
    <Col
      size={2}
      center
    >
      <Line
        height={`${lineHeight}px`}
      />
    </Col>
    <Col
      size={4}
      center
    />
    <Col
      size={2}
      center
    >
      <Line
        height={`${lineHeight}px`}
        colorLine
      />
    </Col>
    <Col size={1} />
  </Row>
);


export default IdeaLine;

const Line = styled.div<{colorLine?: boolean; height: string }>`
  width: 1px;
  border-left: 1px ${(props) => (props.colorLine ? ORANGE : GREY_DARK)} dashed;
  margin-top: 6px;
  min-height: ${(props) => props.height};
  height: auto;
`;
