/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from 'react';
import styled from 'styled-components';


import Language from '../../../contexts/Language';
import switchLanguage from '../../../hoc/switchLanguage';
import {
  GREY,
  GREY_DARK,
  GREY_MEDIUM,
  SHADOW_GREY,
  shadowCard,
  Row,
  FONT_FUTURA,
} from '../../../styles';
import NextButton from '../../UI/Buttons/NextButton';
import Input from '../../UI/Inputs/Input';


const texts = {
  en: {
    subtitle: 'Dealem news',
    title: 'Subscribe to Dealem Newsletter',
    noSpam: 'No spam, only tips, ideas and news about Dealem.',
    placeholder: 'Enter your email',
  },
};


const SMSubscribe = () => {
  const [email, setemail] = useState('');
  const [pp, setpp] = useState(false);
  const locale = useContext(Language);

  return (
    <Container>
      <Subtile>
        {switchLanguage(locale, texts).subtitle}
      </Subtile>
      <Title>
        {switchLanguage(locale, texts).title}
      </Title>
      <Description>
        {switchLanguage(locale, texts).noSpam}
      </Description>
      <form
        action="https://eodcode.us17.list-manage.com/subscribe/post"
        method="POST"
        // noValidate
        target="_blank"
      >
        <input
          type="hidden"
          name="u"
          value="ac232adfb21d8dcd13084ee0f"
        />
        <input
          type="hidden"
          name="id"
          value="972b3e1216"
        />
        <Panel>
          <Input
            placeholder={switchLanguage(locale, texts).placeholder}
            onChange={(e) => setemail((e.target as HTMLTextAreaElement).value.trim())}
            value={email}
            narrow
            type="email"
            name="MERGE0"
            id="MERGE0"
            required
          />
        </Panel>
        <LoginButtonWrapper>
          <NextButton
            loading={false}
            onClick={() => null}
            error={false}
            // disabled={pp === false}
          />
        </LoginButtonWrapper>
        <Row
          md
          ver
        >
          <input
            type="checkbox"
            checked={pp}
            onChange={() => setpp(!pp)}
            required
          />
          <AgreeText>
            I've read and agree with
          </AgreeText>
          <PP
            // style={LINK_NO_STYLE}
            href="/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dealem's Privacy Policy
          </PP>
        </Row>
      </form>
    </Container>
  );
};

export default SMSubscribe;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  @media only screen and (max-width: 1200px){
    padding-top: 66px;
  }
`;

const Subtile = styled.h2`
  font-size: 14px;
  color: ${GREY_MEDIUM};
  letter-spacing: 2.67px;
  text-transform: uppercase;
  font-weight: bold;
`;

const Title = styled.h3`
  font-size: 32px;
  color: ${GREY_DARK};
  letter-spacing: -2px;
  line-height: 32px;
  font-weight: 400;
  margin-top:12px;
  font-family:${FONT_FUTURA};
`;

export const Border = styled.div`
    border-top: 1px dashed ${GREY_DARK};
    height: 1px; 
    width: 42px;
`;


const Panel = styled.div<{slide?: boolean|null}>`
    background: #FFFFFF;
    ${shadowCard(SHADOW_GREY)};
    border-radius: 5px;
    width: 100%;
    z-index: 10;
    transition: margin-top 0.1s linear;
    margin-top:24px;
    @media (max-width: 425px) {
      margin-top:${(props) => (props.slide ? '20px' : '40px')};
  }
`;

const LoginButtonWrapper = styled.div`
    z-index: 12;
    margin-top: -12px;
    display: flex;
    justify-content: center;
`;

const Description = styled.p`
   font-size: 16px;
   margin-left: 2px;
   color: ${GREY};
    letter-spacing: -1px;
    width: 425px;
    @media (max-width: 425px) {
    width: 100%;
  }
  margin-top: 12px;
`;


const AgreeText = styled.span`
   font-size: 14px;
   margin-left: 2px;
   color: ${GREY};
   letter-spacing: -1px;

`;

const PP = styled.a`
   font-size: 14px;
   margin-left: 4px;
   color: ${GREY_DARK};
   letter-spacing: -1px;
   text-decoration: underline;
    /* width: 425px;
    @media (max-width: 425px) {
    width: 100%;
  } */
`;
