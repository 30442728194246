import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import switchLanguage from '../../hoc/switchLanguage';
import { shadowCard, TEXT_AREA_FORMAT, SHADOW_GREY } from '../../styles';
import Language from '../../contexts/Language';

const texts = {
  en: {
    message: 'Write message...',
  },
};

const DealTextArea = ({
  setValue,
  value,
}) => {
  const [isTextAreaFocus, setTextAreaFocus] = useState(false);
  const locale = useContext(Language);
  return (
    <CardInput
      animation="animated slideInLeft"
      className="inv"
      placeholder={switchLanguage(locale, texts).message}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      value={value}
      isFocus={isTextAreaFocus}
      onFocus={() => setTextAreaFocus(true)}
      onBlur={() => setTextAreaFocus(false)}
    />
  );
};

export default DealTextArea;


export const CardInput = styled.textarea`
  ${TEXT_AREA_FORMAT}
  background-color: white;
  padding: 0;
  width: 100%;
  padding: 20px 20px ${(props) => (props.isFocus ? '40px' : '0')} 20px;
  transition: padding-bottom 0.3s linear;
  padding: 20px 20px ${(props) => (props.isFocus ? '40px' : '0')} 20px;
  ${shadowCard(SHADOW_GREY)}
  border-radius: 5px;
  border-radius: 5px;
`;
